import React, { useState, useEffect, useRef, useCallback } from "react";
import Content from "./Content";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from "../hook/useWebSocket";
import { login, setManaged } from "../redux/lib/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Login from "../pages/auth/Login";
import axios from "axios";
import Cookies from "js-cookie";
import QuotaBar from "../components/Navbar/QuotaBar";

const Layout = () => {
  const [show, setShow] = useState(window.innerWidth >= 1012);
  const errorMessage = useSelector((state) => state.errorSlice.message);
  const verified = useSelector((state) => state.authSlice.user.verified);
  const dispatch = useDispatch();
  const storedUserId = useRef(null);
  const userId = useSelector((state) => state?.authSlice?.id);
  const managed = useSelector((state) => state.authSlice.managed);
  const originalUser = useSelector((state) => state.originalUserSlice);
  const isAuth = useSelector((state) => state?.authSlice?.user?.token);
  const [processComplete, setProcessCompleted] = useState(false);
  const [activeProject, setActiveProject] = useState(null); // Active project state
  const [topThreeProjects, setTopThreeProjects] = useState([]); // Top three projects state
  const [isQuotaBarVisible, setIsQuotaBarVisible] = useState(true);
  const [pin_project, setPin_project] = useState(false);
  const handleDismissQuotaBar = () => {
    setIsQuotaBarVisible(false);
  };
  const navigate = useNavigate();
  const { wsMessage, closeMsg, openMsg } = useWebSocket(
    `wss://${process.env.REACT_APP_API_URL_WS}/ws`
  );
  const location = useLocation();
  const locationState = location.pathname; // Track location state
  const projectDetails = location.state?.projectDetails;
  const revertToOriginalUser = useCallback(() => {
    if (originalUser) {
      dispatch(
        login({
          token: originalUser.token,
          id: originalUser.id,
          name: originalUser.name,
          email: originalUser.email,
          verified: verified,
          Roles: [],
        })
      );

      dispatch(setManaged(false));

      toast.info("Returned to your original account");
      navigate("/projects");
    } else {
      toast.error("No original user data found. Please log in again.");
    }
  }, [dispatch, navigate]);

  const toggle = () => {
    setShow(!show);
  };

  const clearCookies = () => {
    const allCookies = Cookies.get();
    Cookies.set("userId", userId);
  };

  useEffect(() => {
    clearCookies();
    const handleResize = () => {
      setShow(window.innerWidth >= 1012);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {

    if (wsMessage) {
      const regex = /Permissions updated successfully:(\d+)/;
      if (typeof wsMessage.message === "string") {
        const match = wsMessage.message.match(regex);

        if (match && match[1]) {
          const extractedUserId = parseInt(match[1], 10);
          storedUserId.current = userId;
          if (extractedUserId !== userId && managed) {
            revertToOriginalUser();
            toast.success(
              "Permissions updated successfully, You have been logged out",
              {
                autoClose: 10000,
              }
            );
          }
        }
      }
    }
  }, [wsMessage, userId, managed, revertToOriginalUser]);

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);
  const isThreeSegmentPath = pathSegments.length === 2;
  const path_Segments = location.pathname
    .split("/")
    .filter((segment) => segment);
  console.log(topThreeProjects, activeProject,)


  const getTopThreePinnedProjects = async () => {
    let projects = [];

    const cachedProjects = localStorage.getItem("projects");
    if (cachedProjects) {
      try {
        projects = JSON.parse(cachedProjects);
        const cachedPinnedProjects = projects
          .filter((project) => project.flag)
        console.log("Top 3 Projects from Cached Data:", cachedPinnedProjects);
        setTopThreeProjects(cachedPinnedProjects);
      } catch (error) {
        console.error("Error parsing cached projects:", error);
        projects = [];
      }
    } else {
      // Step 2: Fetch projects from API if not cached
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/Displayprojects/${userId}/`
        );
        projects = response.data.projects || [];
        console.log("Fetched projects from API:", projects);
        // Cache the fetched projects
        localStorage.setItem("projects", JSON.stringify(projects));
        // Ensure projects is an array before proceeding
        if (!Array.isArray(projects)) {
          console.error("API response projects are not in array format. Resetting to an empty array.");
          projects = [];
        }

        // Log top 3 projects directly from API data
        const apiPinnedProjects = projects
          .filter((project) => project.flag) // Filter pinned projects

        console.log("Top 3 Projects from API Data:", apiPinnedProjects);

        // Set the top three projects
        setTopThreeProjects(apiPinnedProjects);
      } catch (error) {
        console.error("Error fetching projects from API:", error);
        return;
      }
    }
  };

  useEffect(() => {
    getTopThreePinnedProjects();
  }, [pin_project, userId]);

  useEffect(() => {
    setShow(window.innerWidth >= 1012)
  }, [window.innerWidth, location.pathname]);

  return (
    <>
      {(location.pathname !== "/accounts/google/login/" && location.pathname !== "/login-success/") && <>
        {isAuth ? (
          <div className="flex mx-auto h-screen ">
            <Sidebar
              toggle={toggle}
              show={show}
              openedProjectName={projectDetails?.name}
              activeProject={activeProject}
              setActiveProject={setActiveProject}
              topThreeProjects={topThreeProjects}
              setTopThreeProjects={setTopThreeProjects}
              pin_project={pin_project}
              setPin_project={setPin_project}
              path_Segments={path_Segments}
              getTopThreePinnedProjects={getTopThreePinnedProjects}
            />
            <div
              className={`fixed bottom-0 right-0 w-full  text-center text-white z-[1005]`}
            >
              {!verified && (
                <p className="w-full bg-green-600 py-2 text-sm md:text-base">
                  Please verify your account through email to access all features.
                </p>
              )}
            </div>

            <div className={`w-full z-5 ${show ? "ml-[15%]" : "ml-0"}`}>
              <Navbar
                toggle={toggle}
                show={show}
                setProcessCompleted={setProcessCompleted}
                processComplete={processComplete}
                projectUrl={projectDetails?.url}
              />
              <Content
                show={show}
                processComplete={processComplete}
                activeProject={activeProject}
                setActiveProject={setActiveProject}
                topThreeProjects={topThreeProjects}
                setTopThreeProjects={setTopThreeProjects}
                pin_project={pin_project}
                setPin_project={setPin_project}
                getTopThreePinnedProjects={getTopThreePinnedProjects}

              />
            </div>

          </div>
        ) : (
          <Login />
        )}
      </>}


    </>
  );
};

export default Layout;
