import axios from 'axios';
import { toast } from 'react-toastify';

const createLog = async (action, userId) => {
   

    try {
        const logUrl = `${process.env.REACT_APP_API_URL}/api/logCreate/${userId}/`;
        const response = await axios.post(logUrl, { action });

        if (response.status === 201) {
            console.log('Log created successfully');
        } else {
            console.error('Failed to create log:', response.data);
            // toast.error('Failed to create log');
        }
    } catch (error) {
        // toast.error('Error creating log');
        console.error('Failed to create log:', error);
    }
};

export default createLog;
