import React from 'react';

const QuotaModal = ({ isOpen, onClose, remainingQuota }) => {
  if (!isOpen) return null;

  return (
    <div
    onClick={(event) => {
      if (event.target === event.currentTarget) {
        onClose();
      }
    }}
      className="pointer-events-auto fixed inset-0 z-[10006] 
        grid h-screen w-screen place-items-center bg-black bg-opacity-30 opacity-100 
        backdrop-blur-sm transition-opacity duration-300"
    >
      <div className="bg-white rounded-lg p-8 shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Quota Remaining</h2>
        <p className="text-lg">You have {remainingQuota} quota left.</p>
        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-greeng text-white rounded hover:bg-green-600 transition"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuotaModal;
