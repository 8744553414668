import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import Login from './pages/auth/Login';
import PrivateRoutes from './utils/PrivateRoutes';
import Register from './pages/auth/Register';
import { ToastContainer } from 'react-toastify';
import RegistrationToken from './pages/Checking/RegistrationToken';
import CheckExecitance from './pages/Checking/CheckExecitance';
import VerifiedEmail from './pages/Checking/VerifiedEmail';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPassword';
import CheckEmailPage from './pages/CheckEmailPage';

const App = () => {
  return (
    <div>
     
      <ToastContainer
        position="top-right"

        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path='/*' element={<Layout />} />
        </Route>
        <Route path='/login' element={ <Login />} />
        <Route path='/register' element={ <Register />} />
        <Route path='/CheckExecitance/:token' element={<CheckExecitance />} />
        <Route path='/verified/:token' element={<VerifiedEmail />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
        <Route path="/check-email" element={<CheckEmailPage />} />
        <Route path="/register/:token" element={<RegistrationToken />} />

      </Routes>



    </div>

  );
};

export default App;
