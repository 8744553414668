import React from "react";


const ConfirmationModal = ({ show, onClose, onConfirm, title }) => {
  if (!show) return null;

  return (
    <div 
    className="  pointer-events-auto fixed inset-0 
    z-[1500] grid h-screen w-screen place-items-center
     bg-greeng 
     bg-opacity-60 opacity-100 
     backdrop-blur-sm transition-opacity
      duration-300">

      <div className="bg-white rounded-lg p-6 w-[400px] 
      shadow-lg
       border border-gray-600">
      <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-300">

            Confirm Deletion
            </h2>
        <p>Are you sure you want to delete this article?</p>
        <p className="font-semibold text-red-600 my-2">{title}</p>
        <div className="flex justify-end gap-3 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 font-bold text-black bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmationModal;