import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ pieData, minRanks, competitor, result }) => {
  // Validate input data
  if (!pieData || !pieData.series || pieData.series.length === 0 || !pieData.labels) {
    console.warn("Pie chart data is incomplete or missing.");
    return null;
  }

  let { series, labels } = pieData;

  // Pair series values with labels and minRanks for sorting
  const data = series.map((value, index) => ({
    value,
    label: labels[index],
    minRank: minRanks[index],
  }));

  // Sort data by series values in descending order
  data.sort((a, b) => b.value - a.value);

  // Extract sorted series, labels, and minRanks
  series = data.map((item) => Math.round(item.value));
  labels = data.map((item) => item.label);
  const sortedMinRanks = data.map((item) => item.minRank);

  // Add competitor labels if not already included
  const combinedLabelsSet = new Set([...labels, ...competitor]);
  if (labels.length !== combinedLabelsSet.size) {
    labels = Array.from(combinedLabelsSet);
  }
  if (series.length === 0) {
    console.warn("Series data is empty after processing.");
    return null;
  }
  while (labels.length > series.length) {
    series.push(0)
  }
  console.log(series, labels, 'labelslabelslabels');

  // Handle click events on pie slices
  const handleSliceClick = (event, chartContext, config) => {
    console.log(config, 'clickedLabelclickedLabel');

    if (!config || config.dataPointIndex === undefined || !config.w) {
      console.error("Invalid click event configuration:", config);
      return;
    }

    const clickedIndex = config.dataPointIndex; // Index of the clicked slice
    const clickedLabel = config.w.config.labels[clickedIndex]; // Label of clicked slice
    const seriesValue = config.w.globals.series[clickedIndex]; // Value of the clicked slice
    if (seriesValue === 0 || seriesValue === undefined) {
      console.warn("Clicked slice has no data or is invalid.");
      localStorage.removeItem("matchingEntry");
      localStorage.removeItem("clickedLabel");

      window.dispatchEvent(new Event("localStorageUpdate"));

      return; // Exit without further processing
    }

    const matchingEntry = result.find((entry) => entry.minKey === clickedLabel);
        localStorage.setItem("clickedLabel", clickedLabel);

    if (matchingEntry) {
      const existingValue = localStorage.getItem("matchingEntry");

      // Toggle logic for localStorage
      if (
        existingValue &&
        existingValue
          .split(",")
          .map(Number)
          .every((element, index) => element === matchingEntry.rowIndex[index])
      ) {
        localStorage.removeItem("matchingEntry");
        localStorage.removeItem("clickedLabel");

      } else {
        localStorage.setItem("matchingEntry", matchingEntry.rowIndex);
      }
      window.dispatchEvent(new Event("localStorageUpdate"));

      // Notify other components about localStorage update
    } else {
      console.log(`No matching entry found for label: ${clickedLabel}`);
    }


  };

  // Chart configuration
  const chartOptions = {
    chart: {
      type: "pie",
      width: 350,
      events: {
        // legendClick: function (chartContext, seriesIndex, config) {
        //   // Prevent click if the series value is 0 or undefined
        //   console.log("Legend Click Debug:",
        //     chartContext,
        //     seriesIndex,
        //     config);
        //   if (config && config.globals && config.globals.series) {


        //     const series = config.globals.series;
        //     console.log(config.globals.series, series[seriesIndex], 'series[seriesIndex]');
        //     if (series[seriesIndex] === 0 || series[seriesIndex] === undefined) {
        //       return false; // Prevent interaction
        //     }
        //   }
        //   return true; // Allow interaction for valid series
        // },
        dataPointSelection: handleSliceClick, // Keep your existing handler
      },
    },
    stroke: {
      width: 1, // Border width
    },
    colors: ["#0cc013", "#cece14", "#fc6b03", "#fc0303"], // Custom colors
    legend: {
      position: "right",
    },
    labels: labels,
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        const index = opts.seriesIndex;
        const minRank = sortedMinRanks[index];
        return minRank === 0
          ? `${Math.floor(val)}% - Rank ${minRank}`
          : `${Math.floor(val)}%`;
      },
      style: {
        fontSize: "11px",
        colors: ["#fff"],
      },
    },
    tooltip: {
      y: {
        formatter: (val, { seriesIndex }) => {
          const minRank = sortedMinRanks[seriesIndex];
          return minRank === 0
            ? `${Math.floor(val)}% (Rank ${minRank})`
            : `${Math.floor(val)}%`;
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 330,
          },
          legend: {
            position: "right",
          },
        },
      },
    ],
  };

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="pie"
        width="350"
      />
    </div>
  );
};

export default PieChart;