import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchData = createAsyncThunk(
  "data/fetchData",
  async ({ userId, projectId, selectedDate }, { getState }) => {
    const state = getState();
    const token = state.authSlice.token;
    const formatedDate = selectedDate?.toISOString().split("T")[0];
    console.log(formatedDate, "selectedDate");
  
    const url = `${process.env.REACT_APP_API_URL}/api/userRank/${userId}/${projectId}/`;
    console.log(url,"fetchData")
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // This will be the `action.payload` in your extraReducers
    } catch (error) {
      throw error;
    }
  }
);

const tableSlice = createSlice({
  name: "table",
  initialState: {
    data: [],
    status: "idle", // Can be 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload; // The data from the API response
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message; // The error from the API response
      });
  },
});

export default tableSlice.reducer;
// Slice including isPaginating state
// Redux slice for handling the state and reducers
// const tableSlice = createSlice({
//   name: 'table',
//   initialState: {
//     data: [],
//     status: 'idle', // Can be 'idle', 'loading', 'succeeded', 'failed'
//     error: null,
//   },
//   extraReducers: {
//     [fetchData.pending]: (state) => {
//       state.status = 'loading';
//     },
//     [fetchData.fulfilled]: (state, action) => {
//       state.status = 'succeeded';
//       state.data = action.payload; // The data from the API response
//     },
//     [fetchData.rejected]: (state, action) => {
//       state.status = 'failed';
//       state.error = action.payload; // The error from the API response
//     },
//   },
// });

//pagination
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';

// export const fetchData = createAsyncThunk(
//   'data/fetchData',
//   async ({ userId, projectId, pageUrl, pageSize=50 , fetchAll = false }, { getState }) => {

//     const state = getState();
//     const token = state.authSlice.token;

//     let url = pageUrl || `${process.env.REACT_APP_API_URL}/api/DisplayprojectsRanks/${userId}/${projectId}/`;

//     if (fetchAll) {
//       url += `?page_size=${state.tableSlice.totalCount}`; // Use totalCount to fetch all
//     } else {
//       url += `?page_size=${pageSize}`;
//     }

//     try {
//       const response = await axios.get(url, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

// const tableSlice = createSlice({
//   name: 'table',
//   initialState: {
//     data: [],
//     currentPage: 1,
//     nextPage: null,
//     prevPage: null,
//     totalCount: 0,

//     status: 'idle',
//     error: null,
//   },
//   reducers: {

//   },
//   extraReducers: {
//     [fetchData.pending]: (state) => {
//       state.status = 'loading';
//     },
//     [fetchData.fulfilled]: (state, action) => {
//       state.status = 'succeeded';
//       state.data = action.payload.results;
//       state.totalCount = action.payload.count;

//       // Handling currentPage logic here
//     },
//     [fetchData.rejected]: (state, action) => {
//       state.status = 'failed';
//       state.error = action.error.message;
//     },
//     // You can add more cases for fetchNextPage and fetchPrevPage if needed
//   },
// });
// export default tableSlice.reducer;
