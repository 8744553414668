import React, { useState } from 'react';

const Help = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: 'What is RankTera?',
            answer: 'RankTera is an SEO analysis and keyword research tool designed to help users optimize their content for search engines. It provides features for tracking keyword rankings, analyzing keyword performance, and generating SEO-focused content.',
        },
        {
            question: 'How can I use RankTera for keyword research?',
            answer: 'In RankTera, navigate to the "Main Keyword Research" section. Enter a keyword or URL, and the tool will generate relevant keywords along with ranking data, counts, and recommendations to improve your SEO strategy.',
        },
        {
            question: 'What is the "Keyword Data" panel?',
            answer: 'The "Keyword Data" panel displays keywords associated with your content, showing information like keyword count, recommended usage range, and the number of times each keyword appears in headings. This helps ensure optimal keyword density.',
        },
        {
            question: 'How can I generate an SEO-optimized article in RankTera?',
            answer: 'In the "Main Keyword Research" section, you can select a target keyword and click "Generate Article." The tool will create content tailored to SEO best practices, using the selected keyword in the ideal frequency for better ranking potential.',
        },
        {
            question: 'What does the "Recommended" range mean?',
            answer: 'The "Recommended" range shows the ideal number of times a keyword should appear in your content for optimal SEO. Staying within this range helps balance keyword density without over- or under-optimization.',
        },
        {
            question: 'How can I track my keyword rankings over time?',
            answer: 'RankTera allows you to monitor keyword performance with rank tracking features. You can view historical data, daily rankings, and activity reports, helping you understand trends and adjust your SEO strategy as needed.',
        },
        {
            question: 'What is a Project in RankTera?',
            answer: 'A project in RankTera refers to a specific task or assignment designed to achieve particular objectives, utilizing tools and resources efficiently within a defined timeframe.',
        },
        {
            question: 'Can I use web scraping for data collection?',
            answer: 'Yes, web scraping is commonly used to collect data from websites, provided it complies with the website\'s terms of service and legal guidelines.',
        },
        {
            question: 'How to manage multiple projects in RankTera?',
            answer: 'RankTera allows you to manage multiple projects efficiently by providing tools for task assignment, progress tracking, and collaboration.',
        },
        {
            question: 'How do I invite team members to collaborate on RankTera?',
            answer: 'You can invite team members to collaborate by navigating to the "Invite user" option. Team members can help with keyword research, content editing, and monitoring rankings, making it easier to manage projects collaboratively.',
        },
        {
            question: 'What is the purpose of Activity Logs in RankTera?',
            answer: 'The Activity Logs feature provides an overview of recent actions taken by users within RankTera, such as logging in, logging out, or performing specific tasks. This helps in tracking user activities for accountability and security.',
        },
        {
            question: 'How can I filter logs by specific dates?',
            answer: 'You can filter logs by date using the "Customize Date" option in the Activity Logs section. Select the date range you want to view, and the logs will update to display only the entries within that period.',
        },
        {
            question: 'What is the "Clear All" button for in the logs?',
            answer: 'The "Clear All" button allows you to delete all activity logs. Use this option carefully, as it will permanently remove all log entries displayed in the Activity Logs section.',
        },
        {
            question: 'Can I search for specific activities or users in the logs?',
            answer: 'Yes, you can use the "Search by query" input box to search for specific keywords or users in the logs. This is helpful for quickly finding specific actions taken by certain users.',
        },
        {
            question: 'How do I adjust the number of rows displayed per page in logs?',
            answer: 'You can adjust the number of rows displayed by selecting a different number from the "Rows per page" dropdown at the bottom of the logs table. This allows you to view more or fewer logs on the same page.',
        },
        {
            question: 'Who can view the Activity Logs in RankTera?',
            answer: 'Typically, only authorized users such as administrators can view the full Activity Logs. Standard users may have limited access to view only their actions, depending on the permissions set within RankTera.',
        },
    ];

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="pt-[6rem] rounded-lg relative flex justify-center p-4">
            <div className="w-full max-w-4xl">
                <h1 className="text-green-600 text-center dark:text-gray-300 font-bold mt-3 text-xl
 md:text-xl lg:text-3xl xl:text-2xl">
                   FAQs 
                </h1>
                <div className="flex items-center justify-center my-4 w-[100%] ">
    <p>
        Welcome to the RankTera Help Center! Here, you’ll 
        find answers to common questions about using RankTera, 
        from managing multiple projects and conducting keyword research to
         tracking SEO performance and viewing activity logs. 
    </p>
</div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6 px-4 py-2 
       ">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border-b border-slate-200">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full flex justify-between items-center py-5 text-slate-800 focus:outline-none"
                            >
                                <span>{faq.question}</span>
                                <span
                                    className={`transition-transform duration-300 ${openIndex === index ? 'rotate-180' : 'rotate-0'
                                        }`}
                                >
                                    {openIndex === index ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                            className="w-4 h-4"
                                        >
                                            <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                            className="w-4 h-4"
                                        >
                                            <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                                        </svg>
                                    )}
                                </span>
                            </button>
                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${openIndex === index ? 'max-h-screen' : 'max-h-0'
                                    }`}
                                style={{ maxHeight: openIndex === index ? `${faq.answer.length * 0.2 + 2}rem` : '0' }}
                            >
                                <div className="pb-5 text-sm text-slate-500">{faq.answer}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Help;
