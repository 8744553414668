import React from "react";
import ReactApexChart from "react-apexcharts";

// const PieChart = ({ pieData,minRanks }) => {
//   console.log(pieData, minRanks,"pieData22");

//   // Extracting series and labels from the dynamic data
//   let { series, labels } = pieData;
//   // Pairing series values and labels together for sorting
//   let data = series.map((value, index) => ({ value, label: labels[index] }));

//   // Sort the data in descending order based on series values
//   data.sort((a, b) => b.value - a.value);

//   // Extract sorted series and labels
//   series = data.map((item) => Math.round(item.value));
//   labels = data.map((item) => item.label);

//   if (series.length === 0) return;
//   const chartOptions = {
//     chart: {
//       type: "pie",
//       width: 350,
//     },
//     colors: ["#0cc013", "#cece14", "#fc6b03", "#fc0303"],
//     legend: {
//       position: "right",
//     },
//     labels: labels,
//     dataLabels: {
//       enabled: true,
//       formatter: function (val) {
//         return Math.floor(val) + "%"; // Rounds the value to the nearest integer and adds a '%' symbol
//       },
//       style: {
//         fontSize: "11px",
//         colors: ["#fff"], // Change label color if needed
//       },
//       tooltip: {
//         y: {
//           formatter: function (val) {
//             return Math.floor(val) + "%"; // Also rounding in the tooltip
//           },
//         },
//       },
//       dropShadow: {
//         enabled: false,
//       },
//     },
//     states: {
//       hover: {
//         filter: {
//           type: "none", // Disable any hover effect
//         },
//       },
//       active: {
//         filter: {
//           type: "none", // Disable any active state effect
//         },
//       },
//     },
//     responsive: [
//       {
//         breakpoint: 768,
//         options: {
//           chart: {
//             width: 320,
//           },
//           legend: {
//             position: "bottom",
//           },
//         },
//       },
//       {
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: 330,
//           },
//           legend: {
//             position: "right", // Supported position
//           },
//         },
//       },
//     ],
//     // title: {
//     //   text: "",
//     //   align: "center",
//     // },
//   };

//   return (
//     <div>
//       <ReactApexChart
//         options={chartOptions}
//         series={series}
//         type="pie"
//         width="350"
//       />
//     </div>
//   );
// };

// export default PieChart;
const PieChart = ({ pieData, minRanks, competitor }) => {
  console.log(pieData, minRanks, competitor, "pieData22");

  // Extracting series and labels from the dynamic data
  let { series, labels } = pieData;

  // Pairing series values and labels together for sorting
  let data = series.map((value, index) => ({
    value,
    label: labels[index],
    minRank: minRanks[index],
  }));

  // Sort the data in descending order based on series values
  data.sort((a, b) => b.value - a.value);

  // Extract sorted series, labels, and minRanks
  series = data.map((item) => Math.round(item.value));
  labels = data.map((item) => item.label);
  const sortedMinRanks = data.map((item) => item.minRank);
  const isIncluded = minRanks.some((item) => labels.includes(item));
  console.log(isIncluded, labels, minRanks, series, competitor, "isIncluded");
  const LABLESIfNOTINCLUDE = new Set([...labels, ...competitor]);
  if (!isIncluded) {
    series = [...series];
    labels = [...LABLESIfNOTINCLUDE];
  }

  if (series.length === 0) return;

  const chartOptions = {
    chart: {
      type: "pie",
      width: 350,
    },
    stroke: {
      width: 1, // Remove the border by setting stroke width to 0
    },
    colors: ["#0cc013", "#cece14", "#fc6b03", "#fc0303"],
    legend: {
      position: "right",
    },
    labels: labels,
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const index = opts.seriesIndex; // Get the current index of the slice
        const minRank = sortedMinRanks[index]; // Fetch the corresponding minRank

        // Check if minRank is 0 and append the value
        return minRank === 0
          ? `${Math.floor(val)}% - Rank ${minRank}`
          : `${Math.floor(val)}%`;
      },
      style: {
        fontSize: "11px",
        colors: ["#fff"], // Change label color if needed
      },
    },
    tooltip: {
      y: {
        formatter: function (val, { seriesIndex }) {
          const minRank = sortedMinRanks[seriesIndex]; // Fetch corresponding minRank for tooltip
          return minRank === 0
            ? `${Math.floor(val)}% (Rank ${minRank})`
            : `${Math.floor(val)}%`;
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "none", // Disable any hover effect
        },
      },
      active: {
        filter: {
          type: "none", // Disable any active state effect
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 330,
          },
          legend: {
            position: "right", // Supported position
          },
        },
      },
    ],
  };

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="pie"
        width="350"
      />
    </div>
  );
};

export default PieChart;
