import React, { createContext, useContext, useState } from 'react';

const ProjectContext = createContext();

export const useProject = () => {
  return useContext(ProjectContext);
};

export const ProjectProvider = ({ children }) => {
  const [projectDetails, setProjectDetails] = useState({
    name: 'Project A',
    description: 'Description of Project A',
  });

  return (
    <ProjectContext.Provider value={{ projectDetails, setProjectDetails }}>
      {children}
    </ProjectContext.Provider>
  );
};