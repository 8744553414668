import { FaCheckCircle } from "react-icons/fa";

const UpgradeModal = (
    { onClose }
) => {
    return (
        <div
            onClick={(event) => {
                if (event.target === event.currentTarget) {
                    onClose();
                }
            }}
            className="pointer-events-auto fixed inset-0 z-[1200]
             grid h-screen w-screen place-items-center
             bg-greeng bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300 "
        >
            <div className="text-greeng bg-white rounded-xl py-9 px-6 flex flex-col ">
                <h2 className="text-xl font-bold text-center mb-2">It’s Time For Your Upgrade</h2>
                <p className="text-sm mb-6">Enjoy all features and explore Ranktera starting from <span className="font-extrabold text-mainColor ">10$</span> </p>
                <h3 className="font-semibold mb-4">What You Get </h3>
                <ul className="mb-6 mx-2">
                    <li className="text-sm font-medium  mb-4 flex items-center gap-2"><FaCheckCircle className="text-greeng" /> Create multiple projects</li>
                    <li className="text-sm font-medium  mb-4 flex items-center gap-2"><FaCheckCircle className="text-greeng" /> Search for multiple keywords</li>
                    <li className="text-sm font-medium  mb-4 flex items-center gap-2"><FaCheckCircle className="text-greeng" /> Invite your time to manage your account</li>
                </ul>
                <button className="bg-mainColor text-white rounded px-3 py-2 ml-auto">Choose Your Plan</button>
            </div>
        </div>
    )
}
export default UpgradeModal