import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import { store, persistor } from "./redux/store";
import { createRoot } from "react-dom/client";
import "react-datepicker/dist/react-datepicker.css";
import { ProjectProvider } from "./ProjectProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import store from "./redux/store"
const root = createRoot(document.getElementById("root"));
root.render(
  <>
    <GoogleOAuthProvider clientId='961739407242-g2o84v44fu63sit9ctqnulpb90n8j7tb.apps.googleusercontent.com'>

      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ProjectProvider>
              <App />
            </ProjectProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>

    </GoogleOAuthProvider>
  </>
);
