import React, { useState } from 'react';
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';

const Billing = () => {
    const [isYearly, setIsYearly] = useState(true); // Yearly by default
    const [selectedPlanId, setSelectedPlanId] = useState(null); // Store plan ID
    const navigate = useNavigate();

    // Plans with numeric IDs
    const plans = [
        { id: 1, name: 'Basic', yearlyPrice: 60, monthlyPrice: 5 },
        { id: 2, name: 'Pro', yearlyPrice: 120, monthlyPrice: 10 },
        { id: 3, name: 'Enterprise', yearlyPrice: 240, monthlyPrice: 20 },
    ];

    const handleBuyClick = () => {
        if (selectedPlanId) {
            // Store plan details in localStorage
            localStorage.setItem('planId', selectedPlanId); // Store plan ID as a number
            localStorage.setItem('planType', isYearly ? 'yearly' : 'monthly'); // Store plan type
            navigate('/billing-buy'); // Navigate to the next page
        } else {
            alert('Please select a plan before proceeding.');
        }
    };

    return (
        <div className="pt-[6rem] flex flex-col items-center p-8">
            {/* Header Section */}
            <div className="text-center">
                <h1 className="text-greeng dark:text-gray-300 font-bold mt-3 text-lg 2xl:text-xl xl:text-lg md:text-sm">
                    Choose Your Plan
                </h1>
                <p className="dark:text-gray-300 mt-2 mb-6">
                    Select the option you prefer and join our community.
                </p>

                {/* Toggle Between Yearly and Monthly */}
                <div className="flex justify-center mb-8">
                    <div className="flex bg-white rounded-full p-1 shadow-md">
                        <button
                            onClick={() => setIsYearly(false)}
                            className={`px-6 py-2 rounded-full transition-colors duration-300 font-semibold ${
                                !isYearly ? 'bg-mainColor text-white shadow-lg' : 'text-green-500'
                            }`}
                        >
                            Monthly
                        </button>
                        <button
                            onClick={() => setIsYearly(true)}
                            className={`px-6 py-2 rounded-full transition-colors duration-300 font-semibold ${
                                isYearly ? 'bg-mainColor text-white shadow-lg' : 'text-green-500'
                            }`}
                        >
                            Yearly
                        </button>
                    </div>
                </div>
            </div>

            {/* Pricing Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[33px] justify-items-center w-[100%] px-[50px] pb-[60px] ">
                {plans.map((plan) => (
                    <div
                        key={plan.id}
                        onClick={() => setSelectedPlanId(plan.id)} // Store plan ID (1, 2, or 3)
                        className={`p-6 rounded-[24px] shadow-lg transition-all duration-300 ease-in-out  
                            cursor-pointer pb-[68px] pt-[20px] px-[20px] w-[100%] mb-[40px] md:mb-0 ${
                                selectedPlanId === plan.id ? 'bg-mainColor ' : ''
                            }`}
                    >
                        {/* Plan Name */}
                        <h2 className={`text-center font-bold text-[18px] ${
                            selectedPlanId === plan.id ? 'text-white' : 'text-gray-800'
                        }`}>
                            {plan.name}
                        </h2>

                        {/* Plan Price */}
                        <div className={`text-[60px] text-center font-bold mt-4 mb-6 ${
                            selectedPlanId === plan.id ? 'text-white' : 'text-black'
                        }`}>
                            {isYearly ? plan.yearlyPrice : plan.monthlyPrice} 
                            <span className={`text-sm font-light ${
                                selectedPlanId === plan.id ? 'text-white' : 'text-black opacity-50'
                            }`}>
                                $/{isYearly ? 'yr' : 'mo'}
                            </span>
                        </div>

                        {/* Plan Features */}
                        <ul className={`mb-6 space-y-2 ${
                            selectedPlanId === plan.id ? 'text-white' : 'text-gray-600'
                        }`}>
                            {[
                                'Feature 1',
                                'Feature 2',
                                'Feature 3',
                                'Feature 4',
                                'Feature 5',
                            ].map((feature, index) => (
                                <li key={index} className="flex items-center">
                                    <GiCheckMark className={`mr-2 ${
                                        selectedPlanId === plan.id ? 'text-white' : 'text-green-500'
                                    }`} />
                                    {feature}
                                </li>
                            ))}
                        </ul>

                        {/* Buy Button */}
                        <button
                            onClick={handleBuyClick}
                            className={`w-full py-2 mt-4 rounded-full transition-all duration-300 font-semibold ${
                                selectedPlanId === plan.id
                                    ? 'bg-white text-mainColor'
                                    : 'bg-mainColor text-white'
                            }`}
                        >
                            Buy {plan.name}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Billing;
