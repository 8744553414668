import React, { useState } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import mastercard from '../../../assets/logo/logos_mastercard.png';
import paypal from '../../../assets/logo/logos_paypal.png';
import visa from '../../../assets/logo/visa.png';
import creditcard from '../../../assets/logo/tabler_credit-card.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const BillingBuy = () => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const navigate = useNavigate(); 

  const paymentMethods = [
    {
      id: 'visa',
      label: 'Visa',
      icon: <img src={visa} alt="Visa" className="h-auto w-[40px]" />,
      last4: '4568',
      expiry: '12/25'
    },
    {
      id: 'mastercard',
      label: 'MasterCard',
      icon: <img src={mastercard} alt="MasterCard" className="h-auto w-[30px]" />,
      last4: '4568',
      expiry: '12/25'
    },
    {
      id: 'paypal',
      label: 'PayPal',
      icon: <img src={paypal} alt="PayPal" className="h-auto w-[20px]" />,
      last4: '4568',
      expiry: '12/25'
    },
    {
      id: 'add-new',
      label: 'Add Payment Method',
      icon: <img src={creditcard} alt="Add \Payment" className="h-auto w-[24px]" />,
      isAddNew: true
    }
  ];
  


  const handleMethodClick = (method) => {
    const planId = localStorage.getItem('planId');
    const planType = localStorage.getItem('planType');

    if (planId) {
        console.log("PlanId:", planId, "PlanType:", planType, "Method:", method.id);
        navigate(`/add-payment?planId=${planId}&type=${planType}`);
    } else if (method.isAddNew) {
        navigate('/add-payment');
    } else {
        console.error("No PlanId or PlanType found.");
    }
};

  return (
    <div className="pt-[6rem] flex flex-col items-center px-4">
      <h1 className="text-gray-800 text-center font-bold mt-3 text-lg mb-6">Choose Your Payment Method</h1>

      <div className="space-y-4 w-full max-w-md">
        {paymentMethods.map((method) => (
          <div
            key={method.id}
            onClick={() => handleMethodClick(method)}
            className={`flex items-center justify-between p-4 border-2 rounded-lg cursor-pointer transition-all duration-200
              ${selectedMethod === method.id ? 'border-mainColor' : 'border-gray-300'}
            `}
          >
            <div className="flex items-center relative">
              <div className="mr-4 flex flex-col items-center ">
                {method.icon}
                {!method.isAddNew && (
                  <div className="text-xs text-gray-500 absolute bottom-0 ">Expire</div>
                )}
              </div>
              <div className="ml-4">
                <div className={`font-semibold ${selectedMethod === method.id ? 'text-mainColor' : 'text-gray-800'}`}>
                  {method.label}
                </div>
    
                {!method.isAddNew && (
                  <div className='text-[14px] text-gray-600'>
                    <div>XXXX XXXX XXXX {method.last4}</div>
                    <div >{method.expiry}</div>
                  </div>
                )}
              </div>
            </div>
            {selectedMethod === method.id && !method.isAddNew && (
              <span className="text-green-500 text-xl">
                <FaRegCheckCircle />
              </span>
            )}
          </div>
        ))}
        <button className="mt-8 bg-mainColor text-white py-2 px-6 rounded-lg w-full">
          Submit
        </button>
      </div>
    </div>
  );
};

export default BillingBuy;
