
import { useState, useEffect } from 'react';

const useFetchDetails = (url, retryCount = 3) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Track loading state

  useEffect(() => {
    const fetchData = async (retryAttempts) => {
      setIsLoading(true);
      try {
        const response = await fetch(url);
        if (!response.ok) {
          if (response.status === 502 && retryAttempts > 0) {
            // Retry after 1 second if a 502 error occurs
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return fetchData(retryAttempts - 1); // Retry the request
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        }
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {
        if (retryAttempts > 0) {
          console.log(`Retrying... Attempts left: ${retryAttempts - 1}`);
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait before retrying
          return fetchData(retryAttempts - 1); // Retry the request
        } else {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(retryCount);
  }, [url, retryCount]);

  return { data, error, isLoading };
};

export default useFetchDetails;