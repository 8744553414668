import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import customSelectStyles from '../../components/CustomStyling/customSelectStyles';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const FeedbackForm = () => {
    const { register, handleSubmit, control, formState: { errors }, reset } = useForm();
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userId = useSelector((state) => state.authSlice.user.id);
    const email = useSelector((state) => state.authSlice.user.email);
    const user = useSelector((state) => state.authSlice.user.name);
    const acceptedFileTypes = { 'image/png': 'PNG', 'image/jpeg': 'JPG' };

    const onDrop = acceptedFiles => {
        const file = acceptedFiles[0];
        setFile(Object.assign(file, { preview: URL.createObjectURL(file), data: file }));
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: Object.keys(acceptedFileTypes).join(', '),
    });

    const relatedToOptions = [
        { value: 'Rank Tracking', label: 'Rank Tracking' },
        { value: 'Keyword Research', label: 'Keyword Research' },
        { value: 'Ai Writer', label: 'AI Generation' },
        // { value: 'Log', label: 'Log' }
        { value: 'Log', label: 'Log' },
        { value: 'Ai Writer', label: 'ai' }
    ];

    const onSubmit = async data => {
        setIsLoading(true);
        let base64Image = '';
        if (file) {
            base64Image = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.data);
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        const formData = {
            ...data,
            image: base64Image
        };
        console.log(formData, "formData")
        const emailData = {
            subject: formData.subject,
            related_to: formData.relatedto.label, // Use label instead of the whole object
            email: email,
            issue: formData.issue,
            image: formData.image,
            user: user,
        };
        console.log(emailData, "emailData")
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/feedback/${userId}/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(emailData)
            });

            if (!response.ok) throw new Error('Network response was not ok');
            reset();
            setFile(null);
        } catch (error) {
            console.error('Fetch operation error:', error);
            toast.error('Failed to send feedback. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className=' border-2 border-gray-300  rounded-lg m-4 p-4 bg-white'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Subject:</label>
                    <input
                        type="text"
                        {...register('subject', { required: 'Subject is required' })}
                        className={`appearance-none border-2 rounded w-full py-2 px-3 focus:outline-none ${errors.subject ? 'border-red-500' : ''}`}
                        placeholder="Enter Subject"
                    />
                    {errors.subject && <p className="text-red-500 text-xs mt-1">{errors.subject.message}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Related To:</label>
                    <Controller
                        name="relatedto"
                        control={control}
                        rules={{ required: 'Related to is required' }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={relatedToOptions}
                                styles={customSelectStyles}
                                placeholder="Select a topic"
                            />
                        )}
                    />
                    {errors.relatedto && <p className="text-red-500 text-xs mt-1">{errors.relatedto.message}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Issue:</label>
                    <textarea
                        {...register('issue', { required: 'Issue description is required' })}
                        className={`appearance-none border-2 rounded w-full py-2 px-3 focus:outline-none ${errors.issue ? 'border-red-500' : ''}`}
                        placeholder="Please describe the issue"
                    />
                    {errors.issue && <p className="text-red-500 text-xs mt-1">{errors.issue.message}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Upload screenshot (optional):</label>
                    <div {...getRootProps({ className: 'dropzone' })} className="border-dashed border-2 rounded p-4 text-center cursor-grab">
                        <input {...getInputProps()} className='focus:outline-none' />
                        <p className="text-sm text-gray-500">Accepted file types: PNG, JPG</p>
                    </div>
                    {file && (
                        <div className="mt-4 w-32 h-32 border rounded">
                            <img src={file.preview} alt={file.name} className="w-full h-full object-contain" />
                        </div>
                    )}
                </div>
                <button
                    type="submit"
                    className="bg-mainColor text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center">
                            <FaSpinner className="animate-spin mr-2" />
                            Submitting...
                        </div>
                    ) : (
                        'Submit'
                    )}
                </button>
            </form>
            <ToastContainer />
        </div>
    );
};

export default FeedbackForm;
