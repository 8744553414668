import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { logOut, login } from "../../redux/lib/auth";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from "../../hook/useWebSocket";

const VerifiedEmail = () => {

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const userId = useSelector((state) => state.authSlice.id);
  const userToken = useSelector((state) => state.authSlice.token);
  const userName = useSelector((state) => state.authSlice.user.name);
  const userEmail = useSelector((state) => state.authSlice.user.email);
  // Send a request to verify the email using the token
  const verifyEmail = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/verify/${token}/`
      );
      setMessage(response.data.message);
      if (response.data.success) {
        setTimeout(() => {
          navigate("/projects");
        }, 2000);
      }

      dispatch(
        login({
          token: userToken,
          id: userId,
          name: userName,
          email: userEmail,
          verified: true,
          Roles: [],
        })
      );
    } catch (error) {
      console.error("Email verification error:", error);
      toast.error("An error occurred during the email verification process.");
    } finally {
      setLoading(false);
    }
  };

  // Navigate to the login page
  const handleLogin = () => {
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 text-center bg-white rounded-lg">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {message ? (
              <>
                {message === "Email verified successfully" ? (
                  <div>
                    <FaCheckCircle className="mx-auto text-5xl text-green-500" />
                    <p className="mt-4 font-semibold text-md">{message}</p>
                    <button
                      onClick={handleLogin}
                      className="px-4 py-2 mt-8 text-white rounded bg-mainColor"
                    >
                      Go to Projects
                    </button>
                  </div>
                ) : (
                  <div>
                    <p className="mt-4 font-semibold text-md">{message}</p>
                    <button
                      onClick={handleLogin}
                      className="px-4 py-2 mt-8 text-white rounded bg-mainColor"
                    >
                      Go to Projects
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <button
                  onClick={verifyEmail}
                  className="px-4 py-2 text-white bg-green-600 rounded"
                >
                  Verify Account
                </button>
                <p className="mt-3 font-semibold text-md">
                  Please click the button to verify your account.
                </p>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VerifiedEmail;
