import React, { useState, useEffect } from "react";
import { FaTrashAlt, FaSync, FaSpinner } from "react-icons/fa";
import useKeywordAndUrlProcessing from "../../../hook/useKeywordAndUrlProcessing";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Confirmation from "../../../components/Modal/Confirmation";
import { process_Keyword } from "../../../redux/lib/keywordSlice";
import "react-datepicker/dist/react-datepicker.css";
import useFetchDetails from "../../../hook/useFetchDetails";
import { jsonrepair } from "jsonrepair";
import { ImSpinner11 } from "react-icons/im";
import Title from "./keyword/Layout/Title";
import InputForm from "../../../components/keyword_research/keyword/InputForm";
import MainContent from "./keyword/Layout/MainContent";
// import { decrementDailyQuota } from "../../../redux/lib/auth";

const sanitizeHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  doc.querySelectorAll("img, svg, icon, figure").forEach((el) => el.remove());
  return doc.body.innerHTML;
};

const createMarkup = (html) => {
  return { __html: sanitizeHtml(html) };
};
const removeEmojis = (text) => {
  return text.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|\u24C2|\uD83D[\uDE00-\uDE4F])/g,
    ""
  );
};

const MainKeywordResearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingRows, setLoadingRows] = useState({});
  const [isKeywordDataLoading, setIsKeywordDataLoading] = useState(false);
  const [isUrlDataLoading, setIsUrlDataLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [url_Data, setUrl_Data] = useState([]);
  const [isUrlConfirmationOpen, setIsUrlConfirmationOpen] = useState(false);
  const [urlRowToDelete, setUrlRowToDelete] = useState(null);
  const [RequestSuccess, setRequestSuccess] = useState(false);
  const [apiUrl, setApiUrl] = useState(null); // State to store the API URL
  const [isLoading, setIsLoading] = useState(false); // State to handle loading
  const [data2, setData2] = useState(null); // State to store fetched data
  const [error, setError] = useState(null); // State to handle fetch errors
  const [isOpen, setIsOpen] = useState(false);
  const [selsectRowId, setSelectRowId] = useState();
  const [update, setUpdate] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false);

// selector
  const remainingQuota = useSelector((state) => state.authSlice.dailyQuota);
  const userId = useSelector((state) => state.authSlice.id);

  const existingKeywords = data.map((row) => row.query); // Array of keyword strings
// hooks
  const { data: fetchedData, error: fetchError } = useFetchDetails(apiUrl);


  const {
    handleSubmit,
    isKeywordLoading,
    isUrlLoading,
    keywordData,
    urlData,
    modalVisible,
    links,
    closeModal,
    handleKeywordSubmit,
    modalkeywordexist,
    setModalkeywordExist,
    selectedQuery,
    setModalVisible,
    setQuery,
    setLinks,
    setKeywordData,
    setShowDeleteButton,
    showDeleteButton,
    loadingMessage,
    setLoadingMessage,
    query,
    setSelectedRefreshLinks,
    selectedrefreshLinks,
    refreshLinks,

    isConfirmationrefreshOpen,
    setIsConfirmationRefreshOpen,
    isRefreshing,
    setIsRefreshing,
    setIsRefreshLoading,
    refreshloading,
    setIsRefreshingClose,
    isRefreshingClose,
    set_button_refresh,
    button_refresh
  } = useKeywordAndUrlProcessing(dispatch, userId, existingKeywords);



  const fetchData = async (retryCount = 4) => {
    setIsKeywordDataLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/user-data/${userId}/`
      );

      if (!response.ok) {
        if (
          (response.status === 502 || response.status === 500) &&
          retryCount > 0
        ) {
          // Retry after 1 second if a 502 Bad Gateway error occurs
          await new Promise((resolve) => setTimeout(resolve, 1000));
          return fetchData(retryCount - 1); // Retry the request
        } else {
          throw new Error(`Error: ${response.statusText}`);
        }
      }
      const result = await response.json();
      const formattedData = result
        .map((item) => ({
          id: item.object_id,
          query: item.query,
          date: new Date(item.created_at).toLocaleDateString(),
          createdAt: new Date(item.created_at),
          ...item
        }))
        .sort((a, b) => b.createdAt - a.createdAt); // Sort from new to old
      setRequestSuccess(true);
      setData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (retryCount > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait before retrying
        return fetchData(retryCount - 1); // Retry the request
      } else {
        // toast.error("Failed to fetch keyword data after multiple attempts.");
      }
    } finally {
      setIsKeywordDataLoading(false);
    }
  };

  const fetchUrlData = async (retryCount = 4) => {
    setIsUrlDataLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/user-data-url/${userId}/`
      );

      if (!response.ok) {
        if (
          (response.status === 502 || response.status === 500) &&
          retryCount > 0
        ) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          return fetchUrlData(retryCount - 1); // Retry the request
        } else {
          throw new Error(`Error: ${response.statusText}`);
        }
      }
      const result = await response.json();
      const formattedData = result
        .map((item) => ({
          id: item.object_id,
          title: item.title,
          date: new Date(item.created_at).toLocaleDateString(),
          createdAt: new Date(item.created_at),
        }))
        .sort((a, b) => b.createdAt - a.createdAt); // Sort from new to old
      // setRequestSuccess(false)

      setUrl_Data(formattedData);
    } catch (error) {
      console.error("Error fetching URL data:", error);
    } finally {
      setIsUrlDataLoading(false);
    }
  };

  const handleTitleClick = (row) => {
    navigate(`/urlData/${row.id}`);
  };

  const handleQueryClick = (row) => {
    const queryName = encodeURIComponent(row.query);
    navigate(`/detailskeyword/${queryName}`);
    // dispatch(process_Keyword({ query: row.query, userId }));
  };

  const filterData = () => {
    let filtered = data;
    if (startDate && endDate) {
      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0); // Set to start of the day
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999); // Set to end of the day
      filtered = filtered.filter(
        (item) => item.createdAt >= start && item.createdAt <= end
      );
    }

    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.query.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.date.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.id.toString().includes(lowerCaseSearchTerm)
      );
    }
    console.log(data, filtered, 'filteredfilteredfiltered');
    setFilteredData(filtered);
  };


  const extractDomain = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.startsWith("www.") ? domain.slice(4) : domain;
    } catch (error) {
      return "Invalid URL";
    }
  };

  const handleAccordionToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleRemoveClick = (row) => {
    setRowToDelete(row);
    setIsConfirmationOpen(true);
  };

  const onConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/Delete-data/${userId}/${rowToDelete.query}/`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setData((prevData) =>
          prevData.filter((item) => item.id !== rowToDelete.id)
        );
        toast.success(`${rowToDelete.query} removed successfully`);
        setIsConfirmationOpen(false);
      } else {
        toast.error(`Failed to remove item: ${response.statusText}`);
      }
    } catch (error) {
      toast.error("Error removing item");
      console.error("Error removing item:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const onCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleRemoveUrlClick = (row) => {
    setUrlRowToDelete(row);
    setIsUrlConfirmationOpen(true);
  };

  const onConfirmUrlDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/Delete-data-url/${userId}/${urlRowToDelete.id}/`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setUrl_Data((prevData) =>
          prevData.filter((item) => item.id !== urlRowToDelete.id)
        );
        toast.success(`${urlRowToDelete.title} removed successfully`);
        setIsUrlConfirmationOpen(false);
      } else {
        toast.error(`Failed to remove item: ${response.statusText}`);
      }
    } catch (error) {
      toast.error("Error removing item");
      console.error("Error removing item:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const onCloseUrlConfirmation = () => {
    setIsUrlConfirmationOpen(false);
  };
  const handleRefresh = (row) => {
    const queryName = row.query; // Adjust as per your data structure
    Query = queryName
    console.log(row, 'rowrowrow');

    setUpdate(true)
    const newApiUrl = `${process.env.REACT_APP_API_URL
      }/keyword/Query-data/${userId}/${encodeURIComponent(queryName)}/`;

    setLoadingRows(true);
    setIsOpen(true);
    setModalVisible(true)
    setApiUrl(newApiUrl);

  };

  const closeRefreshModal = () => {
    setLoadingRows((prev) => ({ ...prev, [selsectRowId]: false }));
    setIsOpen(false);
  };


  let jsonString, parsedResultsSample, filteredLinks, Query, allLinks;

  if (data2?.length > 0) {
    console.log(data2, 'combinedResultscombinedResults');

    // Parsing and repairing JSON only once to avoid duplication and potential caching issues
    const repairedResultsSample = jsonrepair(data2[0].selected_results_sample);
    parsedResultsSample = JSON.parse(repairedResultsSample);
    allLinks = JSON.parse(jsonrepair(data2[0].results_sample));
    allLinks = allLinks.filtered_links;
    const allLinksData = parsedResultsSample
    filteredLinks = allLinksData.filtered_links;

    Query = data2[0].query;
  }
  const Keyword_Query = keywordData?.Query;
  const keywordDataArray = keywordData?.Weights?.aggregated_h2_counts
    ? Object.entries(keywordData.Weights.aggregated_h2_counts).map(
      ([keyword, count]) => ({ keyword, count })
    )
    : [];
    const keywordData_Array = keywordData?.Weights?.aggregated_results || [];


  const columns = [

    {
      name: "Keyword",
      selector: (row) => row.query,
      sortable: true,
      cell: (row) => (
        <button
          className=" text-mainColor"
          onClick={() => handleQueryClick(row)}
        >
          {row.query}
        </button>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Refresh",
      cell: (row) => (
        <button
          onClick={() => handleRefresh(row)}
          className={`flex items-center justify-center p-2 rounded-lg ${loadingRows[row.id]
            ? "text-mainColor bg-white cursor-not-allowed"
            : "text-white bg-mainColor"
            }`}
          disabled={loadingRows[row.id]}
        >
          {/* Show spinner if this row is loading */}
          {loadingRows[row.id] ? (
            <ImSpinner11 className="animate-spin" />
          ) : (
            <ImSpinner11 />
          )}
        </button>
      ),
      button: "true",
    },
    {
      name: "Remove",
      cell: (row) => (
        <button
          onClick={() => handleRemoveClick(row)}
          className="flex items-center justify-center p-2 text-white rounded-lg bg-red-500"
        >
          <FaTrashAlt />
        </button>
      ),

    },
  ];


  const columns_URL = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => (
        <button
          className=" text-mainColor"
          onClick={() => handleTitleClick(row)}
        >
          {removeEmojis(row.title)}
        </button>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Remove",
      cell: (row) => (
        <button
          onClick={() => handleRemoveUrlClick(row)}
          className="flex items-center justify-center p-2 text-white rounded-lg bg-red-500"
        >
          <FaTrashAlt />
        </button>
      ),

    },
  ];

  const columns_ = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "70%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "20%",
    },
  ];

  const combinedH2H3Columns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "70%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "15%",
    },
    { name: "Type", selector: (row) => row.type, sortable: true, width: "15%" },
  ];


  const combinedH2H3DataArray = [
    ...(keywordData?.Weights?.aggregated_h2_counts
      ? Object.entries(keywordData.Weights.aggregated_h2_counts).map(
        ([keyword, count]) => ({ keyword, count, type: "H2" })
      )
      : []),
    ...(keywordData?.Weights?.aggregated_h3_counts
      ? Object.entries(keywordData.Weights.aggregated_h3_counts).map(
        ([keyword, count]) => ({ keyword, count, type: "H3" })
      )
      : []),
  ];


  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setFilteredData(data);
    setSearchTerm("");
  };
  const Refresh = async (query) => {
    // const apiUrl = `${process.env.REACT_APP_API_URL}/keyword/Query-data/${userId}/${encodeURIComponent(query)}/`; // Construct API URL
    // setApiUrl(apiUrl);


    setUpdate(true)
    const newApiUrl = `${process.env.REACT_APP_API_URL
      }/keyword/Query-data/${userId}/${encodeURIComponent(query)}/`;

    setLoadingRows(true);
    setIsOpen(true);
    setModalVisible(true)
    setApiUrl(newApiUrl);

    // setLoadingMessage(`Updating ${query}...`);
    // setRefreshLoading(true);

    // try {
    //   const response = await fetch(apiUrl);
    //   if (!response.ok) {
    //     throw new Error("Failed to update keyword data");
    //   }

    //   const responseData = await response.json();
    //   let allLinks;

    //   // if (responseData) {
    //   //   allLinks = JSON.parse(jsonrepair(responseData[0].results_sample)).filtered_links;
    //   //   // setLinks(allLinks); // Update filtered links in state
    //   //   setQuery(responseData[0].query);
    //   //   Query = responseData[0].query
    //   // }

    //   setModalVisible(true);
    //   setRefreshLoading(false);
    //   // setKeywordData(response);


    // } catch (error) {
    // } finally {
    //   setRefreshLoading(false);
    //   setLoadingMessage("");
    // }

  };
  useEffect(() => {
    fetchData();
    fetchUrlData();
  }, []);

  useEffect(() => {
    filterData();
  }, [startDate, endDate, searchTerm, data]);
  useEffect(() => {
    if (!apiUrl) return;

    // Reset states when the apiUrl changes to avoid showing stale data
    setIsLoading(true);
    setData2(null);
    setError(null);

    // Set data and error states based on fetchedData and fetchError
    if (fetchedData) {
      setData2(fetchedData);
      setIsLoading(false);
    }
    if (fetchError) {
      setError(fetchError);
      setIsLoading(false);
    }
  }, [apiUrl, fetchedData, fetchError, isOpen]);
  return (
    <div className="container p-4 mx-auto">
      {loadingMessage && (
        <div

          className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
    bg-greeng 
    bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
        >
          <div className="flex flex-col items-center gap-4 p-4">
            <FaSpinner className="animate-spin text-mainColor text-4xl" />

            <p className="text-lg  text-white">{loadingMessage}</p>

          </div>
        </div>
      )}

      <div
        className="relative w-full p-4 mx-auto mt-6 
            rounded-lg bg-baseColor dark:bg-darkbg md:mt-10 md:p-10"
      >
        <div className="col-span-3">
          <>
            <Title title="Main Keyword Research" />
            <InputForm
              onSubmit={handleSubmit}
              set_button_refresh={set_button_refresh}

              isLoading={isKeywordLoading || isUrlLoading}
            />
          </>
        </div>
        {modalkeywordexist && (
          <Confirmation
            keyword="continue"
            isOpen={modalkeywordexist}
            onClose={() => setModalkeywordExist(false)} // Closes modal on user action
            message="This keyword already exists in the table."
            onConfirm={() => {
              Refresh(selectedQuery);

            }}
            isDeleting={refreshLoading}
          />
        )}



        <MainContent
          update={update}
          button_refresh={button_refresh}
          set_button_refresh={set_button_refresh}
          showDeleteButton={showDeleteButton}
          setShowDeleteButton={setShowDeleteButton}
          filteredLinks={filteredLinks}
          isOpen={modalVisible}
          onClose={() => {
            setApiUrl(null)
            closeModal();
            setIsOpen(false);
          }}
          handleSubmit={handleSubmit}
          links={isOpen ? allLinks : links}
          RequestSuccess={RequestSuccess}
          isKeywordDataLoading={isKeywordDataLoading}
          isUrlDataLoading={isUrlDataLoading}
          columns={columns}
          columns_URL={columns_URL}
          data={filteredData}
          setFilteredData={setFilteredData}
          keywordData={Query ? Query : keywordData ? keywordData : query}

          urlData={urlData}
          url_Data={url_Data}
          setUrl_Data={setUrl_Data}
          setData={setData}
          combinedH2H3DataArray={combinedH2H3DataArray}
          combinedH2H3Columns={combinedH2H3Columns}
          keywordData_Array={keywordData_Array}
          columns_={columns_}
          openIndex={openIndex}
          handleAccordionToggle={handleAccordionToggle}
          createMarkup={createMarkup}
          extractDomain={extractDomain}
          handleQueryClick={handleQueryClick}

          selectedrefreshLinks={selectedrefreshLinks}
          setSelectedRefreshLinks={setSelectedRefreshLinks}
          refreshLinks={() => { refreshLinks(Query ? Query : keywordData ? keywordData : query) }}

          isConfirmationrefreshOpen={isConfirmationrefreshOpen}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          setIsConfirmationRefreshOpen={setIsConfirmationRefreshOpen}
          setIsRefreshLoading={setIsRefreshLoading}
          refreshloading={refreshloading}
          setIsRefreshingClose={setIsRefreshingClose}
          isRefreshingClose={isRefreshingClose}

        />
      </div>
      <Confirmation
        isOpen={isConfirmationOpen}
        onClose={onCloseConfirmation}
        onConfirm={onConfirmDelete}
        message={`Are you sure you want to delete "${rowToDelete?.query}"?`}
        isDeleting={isDeleting}
      />
      <Confirmation
        isOpen={isUrlConfirmationOpen}
        onClose={onCloseUrlConfirmation}
        onConfirm={onConfirmUrlDelete}
        message={`Are you sure you want to delete "${urlRowToDelete?.title}"?`}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default MainKeywordResearch;
