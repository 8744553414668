import React from 'react';
import DataTable from 'react-data-table-component';
import SkeletonTable from '../../../components/Skeleton/SkeletonTable';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const columns = [
    { name: 'Query', selector: row => row.query, sortable: true },
    { name: 'Target URL', selector: row => row.target_url, sortable: true },
    { name: 'Project Name', selector: row => row.project_name, sortable: true },
    { name: 'Created At', selector: row => new Date(row.created_at).toLocaleString(), sortable: true },
    { name: 'Action', selector: row => row.action, sortable: true },
    { name: 'User', selector: row => row.user, sortable: true },
];

const customStyles = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: '600',
            color: '#374151',
            paddingLeft: '16px',
            paddingRight: '16px',
        },
    },
    cells: {
        style: {
            fontSize: '14px',
            color: '#4B5563',
            paddingLeft: '16px',
            paddingRight: '16px',
        },
    },
};
const ActivityLogsTable = ({ logs, isLoading, onDelete }) => {
    return (
        <div className='overflow-x-auto'>
            {isLoading ? (
                <SkeletonTable />
            ) : (
                logs.length > 0 ? (
                    <DataTable
                        columns={columns}
                        customStyles={customStyles}
                        data={logs}
                        pagination
                        responsive
                        highlightOnHover
                    />
                ) : (
                    <p>No logs found for the selected date range.</p>
                )
            )}
        </div>
    );
};

export default ActivityLogsTable;
