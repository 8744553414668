import React, { useEffect, useState } from "react";
import AddProgressBar from "./AddProgressbar";
import SharedProgressBar from "./SharedProgressbar";
import EditProgressBar from "./EditProgressBar";
import { ArrowDownCircleIcon } from "@heroicons/react/20/solid";
import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import DeleteProgressBar from "./DeleteProgressbar";

const OperationComplete = ({
  isDelete,
  isDeleting,
  showProgressBar,
  completedUpdates,
  updateErrors,
  totalUpdates,
  fadeOut,
  progressBarVisible,
  showAddProgressBar,
  completedAdds,
  addErrors,
  totalAdds,
  currentQueryName,
  setShowProgressBar,
  setFadeOut,
  setCompletedUpdates,
  setUpdateErrors,
  setTotalUpdates,
  setShowAddProgressBar,
  setCompletedAdds,
  setAddErrors,
  setTotalAdds,
  isEditInProgress,
  editProgress,
  editError,
  setEditProgress,
  setTotalDeletes,
  setCompleteDeletes,
  totalDeletes,
  completeDeletes,
  completedQueriesList
}) => {
  useEffect(() => {
    const updatesComplete = completedUpdates === totalUpdates;
    const updatesWithError = completedUpdates + updateErrors === totalUpdates;

    if ((updatesComplete || updatesWithError) && showProgressBar) {
      const fadeOutTimer = setTimeout(() => {
        setShowProgressBar(false);
        setFadeOut(false);
        setCompletedUpdates(0);
        setUpdateErrors(0);
        setTotalUpdates(0);
      }, 2000);

      return () => {
        clearTimeout(fadeOutTimer);
      };
    }
  }, [
    completedUpdates,
    updateErrors,
    totalUpdates,
    showProgressBar,
    setShowProgressBar,
    setFadeOut,
    setCompletedUpdates,
    setUpdateErrors,
    setTotalUpdates,

    isDelete,
  ]);

  useEffect(() => {
    const addsComplete = completedAdds === totalAdds;
    const addsWithError = completedAdds + addErrors === totalAdds;
    if ((addsComplete || addsWithError) && showAddProgressBar) {
      const timer = setTimeout(() => {
        setShowAddProgressBar(false);

        setCompletedAdds(0);
        setAddErrors(0);
        setTotalAdds(0);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [
    completedAdds,
    addErrors,
    totalAdds,
    showAddProgressBar,
    setShowAddProgressBar,
    setCompletedAdds,
    setAddErrors,
    setTotalAdds,
    isDelete,
  ]);

  // useEffect(() => {
  //   // Store progress bar visibility state in session storage
  //   sessionStorage.setItem('showProgressBar', JSON.stringify(showProgressBar));
  // }, [showProgressBar]);

  const operationsCompleted = !(
    showProgressBar ||
    showAddProgressBar ||
    isEditInProgress ||
    isDelete
  );
console.log(completedQueriesList,"completedQueriesList");

  return (
    <>
      {!operationsCompleted && (
        <div className="addUpdateBox fixed bottom-[10px] md:right-[10px] right-[18px] z-[1400] md:p-4 p-1 w-[91%] md:w-[18%] lg-plus:w-[15%] bg-white border-2 border-gray-200 rounded-lg">
          {showProgressBar && (
            <div className="font-semibold mt-[5px] rounded-md z-[1400] bg-white">
              <SharedProgressBar
                setTotalUpdates={setTotalUpdates}
                setCompletedUpdates={setCompletedUpdates}
                completedUpdates={completedUpdates}
                updateErrors={updateErrors}
                totalUpdates={totalUpdates}
                fadeOut={fadeOut}
                progressBarVisible={progressBarVisible}
                setShowProgressBar={setShowProgressBar}
                completedQueriesList={completedQueriesList}
              />
            
            {/* <div className="mt-2 text-sm text-green-600">
                <ul>
                  {completedQueriesList.map((query, index) => (
                    <li key={index}>{query}</li>
                  ))}
                </ul>
              </div> */}

              <div className="mt-2">
                {updateErrors > 0 && (
                  <span className="ml-2 text-red-500">
                    , with {updateErrors} error(s)
                  </span>
                )}
              </div>
            </div>
          )}
          {isDelete && (
            <div className="font-semibold mt-[5px] rounded-md z-[1000] bg-white">
              <DeleteProgressBar
                setTotalDeletes={setTotalDeletes}
                setCompleteDeletes={setCompleteDeletes}
                totalDeletes={totalDeletes}
                completeDeletes={completeDeletes}
                updateErrors={updateErrors}
                fadeOut={fadeOut}
                progressBarVisible={progressBarVisible}
              />
              <div className="mt-2">
                {updateErrors > 0 && (
                  <span className="ml-2 text-red-500">
                    , with {updateErrors} error(s)
                  </span>
                )}
              </div>
            </div>
          )}
          {isEditInProgress && (
            <div className="font-semibold mt-[5px] rounded-md z-[1000] bg-white">
              <EditProgressBar
                isEditInProgress={isEditInProgress}
                editProgress={editProgress}
                editError={editError}
                setEditProgress={setEditProgress}
              />
            </div>
          )}
          {showAddProgressBar && (
            <div className="z-[1000] bg-white">
              <AddProgressBar
                completedAdds={completedAdds}
                addErrors={addErrors}
                totalAdds={totalAdds}
                fadeOut={fadeOut}
                showAddProgressBar={showAddProgressBar}
              />
              <div className="mt-2">
                {addErrors > 0 && (
                  <span className="ml-2 text-red-500">
                    , with {addErrors} error(s)
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OperationComplete;
