import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "../../utils/validation";
import { login } from "../../redux/lib/auth";
import { setOriginalUser } from "../../redux/lib/originalUserSlice";
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import createLog from '../../pages/SidebarPages/Log/createLog';
import { FcGoogle } from "react-icons/fc";
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';


const LoginForm = ({ NameUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verified = useSelector(state => state.authSlice.verified);
  const [csrfToken, setCsrfToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const originalUser = useSelector((state) => state.originalUserSlice.name);
  const userId = useSelector(state => state?.authSlice?.id);
  const [signInType, setSignInType] = useState("regular");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(loginSchema),
    mode: "onChange"

  });

  useEffect(() => {
    const showVerifyMessage = localStorage.getItem('showVerifyMessage');
    if (showVerifyMessage) {
      toast.info("Please verify your account through email to access all features");
      localStorage.removeItem('showVerifyMessage');
    }
  }, []);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    setCsrfToken(token);
  }, []);



  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const loginData = {
        identifier: data.identifier,
        password: data.password,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login/`,
        loginData,
        {
          headers: {
            "Content-Type": "application/json",
            // 'CSRF-Token': csrfToken, // Uncomment if CSRF token is required
          },
        }
      ).then((res) => {
        //  "provider": "Google"
        const { token, id, Name, Email,
          "Email Verified": verified,
          Roles, daily_quota, provider,
          ACCOUNT_TYPE }
          = res?.data;



        toast.success("Login successfully");
        dispatch(setOriginalUser({
          token,
          id,
          verified,
          Roles,
          name: Name,
          email: Email,
          provider: provider
        }));
        // Dispatch login action to update Redux store
        dispatch(login({
          token,
          id,
          verified,
          Roles,
          name: Name,
          email: Email,
          daily_quota, // Include daily_quota in the login action payload
          ACCOUNT_TYPE,
          provider: provider

        }));
        // Ensure userId is defined before creating the log
        //  if (id) {
        //   createLog(`Login by ${Name}`, id);
        // } else {
        //   console.error('User ID is undefined or null. Log entry cannot be created.');
        // }

        // Navigate to the home page after successful login
        navigate("/");

      }).catch((err) => {
        toast.error(err?.response?.data?.error);
      })

    } catch (error) {
      console.error("Login failed", error);
    }
    finally {
      setIsLoading(false);
    }
    // }
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const loginGoogle = useGoogleLogin({
    onSuccess: tokenResponse => setUser(tokenResponse),

  });

  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            setProfile(res.data);

            axios.post(`${process.env.REACT_APP_API_URL}/api/googleuser/`,
              JSON.stringify({
                username: res.data.given_name,
                email: res.data.email,
                google_id: res.data.id
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                  // 'CSRF-Token': csrfToken, // Uncomment if CSRF token is required
                },
              }
            ).then((res) => {
              const { token, id, Name, Email,
                "Email Verified": verified,
                Roles, daily_quota, provider,
                ACCOUNT_TYPE }
                = res?.data;

              console.log(res.data, 'res.data');


              toast.success("Login successfully");
              dispatch(setOriginalUser({
                token,
                id,
                verified,
                Roles,
                name: Name,
                email: Email,
                provider
              }));
              // Dispatch login action to update Redux store
              dispatch(login({
                token,
                id,
                verified,
                Roles,
                name: Name,
                email: Email,
                daily_quota, // Include daily_quota in the login action payload
                ACCOUNT_TYPE,
                provider

              }));

              navigate("/");

            }).catch((err) => {

              toast.error(err?.response?.data?.error);
            })
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full max-w-md p-6 mx-auto mt-20 rounded-lg shadow-custom sm:mt-10"
    >
      <h2 className="mb-3 text-2xl font-bold text-center text-mainColor">
        RankTracker
      </h2>
      <h3 className="mt-3 text-lg font-bold text-mainColor lg:text-xl md:text-2xl">
        Sign-in
      </h3>
      <p className="mt-4 mb-3 text-md">
        Access Ranktracker using your name or email and password.
      </p>
      <div className="mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">
          Email:
        </label>
        <input
          type="text"
          placeholder="Enter your email"
          {...register("identifier")}
          className="w-full px-3 py-2 border rounded-lg md:text-lg text-md
           focus:outline-none focus:ring-darkblue "
        />
        {errors.identifier && (
          <p className="mt-1 text-xs text-red-500">{errors.identifier.message}</p>
        )}
      </div>

      <div className="relative mb-6">
        <label className="font-bold text-gray-600 
        transition-all
         cursor-text peer-focus:text-xs 
         peer-focus:-top-4 
         peer-focus:text-purple-600">
          Password:
        </label>
        <input
          placeholder="Enter your password"
          type={passwordVisible ? "text" : "password"}
          {...register("password")}
          className="w-full px-3 py-2 border rounded-lg 
          md:text-lg text-md
            focus:outline-none  focus:ring-darkblue"
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 flex 
          items-center px-2 mt-6 focus:outline-none"
        >
          {!passwordVisible ? (
            <HiEyeOff className="text-gray-600" />
          ) : (
            <HiEye className="text-gray-600" />
          )}
        </button>
        {errors.password && (
          <p className="mt-1 text-xs text-red-500">{errors.password.message}</p>
        )}
      </div>

      <button
        type="submit"
        onClick={() => setSignInType("regular")}

        disabled={isLoading}
        className={`px-5 py-2 mt-5 font-semibold text-white 
          rounded transition-colors w-[90%] mx-auto ${isLoading
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-mainColor hover:bg-mainColor-700'
          }`}

      >
        {isLoading ? (
          <>
            <FaSpinner className="inline-block animate-spin mr-2" />
            Signing In...
          </>
        ) : (
          'Sign In'
        )}
      </button>



      <button
        type="button" // Set to "button" to avoid form submission

        onClick={loginGoogle} // Directly call Google Sign-In handler

        className={`px-4 py-2 mt-5 
          font-semibold text-black
           rounded transition-colors 
           w-[90%] mx-auto border-2
          border-gray-200  `}

      >
        <FcGoogle className="inline-block  mr-2 " size={20} />
        Sign In with Google
      </button>



      <div className="mb-4 text-center">
        <p className="mt-4 text-md">
          Don't have an account?{" "}
          <span className="cursor-pointer text-mainColor">
            <Link to="/register">Create Account</Link>
          </span>
        </p>
      </div>
      <div className=" text-center">
        <a href="/forgot-password" className="text-red-500 underline">
          Forgot your password?
        </a>
      </div>
    </form>
  );
};

export default LoginForm;
