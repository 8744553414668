import axios from 'axios';
import { toast } from 'react-toastify';

const createLogQuery = async (action, userId) => {
    try {
        const logUrl = `${process.env.REACT_APP_API_URL}/api/ActionlogCreate/${userId}/`;
        const response = await axios.post(logUrl, { action });
        if (response.status === 201) {
            console.log('Log created successfully');
        } else {
            toast.error('Failed to create log');
        }
    } catch (error) {
        toast.error('Error creating log action');
        console.error('Failed to create log:', error);
    }
};

export default createLogQuery;
