import { useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import AddCompetitors from "../components/Modal/AddCompetitors";
import { useFetchData, usePagination } from "../hook";
import { useLocation } from "react-router-dom";
import { PaginationControls } from "../components";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { FaHome } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {

  FaSort,
  FaSortDown,
  FaSortUp,
} from "react-icons/fa6";
import ConfirmationDialogg from "../components/Modal/ConfirmationDialogg";
import ReactDatePicker from "react-datepicker";
import PieChart from "../components/PieChartForCompetitors";
import ReactApexChart from "react-apexcharts";

const ProjectCompetitors = () => {
  // states and hooks
  const competitors = useSelector((state) => state.projectCompetitors);
  const userId = useSelector((state) => state.authSlice.user.id);
  const location = useLocation();
  const projectId = location.pathname.split("/")[1];
  const [selectedDate, setSelecetedDate] = useState(new Date());

  const { tableData, competitor, loading } = useFetchData(
    userId,
    projectId,
    null,
    null,
    selectedDate
  );

  const links = competitors.links
    ? competitors.links.filter((link) => link.domain != "")
    : competitor;
  const projectName = competitors.projectName;
  const projectUrl = competitors.projectUrl;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [allCompetitors, setAllCompetitors] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [pieData, setPieData] = useState({ series: [], labels: [], rowIndexes: [] });
  const [openChart, setOpenChart] = useState(false);
  const [minRanks, setMinRanks] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [matchingEntry, setMatchingEntry] = useState(localStorage.getItem("matchingEntry"));

  const {
    currentPage,
    currentRows,
    totalPages,
    paginate,
    setRowsPerPage,
    setCurrentPage,
    setCurrentRows,
    rowsPerPage,
  } = usePagination(allCompetitors, 10);
  // variables
  let maxRanks = [];

  // functions


  const calculatePercentage = (domains) => {
    const total = domains.length; // Total number of entries
    const domainCount = {};
    // Count the occurrences of each domain
    const filterDomains = domains.filter(item => item !== null);

    filterDomains.forEach((domain) => {
      if (domainCount[domain]) {
        domainCount[domain] += 1;
      } else {
        domainCount[domain] = 1;
      }
    });
    const series = Object.values(domainCount).map(
      (count) => (count / total) * 100
    );
    const labels = Object.keys(domainCount);
    // Calculate the percentage for each domain


    return { series, labels };
  };
  const getKeyWithMinValue = (row) => {
    let minKey = null;
    let minValue = Infinity; // Start with a very large number
    let otherkey = [];
    // Loop through the object entries
    Object.entries(row).forEach(([key, value]) => {
      // Exclude 'rank_diff', but consider 0 as a valid minimum value
      if (key !== "rank_diff" && typeof value === "number") {
        if (value < minValue) {
          minValue = value;
          minKey = key;
        } else {
          otherkey.push(key);
        }
      }
    });

    return { minKey, otherkey };
  };
  const getPieData = (row, rowIndex) => {
    const { minKey, otherkey } = getKeyWithMinValue(row);

    // Push both minKey and rowIndex together into maxRanks
    maxRanks.push({ minKey, rowIndex });

    // Store other keys with their row index
    setMinRanks((prev) => [...prev, ...otherkey.map((key) => ({ key, rowIndex }))]);

    // Calculate percentages for the pie chart
    const { series, labels } = calculatePercentage(maxRanks.map((rank) => rank.minKey));

    // Include row indexes with pie data
    setPieData({ series, labels, rowIndexes: maxRanks });
  };
  const collectGroupedMinKeys = (allCompetitors) => {
    const groupedMinKeys = {}; // Object to group indexes by minKey

    allCompetitors.forEach((row, rowIndex) => {
      const { minKey } = getKeyWithMinValue(row); // Assuming getKeyWithMinValue is defined


      if (minKey) {
        // Initialize the key in the object if it doesn't exist
        if (!groupedMinKeys[minKey]) {
          groupedMinKeys[minKey] = [];
        }
        // Add the rowIndex to the array for this minKey
        groupedMinKeys[minKey].push(rowIndex);
      }
    });

    // Convert the grouped object into the desired array format
    return Object.entries(groupedMinKeys).map(([minKey, rowIndex]) => ({
      minKey,
      rowIndex,
    }));
  };
  const handleSort = (key) => {
    let direction = "ascending";
    console.log(sortConfig, key, 'sortConfig');
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        direction = "descending";
      } else if (sortConfig.direction === "descending") {
        setSortConfig({ key: "", direction: "" });
        return;
      }
    }
    setSortConfig({ key, direction });
  };
  const sortedRows = useMemo(() => {
    let sortableItems = [...currentRows];
    if (sortConfig.key) {
        sortableItems.sort((a, b) => {
            // Handle cases where the key might not exist in all objects
            const valueA = a[sortConfig.key] ?? '';
            const valueB = b[sortConfig.key] ?? '';

            // Special handling for different data types
            if (typeof valueA === 'number' && typeof valueB === 'number') {
                return sortConfig.direction === "ascending" 
                    ? valueA - valueB 
                    : valueB - valueA;
            }

            // String comparison with locale-aware sorting
            const stringA = String(valueA).toLowerCase();
            const stringB = String(valueB).toLowerCase();

            if (stringA < stringB) {
                return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (stringA > stringB) {
                return sortConfig.direction === "ascending" ? 1 : -1;
            }
            return 0;
        });
    }
 console.log(sortableItems,'sortableItems');
 
    return sortableItems;
}, [currentRows, sortConfig]);

  const result = useMemo(() => {
    const groupedKeys = collectGroupedMinKeys(sortedRows);
    return groupedKeys;
  }, [sortedRows]);

  useEffect(() => {
    const clickedLabel = localStorage.getItem("clickedLabel");
    const matchingEntry = result?.find((entry) => entry.minKey === clickedLabel);
    if (matchingEntry) {
      localStorage.setItem("matchingEntry", matchingEntry.rowIndex);
      window.dispatchEvent(new Event("localStorageUpdate"));
    } else {
      console.log(`No matching entry found for label: ${clickedLabel}`);
    }
  }, [result]);


  const getMinMaxValues = (row) => {
    // Filter out 'rank_diff' and any values that are 0 or not numbers
    const values = Object.entries(row)
      .filter(
        ([key, value]) => key !== "rank_diff" && typeof value === "number"
        // && key !== projectUrl
      )
      .map(([, value]) => value);

    const min = Math.min(...values);
    const max = Math.max(...values);

    return {
      min,
      max,
      count: values.length,
    };
  };

  function extractDomain(url) {
    let domain;
    // Remove protocol (http, https, etc.) and get domain
    if (url.indexOf("://") > -1) {
      domain = url.split("/")[2];
    } else {
      domain = url.split("/")[0];
    }
    // Remove port number if present
    domain = domain.split(":")[0];
    // Remove "www."
    domain = domain.replace("www.", "");
    return domain;
  }
  function parseLinks(linksString) {
    let links = [];
    try {
      if (
        linksString != null &&
        linksString.length > 0 &&
        !linksString.startsWith("g") &&
        !linksString.startsWith("n") &&
        !linksString.startsWith("a")
      ) {
        links = JSON.parse(linksString.replace(/'/g, '"'));
      }
    } catch (e) {
      links = [""];
    }

    return links;
  }
  const collectQueries = (data) => {
    let result = data
      .map((item) => {
        let links = parseLinks(item.first_five_links);

        return links
          .map((link, index) => {
            let domain = extractDomain(link);
            // if (selectedLinks.includes(domain)) {
            return {
              query: item.query,
              link: domain,
              index: index,
              rank_diff: item.rank_diff,
            };
            // }
          })
          .filter((item) => item !== undefined);
      })
      .flat();

    let lowestIndexMap = {};

    result.forEach((item) => {
      let key = `${item.query}-${item.link}`;
      if (!lowestIndexMap[key] || lowestIndexMap[key].index > item.index) {
        lowestIndexMap[key] = item;
      }
    });

    // Convert the result back to an array
    let filteredResults = Object.values(lowestIndexMap);
    let groupedMatches = {};

    filteredResults.forEach((match) => {
      if (!groupedMatches[match.query]) {
        groupedMatches[match.query] = [];
      }
      groupedMatches[match.query].push({
        link: match.link,
        index: match.index,
        rank_diff: match.rank_diff,
      });
    });

    // Convert groupedMatches to the desired format
    let result2 = Object.keys(groupedMatches).map((query) => {
      return {
        query: query,
        matches: groupedMatches[query],
      };
    });

    let results3 = [];
    result2.forEach((item) => {
      let resultItem = {
        query: item.query,
        rank_diff: item.rank_diff,
      };

      item.matches.forEach((match) => {
        let domain = match.link;
        if (selectedLinks.includes(domain)) {
          resultItem[domain] = match.index;
          resultItem.rank_diff = match.rank_diff;
        }
      });

      results3.push(resultItem);
    });

    setAllCompetitors(results3);
  };
  const sendLinks = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/Update-Project-comp/${userId}/${projectId}/`,
        {
          compatetor: selectedLinks,
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onConfirm = () => {
    sendLinks();
    collectQueries(tableData);
    setIsOpen(false);
  };
  const removeSeleted = () => {
    if (selectedLinks.length === 2 && selectedLinks.includes(projectUrl)) {
      setSelectedLinks([]);
    } else {
      setSelectedLinks((oldValues) => {
        return oldValues.filter((old) => old !== linkToDelete);
      });
      maxRanks.filter((old) => old !== linkToDelete);
    }

    setIsOpenDelete(false);
  };
  const OpenDeleteConfirm = (link) => {
    setIsOpenDelete(true);
    setLinkToDelete(link);
  };
  const handleSelect = (link) => {
    if (selectedLinks.length < 4) {
      if (!selectedLinks.includes(projectUrl)) {
        setSelectedLinks([projectUrl, ...selectedLinks, link]);
      } else {
        setSelectedLinks([...selectedLinks, link]);
      }
    }
  };
  // effects
  useEffect(() => {
    setSelectedLinks(
      competitor ? JSON.parse(competitor?.replace(/'/g, '"')) : []
    );
  }, [tableData, loading]);
  useEffect(() => {
    if (selectedLinks.length !== 0) {
      sendLinks();
    }
    collectQueries(tableData);
  }, [selectedLinks]);
  useEffect(() => {
    allCompetitors.forEach((result, index) => {
      getPieData(result, index);
    });
  }, [selectedLinks, allCompetitors, isOpenDelete, tableData, loading]);
  const handleStorageChange = () => {
    const storedEntry = localStorage.getItem("matchingEntry");
    setMatchingEntry(storedEntry);
  };
  useEffect(() => {
    // Function to update the state whenever localStorage changes

    // Listen for changes to localStorage
    window.addEventListener("localStorageUpdate", handleStorageChange);
    window.addEventListener("storage", handleStorageChange);

    // Set initial state
    handleStorageChange();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("localStorageUpdate", handleStorageChange);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  let data = pieData.series.map((value, index) => ({
    value,
    label: pieData.labels[index],
    minRank: minRanks[index],
  }));
  data.sort((a, b) => b.value - a.value);

  let series = data.map((item) => Math.round(item.value));
  let labels = data.map((item) => item.label);
  const isIncluded = minRanks.some((item) => labels.includes(item));
  const LABLESIfNOTINCLUDE = new Set([...labels, ...selectedLinks]);

  if (!isIncluded) {
    series = [...series];
    labels = [...LABLESIfNOTINCLUDE];
  }

  return (
    <>
      <ConfirmationDialogg
        btnText={"Delete"}
        message={"Are you sure you want to delete this competitor?"}
        isOpen={isOpenDelete}
        onConfirm={removeSeleted}
        onClose={() => {
          setIsOpenDelete(false);
        }}
      />

      {loading && (
        <div className=" h-screen w-full  p-4 mt-[4rem] flex items-center justify-center">
          <ClipLoader size={30} color={"green"} loading={true} />
        </div>
      )}
      {!loading && (
        <>
          <AddCompetitors
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            onConfirm={onConfirm}
            links={links}
            setSelectedLinks={setSelectedLinks}
            selectedLinks={selectedLinks}
            projectUrl={projectUrl}
            extractDomain={extractDomain}
            OpenDeleteConfirm={OpenDeleteConfirm}
            removeSeleted={removeSeleted}
            isOpenDelete={isOpenDelete}
            handleSelect={handleSelect}
            openNew={() => {
              setOpenAddNew(true);
            }}
            openAddNew={openAddNew}
          />
          {/* // )} */}

          <div className="w-full  lg:p-4 p-2 mt-[4rem] relative ">
            <div
              onClick={(event) => {
                // if (event.target === event.currentTarget) {
                setOpenChart(false);
                // }
              }}
              className="flex items-center justify-between flex-wrap gap-3 px-3"
            >
              <h1 className="font-bold text-xl capitalize">
                competitors for {projectName}
              </h1>
              <div className="flex items-center gap-3 ">
                <ReactDatePicker
                  id="start-date"
                  selected={selectedDate}
                  onChange={(date) => setSelecetedDate(date)}
                  placeholderText="choose Date"
                  className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-mainColor border-2"
                />
                <div
                  className={`${tableData.length === 0 && selectedLinks.length > 0
                    ? "relative tooltipcontainer"
                    : ""
                    }`}
                >
                  <button
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    disabled={
                      tableData.length === 0 && selectedLinks.length > 0
                    }
                    className={`${tableData.length === 0 && selectedLinks.length > 0
                      ? "cursor-not-allowed"
                      : ""
                      } bg-mainColor px-3 lg:py-2 py-3 capitalize text-white rounded-lg lg:text-base text-sm`}
                  >
                    + Add competitors
                  </button>

                </div>
              </div>
            </div>

            {allCompetitors.length === 0 && selectedLinks.length === 0 && (
              <p className="p-5 font-medium">
                You don't have any competitor selected.
                <span className="px-2 text-sm text-gray-600 ">
                  You can assign competitors to this domain by clicking the "Add
                  Competitor" button at the right corner.
                </span>
              </p>
            )}
            {tableData.length > 0 && selectedLinks.length > 0 && (
              <div>
                <div
                  className="overflow-auto"
                  onClick={(event) => {
                    // if (event.target === event.currentTarget) {
                    setOpenChart(false);
                    // }
                  }}
                >
                  <table className="w-full text-left border-collapse my-3 table-auto">
                    <thead className="bg-[#f3f4f6] dark:bg-darkbg">
                      <tr className="">
                        <th className="p-2 text-center border-b-2 cursor-pointer">
                          <div className="flex items-center justify-center gap-2">
                            {allCompetitors.length} Keyword
                            {sortConfig.key === "query" &&
                              (sortConfig.direction === "ascending" ? (
                                <FaSortUp
                                  color="#bebcbc"
                                  onClick={() => handleSort("query")}
                                />
                              ) : sortConfig.direction === "descending" ? (
                                <FaSortDown
                                  color="#bebcbc"
                                  onClick={() => handleSort("query")}
                                />
                              ) : (
                                ""
                              ))}
                            {sortConfig.key !== "query" && (
                              <FaSort
                                color="#bebcbc"
                                onClick={() => handleSort("query")}
                              />
                            )}
                          </div>
                        </th>
                        {selectedLinks.map((link, i) => {
                          return (
                            <th
                              key={i}
                              className="p-2 text-center border-b-2 cursor-pointer"
                              onClick={() => handleSort(link)}
                            >
                              <div className="flex items-center justify-center gap-2">
                                {link === projectUrl ? (
                                  <>
                                    {link}
                                    <FaHome />
                                  </>
                                ) : (
                                  <>
                                    {link}
                                    <MdDelete
                                      color="red"
                                      onClick={() => OpenDeleteConfirm(link)}
                                      className="cursor-pointer"
                                    />
                                  </>
                                )}
                                {sortConfig.key === "query" &&
                                  (sortConfig.direction === "ascending" ? (
                                    <FaSortUp
                                      color="#bebcbc"
                                      onClick={() => handleSort("query")}
                                    />
                                  ) : sortConfig.direction === "descending" ? (
                                    <FaSortDown
                                      color="#bebcbc"
                                      onClick={() => handleSort("query")}
                                    />
                                  ) : (
                                    ""
                                  ))}
                                {sortConfig.key !== "query" && (
                                  <FaSort
                                    color="#bebcbc"
                                    onClick={() => handleSort("query")}
                                  />
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-500 max-h-[500px] overflow-scroll ">
                      {sortedRows.map((result, index) => {
                        const { min, max, count } = getMinMaxValues(result);

                        return (
                          <tr
                            key={index}
                            className={`hover:bg-[#f3f4f6]
                             cursor-pointer text-gray-950
                              dark:text-gray-300 py-2 
                            
                              `}

                          >
                            <td className={`p-3 whitespace-nowrap`}>
                              <span className={`${matchingEntry?.split(",").map(Number)?.includes(index) ? "bg-[#fef08a]" : "bg-white"}`}>

                                {result.query}
                              </span>
                              {/* {matchingEntry?.split(",").map(Number)?.includes(index) ? <mark>{result.query}</mark> : <span>{result.query}</span>} */}
                            </td>
                            {selectedLinks.map((domain, i) => (
                              <td
                                key={i}
                              // className={`p-3 text-center ${
                              //   result[domain] === min
                              //     ? // && domain !== projectUrl
                              //       // &&  count > 1
                              //       "text-green-500 font-bold"
                              //     : result[domain] === max
                              //     ? // && count > 1
                              //       // && domain !== projectUrl
                              //       "text-red-500 font-bold"
                              //     : ""
                              // }`}
                              >
                                {result[domain] || result[domain] === 0 ? (
                                  <div className="relative">
                                    {result[domain] + 1}
                                    {result.rank_diff !== 0 &&
                                      domain === projectUrl && (
                                        <span
                                          className={`absolute -top-3 text-sm font-bold ${result.rank_diff > 0
                                            ? "text-green-500"
                                            : "text-red-500"
                                            }`}
                                        >
                                          {result.rank_diff > 0
                                            ? `+${result.rank_diff}`
                                            : result.rank_diff}
                                        </span>
                                      )}
                                  </div>
                                ) : (
                                  <span className=" font-bold text-lg">-</span>
                                )}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <PaginationControls
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    currentPage={currentPage}
                    paginate={paginate}
                    totalPages={totalPages}
                    tableDataLength={tableData.length}
                  />
                </div>
                <div
                  className={`  fixed  bottom-0 top-1/2 -translate-y-1/2 
  shadow-md flex items-center justify-center transition-transform duration-500 ease-in-out
    bg-[#F3F4F6] rounded-s border-2 border-[#E5E7EB] border-r-0 h-fit py-3 ${openChart
                      ? "translate-x-0 md:-right-9 right-0 "
                      : "translate-x-full right-0"
                    }`}
                >
                  {/* Sidebar Content */}

                  <PieChart
                    pieData={pieData}
                    minRanks={minRanks}
                    competitor={selectedLinks}
                    result={result}

                  />
                  <button
                    className=" chart pr-2 ps-1 rounded-t bg-[#F3F4F6] border-2 border-[#E5E7EB] border-b-0
              flex items-center justify-center gap-1 absolute -left-[81px] rotate-[270deg] text-[#0B1E15] 	 "
                    onClick={() => {
                      setOpenChart(!openChart);
                      window.addEventListener("localStorageUpdate", handleStorageChange);
                      // localStorage.removeItem("matchingEntry");
                      handleStorageChange()
                    }}
                  >
                    {pieData.labels && (
                      <ReactApexChart
                        options={{
                          chart: {
                            type: "pie",
                            width: 40, // Smaller chart for the button
                          },
                          events: {
                            click: function (event, chartContext, config) {
                              // Prevent the default click behavior
                              event.stopPropagation();
                              event.preventDefault();
                            },
                          },
                          stroke: {
                            width: 0, // Remove the border by setting stroke width to 0
                          },
                          colors: ["#0cc013", "#cece14", "#fc6b03", "#fc0303"],
                          labels: labels,
                          dataLabels: {
                            enabled: false, // Disable data labels to keep it simple
                          },
                          legend: {
                            show: false, // Hide legend for the button
                          },
                          tooltip: {
                            enabled: false, // Disable tooltip
                          },
                          toolbar: {
                            show: false, // Disable toolbar
                          },
                          zoom: {
                            enabled: false, // Disable zooming
                          },
                          states: {
                            hover: {
                              filter: {
                                type: "none", // Disable hover effects on chart elements
                              },
                            },
                            active: {
                              filter: {
                                type: "none", // Disable color change on click
                              },
                            },
                          },
                          plotOptions: {
                            pie: {
                              expandOnClick: false, // Prevent pie slice from expanding on click
                            },
                          },
                        }}
                        series={series}
                        type="pie"
                        width="60"
                      />
                    )}

                    <span className="opacity-70">Stats</span>
                  </button>
                  {/* </>j
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            )}
            {tableData.length === 0 && selectedLinks.length > 0 && (
              <p className="p-5 font-medium">
                You don't have any data on this Day,
                <span className="px-2 text-sm text-gray-600 ">
                  please choose another day
                </span>
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default ProjectCompetitors;
