const CompletedQueries = ({ showQueries, type, completedQueriesList }) => {
  let rank =
    type === "added"
      ? JSON.parse(sessionStorage.getItem("rankAdd"))
      : JSON.parse(sessionStorage.getItem("rank"));
  let newRank = Array.isArray(rank) > 0 ? [...new Set(rank)] : rank;

  if (completedQueriesList) {
    newRank = completedQueriesList
  }
  console.log(rank, newRank, showQueries, Array.isArray(newRank), 'completedQueriesList');
  return (
    <>
      <ul
        className={`${showQueries &&
          "listClick !max-h-60 !overflow-y-scroll border-t border-t-gray-300 mt-2 pt-2 "
          } list  `}
      >

        {showQueries && newRank != null && (
          <>
            {Array.isArray(newRank) ? (
              <>
                {newRank.map((query, index) => {
                  return (
                    <li key={index} className="mb-2">
                      {query} {type} successfully
                    </li>
                  );
                })}
              </>
            ) : (
              newRank + "" + type + "" + "successfully"
            )}
          </>
        )}
      </ul>
    </>
  );
};
export default CompletedQueries;
