import React, { useState, useEffect, useMemo } from "react";

const RankRange = ({
  queries,
  setFilteredData,
  scrollToSection,
  filteredBy,
  setFilteredBy,
}) => {
  const calculateRangeData = useMemo(() => {
    const rangeCounts = {
      "1-3": { count: 0, queries: [] },
      "4-10": { count: 0, queries: [] },
      "11-20": { count: 0, queries: [] },
      "21-50": { count: 0, queries: [] },
      "51+": { count: 0, queries: [] },
    };

    queries.forEach((query) => {
      const rank = query.rank;
      if (rank >= 1 && rank <= 3) {
        rangeCounts["1-3"].count++;
        rangeCounts["1-3"].queries.push(query);
      } else if (rank >= 4 && rank <= 10) {
        rangeCounts["4-10"].count++;
        rangeCounts["4-10"].queries.push(query);
      } else if (rank >= 11 && rank <= 20) {
        rangeCounts["11-20"].count++;
        rangeCounts["11-20"].queries.push(query);
      } else if (rank >= 21 && rank <= 50) {
        rangeCounts["21-50"].count++;
        rangeCounts["21-50"].queries.push(query);
      } else if (rank >= 51) {
        rangeCounts["51+"].count++;
        rangeCounts["51+"].queries.push(query);
      }
    });

    const totalQueries = queries.length;
    const calculatedRangeData = {};
    Object.keys(rangeCounts).forEach((range) => {
      calculatedRangeData[range] = {
        count: rangeCounts[range].count,
        percentage:
          totalQueries > 0
            ? Math.round((rangeCounts[range].count / totalQueries) * 100)
            : 0,
        queries: rangeCounts[range].queries,
      };
    });

    return calculatedRangeData;
  }, [queries]);

  const [rangeData, setRangeData] = useState(calculateRangeData);
  const [selectedData, setSelectedData] = useState();
  const [filtered, setFiltered] = useState(false);

  useEffect(() => {
    setRangeData(calculateRangeData);
  }, [calculateRangeData]);

  const getRangeColorClass = (range) => {
    switch (range) {
      case "1-3":
        return "bg-green-600";
      case "4-10":
        return "bg-green-400";
      case "11-20":
        return "bg-yellow-400";
      case "21-50":
        return "bg-red-400";
      case "51+":
        return "bg-red-600";
      default:
        return "bg-gray-800";
    }
  };

  return (
    <div className="flex flex-col justify-between p-4 bg-white dark:bg-slate rounded h-full ">
      <div className="flex items-center justify-between mb-2">
        <h2 className=" text-lg font-semibold text-gray-700 dark:text-gray-300">
          Rank Ranges
        </h2>
        {/* <button className="text-[12px] text-red-600 font-bold">Reset</button> */}
        {filtered && filteredBy === "ranges" && (
          <button
            onClick={() => {
              setFilteredData(queries);
              setFiltered(false);
              setSelectedData(null);
              setFilteredBy(null);
            }}
            className="text-[12px] font-bold bg-mainColor text-white p-1 rounded"
          >
            Reset
          </button>
        )}
      </div>

      <ul className="mt-[20px] max-w-[85%] m-auto">
        {Object.entries(rangeData).map(([range, data]) => (
          <li
            onClick={() => {
              scrollToSection("table");
              setFilteredData(data.queries);
              setFiltered(true);
              setSelectedData(range);
              setFilteredBy("ranges");
            }}
            key={range}
            className={`${
              range === selectedData && filteredBy === "ranges"
                ? "font-bold"
                : ""
            } grid grid-cols-6 items-center py-2 rounded-lg text-sm text-gray-700 dark:text-gray-300 mb-2 cursor-pointer`}
          >
            <div
              className={` ${
                range === selectedData && filteredBy === "ranges"
                  ? "border-2 border-gray-700"
                  : ""
              } w-3 h-3 inline-block  mr-2 rounded-full col-span-1 text-center ${getRangeColorClass(
                range
              )}`}
            ></div>
            <div className="col-span-2">{range}</div>
            <div className="col-span-2">{data.percentage}%</div>
            <div className="font-bold col-span-1">({data.count})</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RankRange;
