
import { useEffect, useRef, useState } from 'react';

const useWebSocket = (url, setFilteredData) => {
  const socketRef = useRef(null);
  const [queryUpdated, setQueryUpdated] = useState(null);
  const [complate, setComplate] = useState(0);
  const [messages, setMessages] = useState([]);
  const [wsMessage, setWsMessage] = useState(null); // New state for the latest WebSocket message

  useEffect(() => {
    console.log('Initializing WebSocket connection');
    socketRef.current = new WebSocket(url);

    socketRef.current.onopen = () => {
      console.log('WebSocket connected');
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket disconnected');
    };

    socketRef.current.onmessage = (event) => {
      console.log(event, 'onmessage');
      const data = JSON.parse(event.data);
      setWsMessage(data); // Update the latest WebSocket message
      setQueryUpdated(data.query);
      setComplate((prevCount) => prevCount + 1);
      setMessages((prevMessages) => [...prevMessages, data]);

      // Update filteredData if setFilteredData is a function
      if (typeof setFilteredData === 'function') {
        setFilteredData((currentData) => {
          const updatedItemIndex = currentData.findIndex((item) => item.query_id === data.query_id);
          if (updatedItemIndex !== -1) {
            const updatedItem = {
              ...currentData[updatedItemIndex],
              ...data.query,
            };
            return [
              ...currentData.slice(0, updatedItemIndex),
              updatedItem,
              ...currentData.slice(updatedItemIndex + 1),
            ];
          }
          return currentData;
        });
      }
    };

    socketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Cleanup function to close the WebSocket when the component unmounts or URL changes
    return () => {
      if (socketRef.current) {
        console.log('Closing WebSocket connection');
        socketRef.current.close();
      }
    };
  }, [url]);

  const sendMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not open. Cannot send message');
    }
  };

  const sendUpdateQueries = (method, payload) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ method, payload }));
    } else {
      console.warn('WebSocket is not open. Cannot send update queries');
    }
  };

  return { sendMessage, sendUpdateQueries, queryUpdated, complate, messages, wsMessage }; // Return wsMessage
};

export default useWebSocket;
