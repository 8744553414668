import React, { useState, useEffect } from "react";
import DataTableComponent from "../../../../components/keyword_research/keyword/DataTableComponent";
import LinksTable from "../KeywordInfo/LinksTable";
import DataTables from "../../../../components/keyword_research/keyword/DataTables";
import SkeletonDataTable from "../../../../components/Skeleton/SkeletonDataTable";
import SkeletonInput from "../../../../components/Skeleton/SkeletonInput";

const Tables = ({
  
  linksData,
  aggregatedResults,
  aggregatedResultsColumns,
  combinedH2H3DataArray,
  combinedH2H3Columns,
  isKeywordLoading,
  setModalVisible,
  Query,
  selectedrefreshLinks
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredResults, setFilteredResults] = useState(aggregatedResults);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null); // Track sorted column


  const [searchSubHeading, setSearchSubHeading] = useState("");
  const [filteredSubHeadings, setFilteredSubHeadings] = useState(combinedH2H3DataArray);

  useEffect(() => {
    if (searchKeyword) {
      const filtered = aggregatedResults.filter((item) =>
        item.keyword && item.keyword.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setFilteredResults(filtered);
    } else {
      setFilteredResults(aggregatedResults); // Revert to original data if search is cleared
    }
  }, [searchKeyword, aggregatedResults]);

  useEffect(() => {
    if (searchSubHeading) {
      const filtered = combinedH2H3DataArray.filter((item) =>
        item.keyword && item.keyword.toLowerCase().includes(searchSubHeading.toLowerCase())
      );
      setFilteredSubHeadings(filtered);
    } else {
      setFilteredSubHeadings(combinedH2H3DataArray); 
    }
  }, [searchSubHeading, combinedH2H3DataArray]);
  
  
  // This useEffect will log `filteredSubHeadings` whenever it changes
  useEffect(() => {
    console.log(filteredSubHeadings, "filteredSubHeadings");
  }, [filteredSubHeadings]);
  

  const handleSortByCountColumn = () => {
    const sorted = [...filteredResults].sort((a, b) => {
      const countA = a.count ?? 0;
      const countB = b.count ?? 0;
      return sortDirection === "asc" ? countA - countB : countB - countA;
    });

    setFilteredResults(sorted);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const updatedAggregatedResultsColumns = aggregatedResultsColumns.map((col) => {
    if (col.name === "Count") {
      return {
        ...col,
        sortable: true,
        sortFunction: () => handleSortByCountColumn("count"),
      };
    }
    return col;
  });




  return (
    <>
      <div className="flex flex-col h-full mb-6 lg:flex-row">
        <div className="w-full h-full p-4">
          
          {isKeywordLoading &&!selectedrefreshLinks? (
            <SkeletonDataTable />
          ) : (
            <LinksTable 
            linksData={linksData} 
            setModalVisible={setModalVisible} 
            Query={Query}/>
          )}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row">
        <div className="w-full p-4 lg:w-1/2">
          {isKeywordLoading&&!selectedrefreshLinks ? (
            <SkeletonDataTable />
          ) : (
            <>
             
              <div className="flex sm:flex-row flex-col justify-between items-center ">
              <h2 className="text-xl font-semibold mb-2 flex sm:items-center justify-center">
  Keyword Data
</h2>

                <div className="relative w-[70%] ml-4">
                  <input
                    type="text"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    placeholder="Search for a keyword..."
                    className="w-full px-2 py-1 bg-white border-2
                border-[#e7e8ec] rounded-md text-sm focus:outline-none
                transition duration-300 ease-in-out"
                  />
                </div>
              </div>

              {/* DataTables component or message when no matches are found */}
              {filteredResults && filteredResults.length > 0 ? (
                <DataTables
                  columns={updatedAggregatedResultsColumns}
                  data={filteredResults}
                  defaultSortAsc={sortDirection === "asc"}
                  onSort={(column) => handleSortByCountColumn(column.selector)}
                />
              ) : (
                <div className="text-center py-4 text-gray-600">
                  No matching keywords found.
                </div>
              )}
            </>
          )}
        </div>

        <div className="w-full p-4 lg:w-1/2">
          {isKeywordLoading && !selectedrefreshLinks ? (
            <SkeletonDataTable />
          ) : (
            <>
              <div className="flex sm:flex-row flex-col justify-between items-center ">
              <h2 className="text-xl font-semibold mb-2 flex sm:items-center justify-center">
                  Sub Headings
                </h2>
                <div className="relative w-[70%] ml-4">
                  <input
                    type="text"
                    value={searchSubHeading}
                    onChange={(e) => setSearchSubHeading(e.target.value)}
                    placeholder="Search for a heading..."
                    className="w-full px-2 py-1 bg-white border-2 border-[#e7e8ec] rounded-md text-sm focus:outline-none transition duration-300 ease-in-out"
                  />
                </div>
              </div>

              {filteredSubHeadings && filteredSubHeadings.length > 0 ? (
                <DataTableComponent
                  columns={combinedH2H3Columns}
                  data={filteredSubHeadings}
                  defaultSortField="count"
                  defaultSortAsc={sortDirection === "asc"}
                />
              ) : (
                <div className="text-center py-4 text-gray-600">No matching headings found.</div>
              )}
            </>
          )}
        </div>
     
      </div>
    </>
  );
};

export default Tables;
