import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MdOutlineFeedback } from "react-icons/md";
import FeedbackForm from "../pages/FeedBack/FeedBackForm";
import { FiX } from "react-icons/fi";

const FeedbackButton = ({ locationState, onMouseEnter, onMouseLeave ,bottomPosition}) => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const location = useLocation();

  // Check if the path includes "generatedarticle"
  const isGeneratedArticlePath = location.pathname.includes("generatedarticle");

  const toggleFeedback = () => {
    setIsFeedbackOpen((prev) => !prev);

  };

  useEffect(() => {
    setIsFeedbackOpen(false);
  }, [location.pathname]);

  return (
    < >
      <button
        className={`fixed  ${bottomPosition?bottomPosition:'bottom-4'}
                    bg-mainColor  hover:bg-white text-white right-6
                    hover:text-mainColor font-bold rounded-full shadow-lg 
                    focus:outline-none focus:shadow-outline transform 
                    transition-transform duration-300 ease-in-out hover:scale-110 
                    z-[10000] w-12 h-12 flex items-center justify-center pt-[2px]`}
        // onMouseLeave={onMouseLeave}
        // onMouseEnte={onMouseEnter}
        onClick={toggleFeedback}
      >
        {isFeedbackOpen ? <FiX size={22} /> : <MdOutlineFeedback size={22} />}
      </button>

      {/* Feedback Sidebar */}
      {isFeedbackOpen && (
        <div
          className={`fixed bottom-[10%] right-0    w-full md:w-[25%]
       
          z-[1000]  overflow-auto`}
        >

          <FeedbackForm />
        </div>
      )}
    </>
  );
};

export default FeedbackButton;
