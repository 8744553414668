import React, { useCallback, useEffect, useState } from "react";
import InputForm from "../keyword/InputForm";
import CompanyTabs from "../../keywordresearch/components/url/CompanyTabs";
import DataTableComponent from "../keyword/DataTableComponent";
import SkeletonMainKeywordInfo from "../../Skeleton/SkeletonMainKeywordInfo";
import SkeletonDataTable from "../../Skeleton/SkeletonDataTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { FaEye, FaSpinner, FaTrash, FaRegFileWord, FaInfoCircle } from "react-icons/fa"; // Import the Word icon
import { toast } from "react-toastify";
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import StepForm from "../keyword/StepForm";
import Title from "../../../pages/SidebarPages/MainKeyword/keyword/Layout/Title";
import MainKeywordInfo from "../../../pages/SidebarPages/MainKeyword/keyword/KeywordInfo/MainKeywordInfo";
const InitialDataDisplay = ({
  handleSubmit,
  isKeywordLoading,
  isUrlLoading, // Loading flag
  initialData,
  h2Columns,
  h3Columns,

}) => {
  // const url_title = initialData[0].title;
  const navigate = useNavigate();
  const userId = useSelector((state) => state?.authSlice?.user.id);
  const token = useSelector((state) => state.authSlice?.user.token);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const location = useLocation(); // Get current location
  const [isGenerating, setIsGenerating] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Track item to delete
  const [quota, setQuota] = useState()
  const [advancedOption, setAdvancedOption] = useState(false)
  const [isModal_Open, setIsModal_Open] = useState(false);
  const [newHeadings, setNewHeadings] = useState([]);
  const [oldHeadings, setOldHeadings] = useState([]);
  const [resultSample, setResultSample] = useState(null);
  const [minCount, setMinCount] = useState(0); // Initialize minCount state

  const [maxCount, setMaxCount] = useState(1000); // Initialize maxCount state

  const [art_id, set_art_id] = useState(); // Initialize maxCount state
  const [ut_id, set_ut_id] = useState(); // Initialize maxCount state

  
  const fetchQouta = useCallback(async () => {

    await axios.get(`${process.env.REACT_APP_API_URL}/api/UserProfileQuote/${userId}/`).then((res) => {
      setQuota(res.data)

    })


  }, []);
  useEffect(() => {
    fetchQouta()

  }, [fetchQouta])
  console.log(isModal_Open, "isModal_Open");
  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setHistoryData(response.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchHistoryData(); // Call the fetch function on component mount and when navigating back
  }, [location, token]);
  const handleHistory = async () => {
    try {
      setIsLoadingHistory(true);
      setIsHistoryModalOpen(true); // Show modal
      // Fetch data whenever the modal is opened
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Filter history data where kwd_flag is false
      const filteredHistoryData = response.data.filter(
        (item) => item.kwd_flag === false
      );
      console.log(filteredHistoryData, "history");
      setHistoryData(filteredHistoryData);
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      setIsLoadingHistory(false);

    }

  };
  const handleGenerateArticle = async () => {
    if (initialData && initialData.length > 0) {

      const { object_id: ut, title: url_title } = initialData[0];

      if (userId && ut) {
        try {
          setIsGenerating(true);
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
            { ut_id: ut, title: url_title, kwd_flag: false },
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response, response.data, "responseresponse");
          // const { Generated_article, headings, word_count } = response.data;

          if (response.data && !Array.isArray(response.data)) {
            const {
              new_headings,
              old_headings,
              result_sample,
              // min_count,
              // max_count,
              art_id,
              word_count
            } = response.data;
            const min_count = Math.max(Math.round(word_count * 0.7), 0); // 70% of the word count
            const max_count = Math.round(word_count * 1.3); // 130% of the word count
  
            setNewHeadings(new_headings || []);
            setOldHeadings(old_headings || []);

            setMinCount(min_count || 0);
            setMaxCount(max_count || 1000);
            setResultSample(result_sample);
            set_art_id(art_id);
            console.log(advancedOption, "advancedOption");
            set_ut_id(ut_id)
            if (advancedOption) {
              setIsModal_Open(true);

            } else {
              const payload = {
                article_id: art_id,
                word_count: 1000,
                new_headings: new_headings ? new_headings : undefined,
              };
              navigate(`/generatedarticle/${userId}/${response.data.art_id}/`, {
                state: {
                  payload
                },
              });

            }

          }


        } catch (error) {
          console.error("Error generating article:", error);
        } finally {
          setIsGenerating(false);
          setAdvancedOption(false)
        }
      }
    }
  };






  const closeModal = () => {
    setIsHistoryModalOpen(false);
  };
  const handleViewDetails = async (item) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${item.art_id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { Generated_article, new_headings, user, word_count, title } = response.data;
      console.log(word_count, "word_count")

      if (Generated_article && new_headings && word_count && user) {
        console.log("Hello");
        navigate(`/generatedarticle/${user}/${item.art_id}/`, {
          state: {
            content: Generated_article,
            headings: new_headings,
            url_title: title || item.title,
            fromHistory: true, // Pass the fromHistory flag here
            word_count
          },
        });
      }
    } catch (error) {
      console.error("Error fetching article details:", error);
    }
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setIsConfirmationModalOpen(true); // Open confirmation modal
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${itemToDelete.object_id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setHistoryData((prevData) =>
          prevData.filter((item) => item.object_id !== itemToDelete.object_id)
        );
        toast.success("Article deleted successfully");
      } catch (error) {
        console.error("Error deleting article:", error);
      } finally {
        setIsConfirmationModalOpen(false); // Close confirmation modal
        setItemToDelete(null); // Clear the item to delete
      }
    }
  };

  const close_Modal = () => {
    setIsModal_Open(false); // Set the state to false to close the modal
    setAdvancedOption(false)

  };
  const handleExport = (item) => {
    const content = item.Generated_article;
    const title = item.title;

    if (content) {
      const converted = htmlDocx.asBlob(content);
      saveAs(converted, `${title}.docx`);
      toast.success("Article exported as Word");
    } else {
      toast.error("No content available to export.");
    }
  };
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait, your article is being generated. Do not refresh or close this page."
  );
  useEffect(() => {
    if (isGenerating) {
      const firstTimeout = setTimeout(() => {
        setLoadingMessage("Still loading... Please hold on for a moment.");
      }, 30000); // 30 seconds
      const secondTimeout = setTimeout(() => {
        setLoadingMessage(
          "Please be patient, the process is taking a bit longer than expected."
        );
      }, 60000); // 60 seconds (30 more seconds from the previous message)
      return () => {
        clearTimeout(firstTimeout);
        clearTimeout(secondTimeout);
      };
    }
  }, [isGenerating]);
  //url 
  const adjustedMinCount = Math.round(minCount * 0.7); // Decrease by 30%
const adjustedMaxCount = Math.round(maxCount * 1.3); // Increase by 30%
console.log( minCount,maxCount,adjustedMinCount,adjustedMaxCount,"adjustedMaxCount")
  return (
    <>
      {isGenerating && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1003] backdrop-blur-sm">
          <div className="flex flex-col items-center gap-4">
            <FaSpinner className="animate-spin text-white text-5xl" />
            <p className="text-white text-lg">{loadingMessage}</p>
          </div>
        </div>
      )}

      <ConfirmationModal
        show={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleConfirmDelete}
        title={itemToDelete?.title}
      />
      
      <StepForm
        isOpen={isModal_Open}
        onClose={close_Modal}
        newHeadings={newHeadings}
        oldHeadings={oldHeadings}
        resultSample={resultSample}
        minCount={adjustedMinCount} 
        maxCount={adjustedMaxCount} 
        art_id={art_id}
        ut_id={ut_id}
      />

      <div className="flex justify-end gap-4 mb-5">
        <div className={`flex flex-col items-center  justify-center`}>
          <div className=" flex space-x-7 items-center ps-4">

            <div className="flex items-center justify-between mt-4 p-4 space-x-4  w-full max-w-xl mx-auto">
              {/* Quota Display */}
              <p
                className={`text-sm font-medium ${quota?.daily_quota_AI === 0 ? "text-red-800" : "text-mainColor"
                  }`}
              >
                <span>
                  {quota?.daily_quota_AI}/
                  {quota?.billing_type === 1
                    ? "1000"
                    : quota?.billing_type === 2
                      ? "500"
                      : quota?.billing_type === 3
                        ? "250"
                        : quota?.billing_type === 10
                          ? "20000"
                          : ""}{" "}
                </span>
                <span className="block text-mainColor">Remaining Keywords</span>
              </p>

              {/* Button with Toggle */}
              <div
                className="flex items-center gap-4 bg-gradient-to-r from-sky-500 to-sky-300 text-white px-3 py-3 rounded-full shadow-md transform transition-transform duration-200 hover:scale-105 cursor-pointer"
              >
                             {isGenerating ? (
                 <div className="flex items-center justify-center gap-2">
                   <FaSpinner className="animate-spin text-lg" /> {/* Spinner */}
                   <span>Generating...</span>
                 </div>
               ) : (
                 <div className="flex items-center justify-center gap-2">
                   <span                     onClick={handleGenerateArticle}
                   >Generate Article</span>
                 </div>
               )}

                {/* Toggle Switch */}
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={advancedOption}
                    onChange={() => setAdvancedOption(!advancedOption)}
                    className="sr-only"
                  />
              
                  <div
                    className={`w-10 h-5 rounded-full relative shadow-inner transition-colors duration-300 ${advancedOption ? "bg-gray-100" : "bg-blue-200"
                      }`}
                  >
                    <div
                      className={`w-4 h-4 bg-gradient-to-r from rounded-full absolute top-[2px] left-[2px] transition-transform ${advancedOption ? "translate-x-5 bg-white" : "bg-gradient-to-r from-sky-500 to-sky-300"
                        }`}
                    ></div>
                  </div>
                </label>
                <span onClick={() => setAdvancedOption(!advancedOption)}
                >Pro</span>

              </div>
              <button
                onClick={handleHistory}
                className="flex 
            items-center gap-2 bg-gradient-to-r from-gray-500 to-gray-300
               text-white px-4 py-2 rounded-full shadow-lg
               transform transition duration-200 ease-in-out hover:scale-105 h-fit"
              >
                Show History
              </button>
            </div>



          </div>









        </div>




      </div>
      {isHistoryModalOpen && (
        <div
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              closeModal();
            }
          }}
          className="  pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
          bg-greeng 
          bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
        >
          <div className="bg-white rounded-lg p-6 w-[50%] max-h-[60vh] overflow-auto shadow-lg border border-gray-300">
            <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-300">
              Article History URL
            </h2>
            {isLoadingHistory ? (
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin text-2xl" />
              </div>
            ) : historyData.length > 0 ? (
              <ul className="space-y-4">
                {historyData
                  .sort(
                    (a, b) =>
                      Date.parse(b.created_at) - Date.parse(a.created_at)
                  ) // Ensuring proper date parsing
                  .filter((item) => item.kwd_flag === false) // Filter to show only items with kwd_flag === false
                  .map((item) => (
                    <li
                      key={item.art_id}
                      className="border-b pb-2 cursor-pointer flex justify-between items-center"
                    >
                      <div>
                        <h3
                          onClick={() => handleViewDetails(item)}
                          className="font-semibold"
                        >
                          {item.title}
                        </h3>
                        <p className="text-sm text-gray-500">
                          Created At:{" "}
                          {new Date(item.created_at).toLocaleString()}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleViewDetails(item)}
                          className="text-mainColor  px-2 py-2 rounded-md  transition duration-200"
                          title="View Details"
                        >
                          <FaEye />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(item)} // Use new delete handler
                          className="text-red-500  px-2 py-2 rounded-md  transition duration-200"
                          title="Delete Article"
                        >
                          <FaTrash />
                        </button>
                        {/* Export Button */}
                        <button
                          onClick={() => handleExport(item)}
                          className="text-blue-500 px-2 py-2 rounded-md transition duration-200"
                          title="Export Article as Word"
                        >
                          <FaRegFileWord />
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No history found.</p>
            )}
            <button
              onClick={closeModal}
              className="flex mt-3 ml-auto px-4 py-2 font-bold text-black
               bg-[#D1D5DB] rounded "
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="items-center w-full grid grid-cols-1
       lg:grid-cols-5 md:gap-6 gap-3">
        <div className="col-span-3">
          <Title title="Main Keyword Research" />
          <InputForm
            onSubmit={handleSubmit}
            isLoading={isKeywordLoading || isUrlLoading}
          />
        </div>
        {isUrlLoading
          ? Array.from({ length: 1 }).map((_, index) => (
            <SkeletonMainKeywordInfo key={index} />
          ))
          : initialData &&
          initialData.map((item) => (
            <MainKeywordInfo
              key={item.object_id}
              url={true}
              keyword={item.title}
              wordCount={Number(item.word_count).toFixed()}
              optimizationScore={75}
            />
          ))}
      </div>
      <div className="items-center w-full md:p-5">
        <div className="flex flex-col space-y-8">
          {isUrlLoading ? (
            <SkeletonDataTable />
          ) : (
            <CompanyTabs initialData={initialData} />
          )}
          {isUrlLoading
            ? Array.from({ length: 1 }).map((_, index) => (
              <div
                key={index}
                className="lg:grid lg:grid-cols-2 mt-8 gap-6 md:justify-between max-w-screen-2xl"
              >
                <SkeletonDataTable />
                <SkeletonDataTable />
              </div>
            ))
            : initialData &&
            initialData.map((item) => {
              let h2Data = [];
              let h3Data = [];
              try {
                h2Data = Object.entries(
                  JSON.parse(
                    item.h2_count.replace(/\\/g, "").replace(/'/g, '"')
                  )
                ).map(([keyword, count]) => ({ keyword, count }));
              } catch (error) {
                console.error("Error parsing h2_count:", error);
              }
              try {
                h3Data = Object.entries(
                  JSON.parse(
                    item.h3_count.replace(/\\/g, "").replace(/'/g, '"')
                  )
                ).map(([keyword, count]) => ({ keyword, count }));
              } catch (error) {
                console.error("Error parsing h3_count:", error);
              }
              return (
                <div
                  key={item.object_id}
                  className="lg:grid lg:grid-cols-2 mt-8 gap-6 md:justify-between max-w-screen-2xl"
                >
                  <DataTableComponent
                    title="H2 Keywords"
                    columns={h2Columns}
                    data={h2Data}
                  />
                  <DataTableComponent
                    title="H3 Keywords"
                    columns={h3Columns}
                    data={h3Data}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default InitialDataDisplay;
