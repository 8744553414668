import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ScoreCard = ({ score }) => (
    <div className="flex items-center space-x-4 flex-cols 2xl:ml-[-10px]">
        <h3 className='flex font-semibold'>
           Score
        </h3>
        <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar
                value={score}
                text={`40%`}
                className='dark:text-gray-300'
                styles={buildStyles({
                    textColor: "#2BA950",
                    pathColor: "#4caf50",
                    trailColor: "#d6d6d6",
                    textSize: '30px',
                    pathTransitionDuration: 0.5,
                })}
            />
        </div>
    </div>
);

export default ScoreCard;
