import React, { useState, useEffect, memo, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sidebarLinks as data } from "../static";
import { AiOutlineMenu, AiOutlineQuestionCircle } from "react-icons/ai";
import axios from "axios";
import logo from "../assets/logo/Ranktera.png";
import { useDispatch, useSelector } from "react-redux";
import { sortCards } from "../utils/utils";
import { FaEllipsisV, FaStar } from "react-icons/fa";
import { Menu, Transition } from "@headlessui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { BiSolidUserPlus } from "react-icons/bi";
import { VscUnverified } from "react-icons/vsc";
import { selectAuthDetails } from "../redux/selectors";
import { logOut } from "../redux/lib/auth";
import { toast } from "react-toastify";
import InviteUserModal from "../components/Modal/InviteUserModal";
import ConfirmationDialogg from "../components/Modal/ConfirmationDialogg";
const roleOptions = [
  { value: "2", label: "User" },
  { value: "3", label: "Guest" },
];
function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
const Sidebar = ({
  toggle,
  show,
  openedProjectName,
  activeProject,
  setTopThreeProjects,
  setActiveProject,
  topThreeProjects,
  pin_project,
  setPin_project,
  path_Segments,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, NameUser, token, verified } = useSelector(selectAuthDetails);
  const [links, setLinks] = useState([...data]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [invitationLoading, setInvitationLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [invitationError, setInvitationError] = useState("");
  const [invitationDetails, setInvitationDetails] = useState({
    mail: "",
    role_id: "2",
  });
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [openSignConfirm, setOpenSignConfirm] = useState(false);

  const togglePinProject = async (projectId, pinnedByUser = false) => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/toggle_project_flag/${userId}/${projectId}/`,
      { pinnedByUser: pinnedByUser },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setPin_project(!pin_project);
  };

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Displayprojects/${userId}/`
      );

      let fetchedProjects = response.data.projects.map((project) => ({
        ...project,
        isPinned: project.flag,
      }));

      // Sort projects by rank_count (highest to lowest)
      fetchedProjects.sort((a, b) => b.rank_count - a.rank_count);

      // Extract the top three projects based on rank_count
      let top3Projects =
        fetchedProjects.length > 3
          ? fetchedProjects.slice(0, 3)
          : fetchedProjects;

      // Filter out pinned projects that are already in the top three
      let pinnedProjects = fetchedProjects.filter(
        (project) => project.isPinned
      );

      if (pinnedProjects.length === 0) {
        // Pin all top three projects if none are pinned
        // top3Projects.forEach((project) => {
        //   togglePinProject(project.project_id, false);
        // });
        for (let project of top3Projects) {
          await togglePinProject(project.project_id, false);
        }
      } else if (pinnedProjects.length < 3) {
        // Pin any unpinned project in the top three if there are less than 3 pinned
        let toPinProject = top3Projects.find((project) => !project.isPinned);
        if (toPinProject) {
          togglePinProject(toPinProject.project_id, false);
        }
      } else if (pinnedProjects.length === 3) {
        // If there are already 3 pinned projects, unpin the lowest rank_count one and pin a new one
        let lowestPinnedProject = pinnedProjects[pinnedProjects.length - 1];
        let lowest2PinnedProject = pinnedProjects[pinnedProjects.length - 2];
        let lowest3PinnedProject = pinnedProjects[pinnedProjects.length - 3];

        let toPinProject = top3Projects.filter((project) => !project.isPinned);
        let notPinnedByUser = topThreeProjects.filter(
          (project) => !project.pinnedByUser && !project.isPinned
        );

        function arraysAreSame(arr1, arr2) {
          // Check if arrays have the same length
          if (arr1.length !== arr2.length) {
            return false;
          }

          // Sort both arrays to ensure order doesn't matter
          const sortedArr1 = arr1.slice().sort();
          const sortedArr2 = arr2.slice().sort();

          // Compare each element in the arrays
          for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
              return false;
            }
          }

          return true;
        }

        if (
          notPinnedByUser.length === 0 &&
          !arraysAreSame(top3Projects, pinnedProjects)
        ) {
          if (
            lowestPinnedProject.rank_count < toPinProject[0].rank_count &&
            !lowestPinnedProject.pinnedByUser
          ) {
            // Automatically pin the new project after unpinning
            await togglePinProject(lowestPinnedProject.project_id, false);
            await togglePinProject(toPinProject[0].project_id, false);
          } else if (
            !lowest2PinnedProject.pinnedByUser &&
            lowest2PinnedProject.isPinned &&
            lowest2PinnedProject.rank_count < toPinProject[0].rank_count
          ) {
            await togglePinProject(lowest2PinnedProject.project_id, false);
            await togglePinProject(toPinProject[0].project_id, false);
          } else if (
            !lowest3PinnedProject.pinnedByUser &&
            lowest3PinnedProject.isPinned &&
            lowest3PinnedProject.rank_count < toPinProject[0].rank_count
          ) {
            await togglePinProject(lowest3PinnedProject.project_id, false);
            await togglePinProject(toPinProject[0].project_id, false);
          }
        }
      }

      // Set the pinned projects as the top three projects

      setTopThreeProjects(pinnedProjects);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenProject = (path, projectDetails, project_id) => {
    navigate(path, {
      state: { projectDetails },
    });
    setActiveProject(project_id);
  };
  const OpenSignConfirm = () => {
    setOpenSignConfirm(true);
  };
  const handleLogOut = () => {
    dispatch(logOut());
    navigate("/login");
  };
  const sendInvitation = async () => {
    setInvitationLoading(true);
    setInvitationError("");
    try {
      const payload = {
        mail: invitationDetails.mail,
        role_id: invitationDetails.role_id,
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/invite/${userId}/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      toast.success("Invitation sent successfully");
      setInvitationDetails({ mail: "", role_id: "" });
      setShowInviteUser(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mail &&
        error.response.data.mail.includes(
          "invitation with this mail already exists."
        )
      ) {
        toast.info(error.response.data.mail);
        setShowInviteUser(false);
      } else {
        setInvitationError("Failed to send invitation");
        toast.error("Failed to send invitation");
      }
    } finally {
      setInvitationLoading(false);
    }
  };
  const handleInviteInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "mail") {
      const isValid = validateEmail(value);
      setIsEmailValid(isValid);
    }

    setInvitationDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleRoleChange = (selectedOption) => {
    setInvitationDetails((prevState) => ({
      ...prevState,
      role_id: selectedOption.value,
    }));
  };
  const reSendVerified = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/verify_button/${userId}/`
      );
      toast.success(response.data.message);
    } catch (error) {
      toast.error("An error occurred during the email verification process.");
    }
  };

  useEffect(() => {
    const insertProjectNameAfterProjects = () => {
      const updatedLinks = [...data];
      const projectsIndex = updatedLinks.findIndex(
        (link) => link.name === "Projects"
      );
      if (projectsIndex !== -1 && openedProjectName) {
        updatedLinks.splice(projectsIndex + 1, 0, {
          id: "currentProject",
          name: ` ${openedProjectName}`,
          path: "#",
          custom: true,
        });
      }
      setLinks(updatedLinks);
    };

    insertProjectNameAfterProjects();
  }, [openedProjectName]);
  useEffect(() => {
    if (location.pathname === "/projects") {
      setActiveProject(null);
    } else if (location.pathname.split("/")[1] === "projects") {
      setActiveProject(Number(location.pathname.split("/")[2]));
    }
  }, [location.pathname]);
  useEffect(() => {
    fetchProjects();
  }, [pin_project]);
  useEffect(() => {
    if (!location.pathname.startsWith("/projects/")) {
      setActiveProject(null);
    }
    // fetchProjects();
  }, [location.pathname]);

  useEffect(() => {
    // Assuming location.pathname is set and pathSegments is split from location.pathname
    const path_Segments = location.pathname.split("/").filter((segment) => segment);

    // Check if the second segment in the path is a valid projectId
    if (!isNaN(path_Segments[0])) {
      const projectIdFromPath = Number(path_Segments[0]); // Convert path segment to a number

      // Find the project in topThreeProjects with the matching project ID
      const matchingProject = topThreeProjects.find(
        (project) => project.project_id === projectIdFromPath
      );

      // If a match is found, set it as the active project
      if (matchingProject) {
        setActiveProject(matchingProject.project_id);
        console.log(`Active project set to: ${matchingProject.project_id}`);
      }
    }
  }, [location.pathname, topThreeProjects]);


  return (
    <div
      className={`h-full z-[1002] fixed bg-greeng dark:bg-slate transition duration-150 
        ease-in-out ${show ? "md:w-[15%] w-full fixed top-0 left-0" : "w-0"}`}
    >
      <div className={`relative flex items-center gap-7 p-5`}>
        <button onClick={toggle} className="text-mainColor ">
          <AiOutlineMenu size={24} />
        </button>
        <Link to="/projects" className=" ">
          <img src={logo} alt="logo" className="md:w-36 w-20 mx-auto" />
        </Link>
      </div>
      <div
        className={`flex flex-col gap-1 p-2 md:pt-4 transition-opacity duration-300 ease-in-out ${show ? "opacity-100" : "opacity-0"
          }`}
      >
        {show &&
          links.map(({ id, name: title, path, custom }) => (
            <React.Fragment key={id}>
              {custom && path === "/projects" ? (
                <>
                  <Link
                    className={`${location.pathname === path
                      ? "border-r-4 border-mainColor font-bold text-white"
                      : "text-white"
                      } hover:border-r-2 border-mainColor hover:shadow-md cursor-pointer
                       hover:text-white py-2 flex items-center transition-transform transform ${show ? "translate-x-0" : "translate-x-full"
                      }`}
                    to={path}
                  >
                    <span className="ml-2 title text-2xl font-bold">
                      {title}
                    </span>
                  </Link>
                  {topThreeProjects.map((project) => (
                    <div
                      key={project.project_id}
                      className={`flex items-center space-x-3 ml-[20px] my-1 text-lg cursor-pointer 
      ${activeProject === project.project_id
                          ? "border-r-2 h-6 border-mainColor font-bold text-white duration-300 ease-in-out transform scale-105"
                          : "text-gray-300 hover:text-white hover:transform hover:scale-105 transition duration-300 ease-in-out"
                        }`}
                      onClick={() => {
                        handleOpenProject(
                          `/projects/${project.project_id}/`,
                          project,
                          project.project_id
                        );
                      }}
                    >
                      <FaStar className="flex items-center justify-center text-[#27f34f] w-4 transition-transform duration-300 ease-in-out transform hover:scale-125" />
                      <p className="transition-transform duration-300 ease-in-out transform hover:translate-x-1">
                        {project.name}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <Link
                  className={`${location.pathname === path
                    ? "border-r-2 border-blue font-bold text-white"
                    : "text-white"
                    } hover:border-r-2 border-mainColor hover:shadow-md cursor-pointer
                     hover:text-white py-2 flex items-center transition-transform
                      transform ${show ? "translate-x-0" : "translate-x-full"}`}
                  to={path}
                >
                  <div className="ml-2 title text-xl font-bold">{title}</div>
                </Link>
              )}
            </React.Fragment>
          ))}
      </div>
      <div
        className={`absolute text-white ${show ? "" : "hidden"}  ${verified ? "bottom-0" : "bottom-9"
          }    w-full border-t border-t-gray-500 bg-greeng `}
      >
        <Menu as="div" className="relative  text-end w-full bg-greeng ">
          <Menu.Button
            as="button"
            className="relative z-20 grid md:grid-cols-5 grid-cols-3 md:w-full w-1/3 gap-4 items-center  capitalize px-4 py-3 bg-greeng"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="bg-gray-300 text-greeng flex items-center justify-center  w-9 h-9 rounded-full xl:col-span-1 md:col-span-2">
              {NameUser.slice(0, 1).toUpperCase()}
            </div>
            <p className="xl:col-span-3 md:col-span-2 text-start">{NameUser}</p>
            {isOpen ? (
              <MdKeyboardArrowUp size={24} />
            ) : (
              <MdKeyboardArrowDown size={24} />
            )}
          </Menu.Button>
          <Transition
            className="w-full"
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="transform translate-y-full opacity-0"
            enterTo="transform translate-y-0 opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="transform translate-y-0 opacity-100"
            leaveTo="transform translate-y-full opacity-0"
          >
            <Menu.Items
              className={`absolute w-full bottom-0 pb-16 z-10 mt-2 
                origin-bottom bg-greeng focus:outline-none text-gray-100 font-semibold border-t`}
            >
              <div className="p-1">
                {/*  border-b border-b-gray-500 */}
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`flex items-center gap-1 w-full px-2 py-2 text-sm   border-b border-b-gray-500 `}
                      onClick={() => {
                        OpenSignConfirm();
                      }}
                    >
                      <FiLogOut size={18} className="" /> Sign out
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => navigate('/help')}
                      className={`flex items-center gap-1 w-full px-2 py-2 text-sm  rounded-md  `}
                    >
                      <AiOutlineQuestionCircle size={18} className="" /> FAQs
                    </button>
                  )}
                </Menu.Item>
                {verified ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`flex items-center gap-1 w-full px-2 py-2 text-sm  rounded-md `}
                        onClick={() => {
                          setShowInviteUser(true);
                        }}
                      >
                        <BiSolidUserPlus size={18} /> Invite user
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`flex items-center gap-1 w-full px-2 py-2 text-sm  rounded-md  `}
                        onClick={() => {
                          reSendVerified();
                        }}
                      >
                        <VscUnverified size={18} className="" /> Verify account
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <ConfirmationDialogg
          btnText={"Sign out"}
          message={"Are you sure you want to sign out?"}
          isOpen={openSignConfirm}
          onConfirm={handleLogOut}
          onClose={() => {
            setOpenSignConfirm(false);
          }}
        />
        <InviteUserModal
          showInviteUser={showInviteUser}
          setShowInviteUser={setShowInviteUser}
          invitationDetails={invitationDetails}
          setInvitationDetails={setInvitationDetails}
          handleInviteInputChange={handleInviteInputChange}
          handleRoleChange={handleRoleChange}
          roleOptions={roleOptions}
          sendInvitation={sendInvitation}
          isEmailValid={isEmailValid}
          invitationError={invitationError}
          invitationLoading={invitationLoading}
        />
      </div>
    </div>
  );
};

export default memo(Sidebar);
