import React, { useEffect, useState } from "react";
import RankRangeComponent from "./RankRangeComponent";
import ChartComponent from "./ChartComponent";
import ActivityPieComponent from "./ActivityPieComponent";
import ProjectDetailsComponent from "./ProjectDetailsComponent";
import { useLocation } from "react-router-dom";

const ProjectHeader = ({
  projectDetails,
  seriesData,
  filteredData,
  openDateFilter,
  startDate,
  endDate,
  customDateChart,
  setCustomDateChart,
  setFilteredData,
  tableData,
  filteredByRank,
  setFilteredByRank,
  setFilteredBy,
  filteredBy
}) => {
  // const [filteredBy, setFilteredBy] = useState(null);
  const location = useLocation()
  // const firstDate = customDateChart
  //   ? startDate.getTime()
  //   : new Date(new Date().setDate(new Date().getDate() - 5)).getTime();
  // const lastDate = customDateChart ? endDate.getTime() : new Date().getTime();
  const firstDate = customDateChart
    ? startDate.getTime()
    : new Date(Date.now() - 5 * 24 * 60 * 60 * 1000).getTime(); // Explicitly subtract 5 days
  const lastDate = customDateChart ? endDate.getTime() : Date.now();
  console.log(new Date(firstDate).toISOString(), new Date(lastDate).toISOString(), "seriesData");
  const normalizedData = seriesData?.[0]?.data?.map((point) => ({
    x: new Date(point.x), // Convert to UTC
    y: point.y,
  }));


  const yValues = seriesData?.[0]?.data?.map((point) => parseFloat(point.y)) || [];
  const minY = yValues.length > 0 ? Math.min(...yValues) : 0; 
  const maxY = yValues.length > 0 ? Math.max(...yValues) : 10;
  
console.log(minY,maxY,'okok')
  
  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
        // type: "x", // Allow zooming on the x-axis only
      },
      // toolbar: {
      //   show: true, // Show the toolbar to enable zoom controls
      //   tools: {
      //     zoom: true,
      //     zoomin: true,
      //     zoomout: true,
      //     reset: true, // Add a reset button to zoom out
      //     pan: false, // Disable panning
      //     selection: false, // Disable selection zoom
      //     zoomselection: false, // Explicitly disable selection zoom
      //   },
      // },
    },
    colors: ["#2BA950"],
    xaxis: {
      type: "datetime",
      labels: {
        format: "dd MMM",

      },
      min: firstDate,
      max: lastDate,
    },
    yaxis: {
      reversed: true,
      min: Math.floor(minY), // Dynamically set minimum y-axis value
      max: Math.ceil(maxY), // Dynamically set maximum y-axis value
      tickAmount: Math.ceil(maxY - minY), // Ensure integer ticks
      labels: {
        formatter: function (value) {
          return Math.round(value); // Ensure only integers are shown
        },
      },
      title: {
        text: "Average Rank",
      },
    },
  
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 5,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
        formatter: function (val) {

          // Format date explicitly in UTC
          const date = new Date(val);
          console.log(val, date, 'datedate');
          return date.toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
          });
        },
      },
      y: {
        formatter: function (value) {

          return `Rank: ${value}`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return value;
      },
    },
  };
  // Function to handle scrolling
  const scrollToSection = (targetId, duration = 1200) => {
    const target = document.getElementById(targetId);
    if (!target) return;

    const startPosition = window.pageYOffset;
    const targetPosition = target.getBoundingClientRect().top;
    const startTime = performance.now();

    const easeInOutQuad = (t) =>
      t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

    const animateScroll = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Progress from 0 to 1

      const ease = easeInOutQuad(progress);
      window.scrollTo(0, startPosition + targetPosition * ease);

      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };
  useEffect(() => {
    setFilteredBy(null)
  }, [location.pathname])
  return (
    <div className="mt-1 p-4">
      <div
        className="w-full m-auto  animate-fade-in-down items-stretch grid
     xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3"
      >
        <RankRangeComponent
          filteredBy={filteredBy}
          setFilteredBy={setFilteredBy}
          setFilteredData={setFilteredByRank}
          tableData={filteredData}
          scrollToSection={scrollToSection}
        />
        <ProjectDetailsComponent projectDetails={projectDetails} />
        <ActivityPieComponent
          filteredBy={filteredBy}
          setFilteredBy={setFilteredBy}
          tableData={filteredData}
          setFilteredData={setFilteredByRank}
          scrollToSection={scrollToSection}
        />
        <ChartComponent
          chartOptions={chartOptions}
          seriesData={seriesData}
          openDateFilter={openDateFilter}
          setCustomDateChart={setCustomDateChart}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default ProjectHeader;
