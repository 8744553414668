import React, { useState, useEffect } from "react";
import { FaTrashAlt, FaSync } from "react-icons/fa";
import useKeywordAndUrlProcessing from "../../../hook/useKeywordAndUrlProcessingTest";
import { useDispatch, useSelector } from "react-redux";
import { ImSpinner11 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import MainHeader from "./Layout/MainHeader";
import MainContent from "./Layout/MainContent";
import { toast } from "react-toastify";
import Confirmation from "../../../components/Modal/Confirmation";
import { process_Keyword } from "../../../redux/lib/keywordSlice";
import "react-datepicker/dist/react-datepicker.css";
import Title from "./Layout/Title";
import InputForm from "../../../components/keyword_research/keyword/InputForm";
import SkeletonTitle from "../../../components/Skeleton/SkeletonTitle";
import SkeletonInputForm from "../../../components/Skeleton/SkeletonInputForm";
// import { decrementDailyQuota } from "../../../redux/lib/auth";

const MainKeywordResearchTest = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.authSlice.id);
  const navigate = useNavigate();
  const { handleSubmit, isKeywordLoading, isUrlLoading, keywordData, urlData } =
    useKeywordAndUrlProcessing(dispatch, userId);
  const [loadingRows, setLoadingRows] = useState({});
  const [data, setData] = useState([]);
  const [isKeywordDataLoading, setIsKeywordDataLoading] = useState(false);
  const [isUrlDataLoading, setIsUrlDataLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [url_Data, setUrl_Data] = useState([]);
  const [isUrlConfirmationOpen, setIsUrlConfirmationOpen] = useState(false);
  const [urlRowToDelete, setUrlRowToDelete] = useState(null);

  useEffect(() => {
    fetchData();
    fetchUrlData();
  }, []);

  useEffect(() => {
    filterData();
  }, [startDate, endDate, searchTerm, data]);

  const sanitizeHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    doc.querySelectorAll("img, svg, icon, figure").forEach((el) => el.remove());
    return doc.body.innerHTML;
  };

  const createMarkup = (html) => {
    return { __html: sanitizeHtml(html) };
  };

  const fetchData = async () => {
    setIsKeywordDataLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/user-data/${userId}/`
      );
      const result = await response.json();
      const formattedData = result
        .map((item) => ({
          id: item.object_id,
          query: item.query,
          date: new Date(item.created_at).toLocaleDateString(),
          createdAt: new Date(item.created_at),
        }))
        .sort((a, b) => b.createdAt - a.createdAt); // Sort from new to old
      setData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsKeywordDataLoading(false);
    }
  };

  const fetchUrlData = async () => {
    setIsUrlDataLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/user-data-url/${userId}/`
      );
      const result = await response.json();
      const formattedData = result
        .map((item) => ({
          id: item.object_id,
          title: item.title,
          date: new Date(item.created_at).toLocaleDateString(),
          createdAt: new Date(item.created_at),
        }))
        .sort((a, b) => b.createdAt - a.createdAt); // Sort from new to old
      setUrl_Data(formattedData);
    } catch (error) {
      console.error("Error fetching URL data:", error);
    } finally {
      setIsUrlDataLoading(false);
    }
  };

  const handleTitleClick = (row) => {
    navigate(`/urlData/${row.id}`);
  };

  const handleQueryClick = (row) => {
    const queryName = encodeURIComponent(row.query);
    navigate(`/detailskeyword/${queryName}`);
    // dispatch(process_Keyword({ query: row.query, userId }));
  };

  const filterData = () => {
    let filtered = data;
    if (startDate && endDate) {
      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0); // Set to start of the day
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999); // Set to end of the day
      filtered = filtered.filter(
        (item) => item.createdAt >= start && item.createdAt <= end
      );
    }
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.query.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.date.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.id.toString().includes(lowerCaseSearchTerm)
      );
    }
    setFilteredData(filtered);
  };
  const remainingQuota = useSelector((state) => state.authSlice.dailyQuota);

  console.log(remainingQuota, "remainingQuota ");

  const handleQueryCall = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.id]: true }));
    try {
      const result = await dispatch(
        process_Keyword({ query: row.query, userId })
      ).unwrap();
      if (result) {
        toast.success(`${row.query} processed successfully`);
        // dispatch(decrementDailyQuota(1));
      }
    } catch (error) {
      toast.error("Failed to process keyword");
    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.id]: false }));
      const queryName = encodeURIComponent(row.query);
      navigate(`/detailskeyword/${queryName}`);
    }
  };

  const handleRefresh = () => {
    fetchData();
    fetchUrlData(); // Refresh URL data as well
  };

  const extractDomain = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.startsWith("www.") ? domain.slice(4) : domain;
    } catch (error) {
      return "Invalid URL";
    }
  };

  const handleAccordionToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleRemoveClick = (row) => {
    setRowToDelete(row);
    setIsConfirmationOpen(true);
  };

  const onConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/Delete-data/${userId}/${rowToDelete.query}/`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setData((prevData) =>
          prevData.filter((item) => item.id !== rowToDelete.id)
        );
        toast.success(`${rowToDelete.query} removed successfully`);
        setIsConfirmationOpen(false);
      } else {
        toast.error(`Failed to remove item: ${response.statusText}`);
      }
    } catch (error) {
      toast.error("Error removing item");
      console.error("Error removing item:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const onCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleRemoveUrlClick = (row) => {
    setUrlRowToDelete(row);
    setIsUrlConfirmationOpen(true);
  };

  const onConfirmUrlDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/Delete-data-url/${userId}/${urlRowToDelete.id}/`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setUrl_Data((prevData) =>
          prevData.filter((item) => item.id !== urlRowToDelete.id)
        );
        toast.success(`${urlRowToDelete.title} removed successfully`);
        setIsUrlConfirmationOpen(false);
      } else {
        toast.error(`Failed to remove item: ${response.statusText}`);
      }
    } catch (error) {
      toast.error("Error removing item");
      console.error("Error removing item:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const onCloseUrlConfirmation = () => {
    setIsUrlConfirmationOpen(false);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Keyword",
      selector: (row) => row.query,
      sortable: true,
      cell: (row) => (
        <button
          className=" text-mainColor"
          onClick={() => handleQueryClick(row)}
        >
          {row.query}
        </button>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Refresh",
      cell: (row) => (
        <button
          onClick={() => handleQueryCall(row)}
          className={`flex items-center justify-center p-2 rounded-lg ${
            loadingRows[row.id]
              ? "text-mainColor bg-white cursor-not-allowed"
              : "text-white bg-mainColor"
          }`}
          disabled={loadingRows[row.id]}
        >
          {loadingRows[row.id] ? (
            <ImSpinner11 className="animate-spin" />
          ) : (
            <ImSpinner11 />
          )}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: "true",
    },
    {
      name: "Remove",
      cell: (row) => (
        <button
          onClick={() => handleRemoveClick(row)}
          className="flex items-center justify-center p-2 text-white rounded-lg bg-red-500"
        >
          <FaTrashAlt />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: "true",
    },
  ];

  const columns_URL = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => (
        <button
          className=" text-mainColor"
          onClick={() => handleTitleClick(row)}
        >
          {row.title}
        </button>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Remove",
      cell: (row) => (
        <button
          onClick={() => handleRemoveUrlClick(row)}
          className="flex items-center justify-center p-2 text-white rounded-lg bg-red-500"
        >
          <FaTrashAlt />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: "true",
    },
  ];

  const columns_ = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "70%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "20%",
    },
  ];

  const combinedH2H3Columns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "70%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "15%",
    },
    { name: "Type", selector: (row) => row.type, sortable: true, width: "15%" },
  ];

  const optimizationScore = 75;
  const Keyword_Query = keywordData?.Query;

  const keywordDataArray = keywordData?.Weights?.aggregated_h2_counts
    ? Object.entries(keywordData.Weights.aggregated_h2_counts).map(
        ([keyword, count]) => ({ keyword, count })
      )
    : [];

  const combinedH2H3DataArray = [
    ...(keywordData?.Weights?.aggregated_h2_counts
      ? Object.entries(keywordData.Weights.aggregated_h2_counts).map(
          ([keyword, count]) => ({ keyword, count, type: "H2" })
        )
      : []),
    ...(keywordData?.Weights?.aggregated_h3_counts
      ? Object.entries(keywordData.Weights.aggregated_h3_counts).map(
          ([keyword, count]) => ({ keyword, count, type: "H3" })
        )
      : []),
  ];

  const keyword_Count = keywordData?.Weights?.weighted_word_count;
  const keywordData_Array = keywordData?.Weights?.aggregated_results || [];

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setFilteredData(data);
    setSearchTerm("");
  };

  return (
    <div className="container p-4 mx-auto">
      <div
        className="relative w-full p-4 mx-auto mt-6 
            rounded-lg bg-baseColor dark:bg-darkbg md:mt-10 md:p-10"
      >
        
        {!keywordData && !urlData && (
          <div className="col-span-3">
            <Title title="Main Keyword Research" />
            <InputForm
              onSubmit={handleSubmit}
              isLoading={isKeywordLoading || isUrlLoading}
            />
          </div>
        )}
       

        <MainContent
          isKeywordDataLoading={isKeywordDataLoading}
          isUrlDataLoading={isUrlDataLoading}
          handleRefresh={handleRefresh}
          columns={columns}
          columns_URL={columns_URL}
          data={filteredData}
          keywordData={keywordData}
          urlData={urlData}
          url_Data={url_Data}
          combinedH2H3DataArray={combinedH2H3DataArray}
          combinedH2H3Columns={combinedH2H3Columns}
          keywordData_Array={keywordData_Array}
          columns_={columns_}
          openIndex={openIndex}
          handleAccordionToggle={handleAccordionToggle}
          createMarkup={createMarkup}
          extractDomain={extractDomain}
          handleQueryClick={handleQueryClick}
        />
      </div>
      <Confirmation
        isOpen={isConfirmationOpen}
        onClose={onCloseConfirmation}
        onConfirm={onConfirmDelete}
        message={`Are you sure you want to delete "${rowToDelete?.query}"?`}
        isDeleting={isDeleting}
      />
      <Confirmation
        isOpen={isUrlConfirmationOpen}
        onClose={onCloseUrlConfirmation}
        onConfirm={onConfirmUrlDelete}
        message={`Are you sure you want to delete "${urlRowToDelete?.title}"?`}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default MainKeywordResearchTest;
