import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ActivityLogsTable from "./ActivityLogsTable";
import parseActionText from "./parseActionText";
import OtherActivityLog from "./OtherActivityLog";
import { extractActionDetails } from "./extractActionDetails";
import DateFilter from "../../../components/DateFilter";
import { toast } from "react-toastify";
import ClearDialog from "../../../components/Modal/ClearDialog";
const sanitizeInput = (input) => {
  return input.replace(/[^a-zA-Z0-9 @.\-\/?:=&%\u0600-\u06FF]/g, "");
};
const Log = () => {
  // selectror
  const userId = useSelector((state) => state?.authSlice?.id);
  const roles = useSelector((state) => state.roleSlice.roles);
  const managed = useSelector((state) => state.authSlice.managed);
  const [queryLogs, setQueryLogs] = useState([]);
  const [otherLogs, setOtherLogs] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1");
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(new Date());
  const [tempEndDate, setTempEndDate] = useState(new Date());
  const [deleteTab, setDeleteTab] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const fetchQueryLogs = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/${userId}/`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      const filteredData = data.filter((log) => {
        const logDate = new Date(log.created_at);
        const endDate = new Date(dateRange.endDate);
        endDate.setHours(23, 59, 59, 999);
        return (
          (!dateRange.startDate || logDate >= new Date(dateRange.startDate)) &&
          (!dateRange.endDate || logDate <= endDate)
        );
      });

      const transformLogs = (logs) =>
        logs.map((log) => {
          const { query, target_url, project_name, action, user } =
            parseActionText(log.action);
          const { created_at } = log;
          return {
            ...log,
            query,
            target_url,
            project_name,
            created_at,
            action,
            user,
          };
        });

      setQueryLogs(transformLogs(filteredData));
    } catch (error) {
      console.error("Failed to fetch query logs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOtherLogs = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user-log/${userId}/`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      const filteredData = data.filter((log) => {
        const logDate = new Date(log.created_at);
        const endDate = new Date(dateRange.endDate);
        endDate.setHours(23, 59, 59, 999);
        return (
          (!dateRange.startDate || logDate >= new Date(dateRange.startDate)) &&
          (!dateRange.endDate || logDate <= endDate)
        );
      });

      const transformLogs = (logs) =>
        logs.map((log) => {
          const { action, user } = extractActionDetails(log.action);
          const { created_at } = log;
          return { ...log, action, user, created_at };
        });

      setOtherLogs(transformLogs(filteredData));
    } catch (error) {
      console.error("Failed to fetch other logs:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClearAll = async (tab) => {
    const endpoint =
      tab === "tab1"
        ? `${process.env.REACT_APP_API_URL}/api/Actionlogdelete/${userId}/`
        : `${process.env.REACT_APP_API_URL}/api/loggerdelete/${userId}/`;

    try {
      const response = await fetch(endpoint, { method: "DELETE" });
      if (response.ok) {
        toast.success("All logs deleted successfully");
        if (tab === "tab1") {
          setQueryLogs([]);
        } else {
          setOtherLogs([]);
        }
      } else {
        toast.error("Failed to delete logs");
      }
    } catch (error) {
      toast.error("Error deleting logs");
      console.error("Failed to delete logs:", error);
    }
    setIsConfirmOpen(false);
  };

  const handleSearchChange = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value); // Sanitize in real-time
    setSearchTerm(sanitizedValue);
  };

  const handleDateFilterConfirm = () => {
    setDateRange({ startDate: tempStartDate, endDate: tempEndDate });
    setIsDateFilterOpen(false);
  };
  const filteredQueryLogs = queryLogs.filter((log) =>
    log.query.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleConfirmDelete = (tab) => {
    // Open confirmation dialog and pass which tab is being cleared
    setDeleteTab(tab);
    setIsConfirmOpen(true);
  };
  useEffect(() => {
    if (activeTab === "tab1") {
      fetchQueryLogs();
    } else if (activeTab === "tab2") {
      fetchOtherLogs();
    }
  }, [activeTab, userId, dateRange]);

  return (
    <div className="relative p-4 bg-[#f9f9f9] rounded-lg shadow-lg mt-10 md:mt-14 md:p-10">
      <div className="flex flex-col md:flex-row justify-between p-3 md:p-0">
        <div>
          <h1 className="mb-4 font-bold  text-darkblue mt-6 md:mt-0 text-lg 2xl:text-xl xl:text-lg md:text-sm ">
            Activity Logs
          </h1>

          <p className="mb-6 text-sm text-gray-700 md:text-base">
            An overview of your latest requests
          </p>
        </div>
        {(!managed || (managed && roles.Audit_log)) && (
          <div className="flex flex-col md:flex-row justify-between mb-4 gap-6">
            <div className="flex gap-x-3 items-start md:items-end">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="p-2 border border-gray-300 mt-1 rounded focus:outline-none focus:border-2 focus:border-basegray transition duration-300 ease-in-out md:w-[300px] w-full"
                placeholder="Search by query"
              />

              <button
                className="cursor-pointer text-center text-gray-600 border-2
                 border-gray-400 rounded-md w-fit p-2 hover:bg-mainColor
                  hover:text-white hover:border-mainColor
                   transition-all duration-500 ease-in-out"
                onClick={() => setIsDateFilterOpen(true)}
              >
                Customize Date
              </button>
              {activeTab === "tab1" && queryLogs.length > 0 && (
                <button
                  className="cursor-pointer text-center text-red-600 border-2 border-red-400 rounded-md w-fit p-2 hover:bg-red-600 hover:text-white hover:border-red-600 transition-all duration-500 ease-in-out"
                  onClick={() => handleConfirmDelete("tab1")}
                >
                  Clear All
                </button>
              )}
              {activeTab === "tab2" && otherLogs.length > 0 && (
                <button
                  className="cursor-pointer text-center text-red-600 border-2 border-red-400 rounded-md w-fit p-2 hover:bg-red-600 hover:text-white hover:border-red-600 transition-all duration-500 ease-in-out"
                  onClick={() => handleConfirmDelete("tab2")}
                >
                  Clear All
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {!managed || (managed && roles.Audit_log) ? (
        <Tabs value={activeTab} onChange={setActiveTab}>
          <TabsHeader
            className="p-0 font-semibold bg-transparent 
          border-b rounded-none border-mainColor-gray-50 z-10 relative"
            indicatorProps={{
              className:
                "bg-transparent border-b-2 border-mainColor shadow-none rounded-none",
            }}
          >
            <Tab key="tab1" value="tab1" onClick={() => setActiveTab("tab1")}>
              Query Logs
            </Tab>
            <Tab
              key="tab2"
              value="tab2"
              onClick={() => setActiveTab("tab2")}
              className="relative z-10"
            >
              Other Activity Logs
            </Tab>
          </TabsHeader>
          <TabsBody>
            <TabPanel key="tab1" value="tab1">
              <ActivityLogsTable
                logs={filteredQueryLogs}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel key="tab2" value="tab2">
              <OtherActivityLog logs={otherLogs} isLoading={isLoading} />
            </TabPanel>
          </TabsBody>
        </Tabs>
      ) : (
        <p className="text-red-500">
          You do not have permission to view the audit logs.
        </p>
      )}

      <DateFilter
        isOpen={isDateFilterOpen}
        onClose={() => setIsDateFilterOpen(false)}
        setStartDate={setTempStartDate}
        setEndDate={setTempEndDate}
        startDate={tempStartDate}
        endDate={tempEndDate}
        onConfirm={handleDateFilterConfirm}
      />

      <ClearDialog
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={() => handleClearAll(deleteTab)}
        message="Are you sure you want to delete all logs?"
      />
    </div>
  );
};

export default Log;
