import React, { useState } from "react";
import { Card, CardBody } from "@material-tailwind/react";
import { FaEdit, FaTrash, FaThumbtack, FaCheck } from "react-icons/fa";
import ReactApexChart from "react-apexcharts";
import ConfirmationDialog from "../../../components/Modal/ConfirmingDialog";
import SkeletonProject from "../../../components/Skeleton/SkeletonProject";
import SkeletonChart from "../../../components/Skeleton/SkeletonChart";
import { Link } from "react-router-dom";

const ProjectCardList = ({
  loading,
  projectsPerPage,
  currentProjects,
  chartOptions,
  chartSeries,
  handleCardClick,
  selectedProjectId,
  handleNameChange,
  handleUpdateProjectName,
  handleEditClick,
  togglePinProject,
  handleOpenProject,
  requestDeleteProject,
  managed,
  roles,
  showDeleteConfirmation,
  cancelDeleteProject,
  confirmDeleteProject,
  isDeleting,
  extractDomainName,
  formatDate,
  userId,
  loadingCharts,
}) => {
  const [nameErrors, setNameErrors] = useState({});

  const validateAndSaveName = (projectId, name) => {
    if (name.trim() === "") {
      setNameErrors((prev) => ({
        ...prev,
        [projectId]: "Project name cannot be empty",
      }));
    } else {
      setNameErrors((prev) => ({
        ...prev,
        [projectId]: "",
      }));
      handleUpdateProjectName(projectId);
    }
  };

  return (
    <div className="flex flex-wrap items-center w-full px-4 sm:px-6 md:px-8 lg:px-10 justify-evenly">
      {loading ? (
        Array.from({ length: projectsPerPage }).map((_, index) => (
          <SkeletonProject key={index} />
        ))
      ) : currentProjects.length > 0 ? (
        currentProjects.map((card) => (
          <Card
            key={card.project_id}
            className={`w-full bg-white dark:bg-darkbg text-gray-950 dark:text-gray-300 sm:w-[48%] lg:w-[32%] m-1 shadow-none border-[3px] ${card.project_id === selectedProjectId
              ? "border-mainColor"
              : "border-gray-200 dark:border-gray-500"
              } rounded-2xl mt-6`}
            onClick={() => handleCardClick(card.project_id)}
          >
            <CardBody className="p-0">
              <div className="flex justify-between border-b-2 border-gray-200 dark:border-gray-500">
                {card.editable ? (
                  <div className="flex items-center w-full">
                    <input
                      type="text"
                      value={card.name}
                      onChange={(e) =>
                        handleNameChange(card.project_id, e.target.value)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          validateAndSaveName(card.project_id, card.name);
                        }
                      }}
                      className="flex-grow px-2 py-1 text-lg font-bold 
                      cursor-pointer rounded-l-2xl focus:outline-none dark:bg-gray-900
                      "
                      autoFocus
                    />
                    <button
                      className="px-2 py-1  text-md font-semibold
                       bg-mainColor-500  text-mainColor"
                      onClick={() => handleUpdateProjectName(card.project_id)}
                    >
                      <FaCheck />
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center justify-between px-6 py-2">
                    <h5
                      className="flex-grow text-lg font-bold cursor-pointer"
                      onClick={() => handleCardClick(card.project_id)}
                    >
                      {card.name}
                    </h5>
                    <FaEdit
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(card.project_id);
                      }}
                      className="ml-4 text-mainColor-500
                       transition-colors duration-150 ease-in-out
                        cursor-pointer hover:text-mainColor-700"
                      aria-label="Edit project"
                    />
                  </div>
                )}
                <FaThumbtack
                  className={`mt-3 mr-3 cursor-pointer ${card.isPinned ? "text-mainColor" : "text-gray-500"
                    }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePinProject(card.name, card.project_id, false, true);
                  }}
                />
              </div>
              <div className="flex flex-col p-3 md:flex-row">
                <div className="w-full md:w-1/2">
                  {loadingCharts[card.project_id] ? (
                    <SkeletonChart className="h-40" />
                  ) : (
                    <ReactApexChart
                      options={chartOptions}
                      series={chartSeries[card.project_id] || []}
                      type="line"
                      height={200}
                      className="text-gray-950 dark:text-gray-300"
                    />
                  )}
                </div>

                {/* <ReactApexChart
                  options={chartOptions}
                  series={chartSeries[card.project_id] || []}
                  type="line"
                  height={200}
                  className="w-full md:w-1/2 text-gray-950 dark:text-gray-300"
                /> */}
                <div className="flex flex-col items-center justify-center w-full m-auto md:w-1/2 relative">
                  <h5 className="text-lg font-bold text-greeng dark:text-gray-300 tooltip">
                    {extractDomainName(card.url)}
                  </h5>
                  <p className="text-greeng dark:text-gray-300">
                    {formatDate(card.created_at)}
                  </p>
                  {card.rank_count > 0 && (
                    <p className="text-greeng dark:text-gray-300 font-bold">
                      Word Count: {card.rank_count}
                    </p>
                  )}

                  <div className="flex items-center mt-4 space-x-2">
                    <Link to={`/projects/${card.project_id}/`} state={{ projectDetails:card }}>
                      <button
                        className="px-6 py-1 text-lg font-bold text-white rounded-full bg-mainColor"
                        onClick={() =>
                          handleOpenProject(userId, card.project_id, card)
                        }
                      >
                        Open
                      </button>
                    </Link>
                    {!managed && (
                      <FaTrash
                        size={30}
                        onClick={() => requestDeleteProject(card.project_id)}
                        className="bg-red-500 text-white  p-2 rounded-[10px] cursor-pointer"
                      />
                    )}
                    {managed && roles.Delete_project && (
                      <FaTrash
                        onClick={() => requestDeleteProject(card.project_id)}
                        size={28}
                        className="bg-red-500 text-white  p-2 rounded-[10px] cursor-pointer"
                      />
                    )}
                    <ConfirmationDialog
                      isOpen={showDeleteConfirmation}
                      onClose={cancelDeleteProject}
                      onConfirm={confirmDeleteProject}
                      message="Are you sure you want to delete this project?"
                      isDeleting={isDeleting}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        ))
      ) : (
        <div className="flex items-center justify-center dark:text-gray-300">
          No projects found.
        </div>
      )}
    </div>
  );
};

export default ProjectCardList;
