import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ResultSample from "./ResultSample";
import useFetchDetails from "../../../hook/useFetchDetails";

const DetailsKeyword = () => {
  const location = useLocation();
  const queryName = decodeURIComponent(location.pathname.split("/")[2]);
  const userId = useSelector((state) => state?.authSlice?.id);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const apiUrl = `${
    process.env.REACT_APP_API_URL
  }/keyword/Query-data/${userId}/${encodeURIComponent(queryName)}/`;

  const { data: fetchedData, error: fetchError } = useFetchDetails(apiUrl);

  useEffect(() => {
    if (location.state?.responseData) {
      setData([location.state.responseData]);
    } else {
      // Otherwise, use fetched data from the custom hook
      if (fetchError) {
        setError(fetchError);
      } else {
        setData(fetchedData);
      }
    }
  }, [fetchedData, fetchError, location.state?.responseData]);

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="container p-4 mx-auto">
      {data ? <ResultSample
       data={data}
       IsLocation={location.state?.responseData?true:false} /> : <div>Loading...</div>}
    </div>
  );
};

export default DetailsKeyword;
