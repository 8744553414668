import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaUpload, FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import axios from "axios";
import ConfirmationDialogg from "../Modal/ConfirmationDialogg"; // Adjust the import path as necessary

const FileUpload = ({ projectId, userId,setProcessCompleted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const [completedRequests, setCompleteRequestes] = useState(0);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes, seconds] = time.split(":");
    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }
    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }
    return `${hours}:${minutes}:${seconds}`;
  };
  const uploadFile = async (formattedChunk) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/add-rank-upload/${userId}/${projectId}/`,
      { queries_info: formattedChunk },
      { headers: { "Content-Type": "application/json" } }
    );
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const retryRequest = async (
    formattedChunk,
    completedRequests,
    totalRequests
  ) => {
    const retryLimit = 10;
    let attempt = 0;
    let requestSuccessful = false;
    while (attempt < retryLimit && !requestSuccessful) {
      try {
        const response = await uploadFile(formattedChunk);
        if (response && response.data) {
          requestSuccessful = true;
          completedRequests.current++;
          setProgress(
            Math.round((completedRequests.current / totalRequests) * 100)
          );
        } else {
          throw new Error("Invalid response format or no data returned.");
        }
      } catch (error) {
        attempt++;
        await new Promise((resolve) => setTimeout(resolve, 2000 * attempt)); // Exponential back-off
      }
    }
    if (!requestSuccessful) {
      throw new Error("Failed after maximum retry attempts.");
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setIsLoading(true);
        try {
          const data = await file.arrayBuffer();
          const workbook = XLSX.read(data, { type: "array" });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(sheet);

          const chunkSize = 10;
          const totalRequests = Math.ceil(jsonData.length / chunkSize);
          const completedRequests = { current: 0 };

          const chunks = chunkArray(jsonData, chunkSize);

          for (const chunk of chunks) {
            const formattedChunk = chunk.map((rowData) => ({
              query: rowData.query || "",
              target_url: rowData.target_url || "",
              google_domain: rowData.google_domain || "",
              new_query: rowData.new_query || "nan",
              rank: rowData.rank || "",
              rank_abs: rowData.rank_abs || "",
              source_link: rowData.source_link || "",
              breadcrumb: rowData.breadcrumb || "",
              result_stat: rowData.result_stat || "",
              Type_rank: rowData.Type_rank || "",
              best_rank: rowData.best_rank || 0,
              rank_diff: rowData.rank_diff || 0,
              first_five_links: rowData.first_five_links || "nan",
              date: rowData.date ? formatDate(rowData.date) : "",
              time: rowData.time ? formatTime(rowData.time) : "",
            }));

            try {
              await retryRequest(
                formattedChunk,
                completedRequests,
                totalRequests
              );
            } catch (error) {
              console.error("Failed to upload chunk after retries:", error);
            }
          }

          setIsLoading(false);
          setProgress(0);
          toast.success("All data uploaded successfully");
          setProcessCompleted(true)
          setShowConfirmDialog(true); // Show the confirmation dialog on success
        } catch (error) {
          toast.error("Error processing file. Please try again.");
        } finally {
          setIsLoading(false);
          setProgress(0);
        }
      } else {
        toast.error("Please select a valid Excel file.");
      }
    },
    [projectId, userId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    maxFiles: 1,
  });

  return (
    <div>
      {!isLoading && (
        <div {...getRootProps()} className="flex items-center">
          <input {...getInputProps()} />

          <div
            className={`flex items-center justify-center px-4 py-2 font-bold transition duration-150 ease-in-out bg-white rounded text-mainColor ${
              isLoading
                ? "cursor-not-allowed"
                : "hover:bg-mainColor hover:text-white"
            }`}
          >
            <FaUpload className="mr-2" />

            {isLoading
              ? "Importing..."
              : isDragActive
              ? "Drop the file here ..."
              : "Import"}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="flex items-center">
          <div className="flex items-center gap-2 w-full">
            <div className=" w-[100px] h-2 bg-gray-200 rounded  ">
              <div
                className=" h-full bg-green-500 rounded progress-bar-striped"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="text-left font-semibold">{progress}%</div>
          </div>
          <div
            className={`flex items-center justify-center px-4 py-2 font-bold transition duration-150 ease-in-out bg-white rounded text-mainColor 
        
               "cursor-not-allowed"
              
          `}
          >
            <FaSpinner className="mr-2 animate-spin" />
            Importing...
          </div>
        </div>
      )}

      {/* <ConfirmationDialogg
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => {
          setShowConfirmDialog(false);
          window.location.reload();
        }}
        message="The data has been uploaded. Do you want to refresh the page to see the new data?"
      /> */}
    </div>
  );
};

export default FileUpload;
