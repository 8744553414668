import React from "react";
import { FaSpinner } from "react-icons/fa";



const Confirmation = ({ isOpen, onClose, onConfirm, message, isDeleting, keyword }) => {
  if (!isOpen) return null;

  return (

    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}

      className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
      bg-greeng 
      bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
    >
      <div className="w-full max-w-sm p-4 bg-white rounded-lg shadow-lg">
        <p className="text-lg">{message}</p>
        <div className="flex justify-end mt-4 space-x-4">
          <button
            className="px-4 py-2 font-bold text-black bg-gray-300 rounded hover:bg-gray-400"
            onClick={onClose}
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button
            className={`flex items-center px-4 py-2 font-bold text-white ${keyword ? "bg-mainColor rounded " : "bg-red-500 rounded hover:bg-red-700"} `}
            onClick={onConfirm}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <>
                <FaSpinner className="animate-spin text-white mr-2 " />
                {keyword ? "updating..." : "Deleting..."}
              </>
            ) : (
              `${keyword ? keyword : "Delete"}`
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
