import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  FaArrowTrendUp,
  FaArrowTrendDown,
  FaArrowRightLong,
} from "react-icons/fa6";

const ActivityPieComponent = ({
  tableData,
  setFilteredData,
  scrollToSection,
  filteredBy,
  setFilteredBy,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isD3Loaded, setIsD3Loaded] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [filtered, setFiltered] = useState(false);
  const [queries, setQueries] = useState({
    Improved: [],
    Declined: [],
    Maintained: [],
  });
  // Load D3 dynamically
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/d3/7.9.0/d3.min.js";
    script.onload = () => setIsD3Loaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const rankStatistics = useMemo(() => {
    if (!Array.isArray(tableData)) {
      console.error("Invalid tableData:", tableData);
      return { Improved: 0, Declined: 0, Maintained: 0 };
    }
    let Improved = 0;
    let Declined = 0;
    let Maintained = 0;

    const collectedQueries = {
      Improved: [],
      Declined: [],
      Maintained: [],
    };

    tableData.forEach((query) => {
      const rank_diff = query.rank_diff || 0; // Default to 0 if rank_diff is undefined
      if (rank_diff > 0) {
        Improved++;
        collectedQueries.Improved.push(query);
      } else if (rank_diff < 0) {
        Declined++;
        collectedQueries.Declined.push(query);
      } else {
        Maintained++;
        collectedQueries.Maintained.push(query);
      }
    });

    setQueries(collectedQueries);

    return { Improved, Declined, Maintained };
  }, [tableData]);

  useEffect(() => {
    setIsVisible(
      rankStatistics.Improved > 0 ||
        rankStatistics.Declined > 0 ||
        rankStatistics.Maintained > 0
    );
  }, [rankStatistics]);

  // D3 Pie Chart Drawing
  const svgRef = useRef();

  // useEffect(() => {
  //   if (isVisible && isD3Loaded) {
  //     const data = [
  //       {
  //         label: "Improved",
  //         value: rankStatistics.Improved,
  //         color: "#16a34a",
  //       },
  //       {
  //         label: "Declined",
  //         value: rankStatistics.Declined,
  //         color: "#dc2626",
  //       },
  //       {
  //         label: "Maintained",
  //         value: rankStatistics.Maintained,
  //         color: "#c4c2c2",
  //       },
  //     ];
  //     const colors = ["#16a34a", "#dc2626", "#c4c2c2"];
  //     const width = 160;
  //     const height = 160;
  //     const radius = Math.min(width, height) / 2;

  //     const svg = window.d3
  //       .select(svgRef.current)
  //       .attr("width", width)
  //       .attr("height", height)
  //       .style("overflow", "visible");

  //     svg.selectAll("*").remove(); // Clear previous content

  //     const g = svg.append("g").attr("transform", `translate(80, 80)`);

  //     const pie = window.d3.pie().value((d) => d.value);
  //     const arc = window.d3.arc().innerRadius(0).outerRadius(radius);
  //     console.log(selectedData, "selectedData");

  //     const arcs = g
  //       .selectAll("arc")
  //       .data(pie(data))
  //       .enter()
  //       .append("g")
  //       .attr("class", "arc")
  //       .style("cursor", "pointer");

  //     arcs
  //       .append("path")
  //       .attr("d", arc)
  //       .attr("fill", (d, i) =>
  //         selectedData === d.data.label && filteredBy === "pie"
  //           ? window.d3.color(colors[i]).darker(1)
  //           : colors[i]
  //       )
  //       .attr("stroke", (d) =>
  //         selectedData === d.data.label ? d.data.color : "none"
  //       )

  //       .on("click", (event, d) => {
  //         const dataPointIndex = d.index;
  //         const ranges = ["Improved", "decreased", "same"];
  //         const selectedRange = ranges[dataPointIndex];
  //         if (selectedRange && queries[d.data.label]?.length > 0) {
  //           scrollToSection("table");
  //           setFilteredData(queries[d.data.label]);
  //           setFiltered(true);
  //           setFiltered(true);
  //           setSelectedData(d.data.label);
  //           setFilteredBy("pie");
  //         }
  //       })
  //       .on("mouseover", (event, d) => {
  //         const tooltip = window.d3
  //         .select("body")
  //         .append("div")
  //         .attr("id", "custom-tooltip")
  //         .style("position", "absolute")
  //         .style("background-color", "#fff")
  //         .style("padding", "8px")
  //         .style("border-radius", "4px")
  //         .style("box-shadow", "0px 2px 4px rgba(0, 0, 0, 0.2)")
  //         .style("pointer-events", "none")// Prevents the tooltip from interfering with mouse events
  //         .style("opacity", 1)
  //         .html(
  //           `<div style="display: flex; align-items: center;">
  //             <div style="width: 10px; height: 10px; background-color: ${
  //               d.data.color
  //             }; border-radius: 50%; margin-right: 5px;"></div>
  //             <div style="font-weight: bold;">${d.data.label}</div>
  //             <div style="margin-left: 10px;">${d.data.value}</div>
  //           </div>`
  //         );
  //         // Set tooltip content and make it visible
  //         // tooltip
  //         //   .style("opacity", 1)
  //         //   .html(`${d.data.label}: ${d.data.value}`)
  //         //   .style("background-color", d.data.color);

  //         // Get the bounding box of the pie chart (assuming it's an SVG element)
  //         const svgBounds = svgRef.current.getBoundingClientRect();

  //         // Calculate tooltip position based on the pie chart container
  //         // Adjust the offsets based on your layout (these are example values)
  //         console.log(svgBounds, "svgBounds");

  //         const tooltipX =
  //           svgBounds.left + svgBounds.width / 2 - svgBounds.left +90; // Centered horizontally
  //         const tooltipY = svgBounds.top + svgBounds.height / 2 - 200; // Adjust for height

  //         // Apply the calculated position (fixed relative to the pie chart)
  //         tooltip.style("left", `${tooltipX}px`).style("top", `${tooltipY}px`);
  //       })
  //       .on("mouseout", () => {
  //         // Hide the tooltip when the mouse leaves the element
  //         window.d3.select("#tooltip").style("opacity", 0);
  //       });

  //     // .on("mouseover", (event, d) => {
  //     //   const tooltip = window.d3.select("#tooltip");
  //     //   tooltip
  //     //     .style("opacity", 1)
  //     //     .html(`${d.data.label}: ${d.data.value}`)
  //     //     .style("background-color", d.data.color);
  //     //   const svgBounds = svgRef.current.getBoundingClientRect();
  //     //   const tooltipX = svgBounds.left - event.offsetX - 550;
  //     //   const tooltipY = svgBounds.top - event.offsetY - 50;
  //     //   tooltip.style("left", `${svgBounds.left}px`).style("top", `${svgBounds.top}px`);
  //     // })
  //     // .on("mousemove", (event) => {
  //     //   const tooltip = window.d3.select("#tooltip");
  //     //   const svgBounds = svgRef.current.getBoundingClientRect();
  //     //   const tooltipX = svgBounds.left - event.offsetX - 550;
  //     //   const tooltipY = svgBounds.top - event.offsetY - 50;

  //     //   tooltip.style("left", `${svgBounds.left}px`).style("top", `${svgBounds.top}px`);
  //     // })
  //     // .on("mouseout", () => {
  //     //   window.d3.select("#tooltip").style("opacity", 0);
  //     // });
  //     arcs
  //       .append("text")
  //       // .attr("transform", (d) => {
  //       //   const [x, y] = arc.centroid(d); // Get the centroid of the slice
  //       //   return `translate(${x - 8}, ${y - 18})`; // Adjust y to position the text slightly above the centroid
  //       // })
  //       .attr("transform", (d) => `translate(${arc.centroid(d)})`) // Position the text at the center of the slice
  //       .attr("text-anchor", "middle") // Center the text horizontally
  //       .attr("dy", "0.35em") // Adjust the vertical alignment
  //       .style("fill", "#fff") // Set text color (you can change it if needed)
  //       .style("font-size", "10px") // Increase font size for better readability
  //       .style("font-weight", "bold") // Make the text bold
  //       .style("text-shadow", "1px 1px 2px #000") // Add a shadow effect for better contrast
  //       .text((d) => {
  //         const totalValue = window.d3.sum(data.map((item) => item.value));
  //         const percentage = ((d.data.value / totalValue) * 100).toFixed(0);
  //         return percentage > 5 ? `${percentage}%` : ""; // Display percentage only if it's greater than 5%
  //       });
  //   }
  // }, [
  //   isVisible,
  //   isD3Loaded,
  //   rankStatistics,
  //   queries,
  //   setFilteredData,
  //   setFiltered,
  //   selectedData,
  //   filteredBy,
  // ]);

  useEffect(() => {
    if (isVisible && isD3Loaded) {
      const data = [
        {
          label: "Improved",
          value: rankStatistics.Improved,
          color: "#16a34a",
        },
        {
          label: "Declined",
          value: rankStatistics.Declined,
          color: "#dc2626",
        },
        {
          label: "Maintained",
          value: rankStatistics.Maintained,
          color: "#c4c2c2",
        },
      ];
      const colors = ["#16a34a", "#dc2626", "#c4c2c2"];
      const width = 160;
      const height = 160;
      const radius = Math.min(width, height) / 2;

      // Create tooltip once outside of the event
      const tooltip = window.d3
        .select("body")
        .append("div")
        .attr("id", "custom-tooltip")
        .style("position", "absolute")
        .style("background-color", "#fff")
        .style("padding", "8px")
        .style("border-radius", "4px")
        .style("box-shadow", "0px 2px 4px rgba(0, 0, 0, 0.2)")
        .style("pointer-events", "none")
        .style("opacity", 0); // Initially hidden

      const svg = window.d3
        .select(svgRef.current)
        .attr("width", width)
        .attr("height", height)
        .style("overflow", "visible");

      svg.selectAll("*").remove(); // Clear previous content

      const g = svg.append("g").attr("transform", `translate(80, 80)`);

      const pie = window.d3.pie().value((d) => d.value);
      const arc = window.d3.arc().innerRadius(0).outerRadius(radius);

      const arcs = g
        .selectAll("arc")
        .data(pie(data))
        .enter()
        .append("g")
        .attr("class", "arc")
        .style("cursor", "pointer");

      arcs
        .append("path")
        .attr("d", arc)
        .attr("fill", (d, i) =>
          selectedData === d.data.label && filteredBy === "pie"
            ? window.d3.color(colors[i]).darker(1)
            : colors[i]
        )
        .attr("stroke", (d) =>
          selectedData === d.data.label ? d.data.color : "none"
        )
        .attr("stroke-width", 1.5)
        .on("click", (event, d) => {
          const dataPointIndex = d.index;
          const ranges = ["Improved", "decreased", "same"];
          const selectedRange = ranges[dataPointIndex];
          if (selectedRange && queries[d.data.label]?.length > 0) {
            scrollToSection("table");
            setFilteredData(queries[d.data.label]);
            setFiltered(true);
            setSelectedData(d.data.label);
            setFilteredBy("pie");
          }
        })
        .on("mouseover", (event, d) => {
          tooltip.style("opacity", 1).html(
            `<div style="display: flex; align-items: center;">
                <div style="width: 10px; height: 10px; background-color: ${
                  d.data.color
                }; border-radius: 50%; margin-right: 5px;"></div>
                <div style="font-weight: bold;">${d.data.label}</div>
                <div style="margin-left: 10px;">(${d.data.value})</div>
                <div style="margin-left: 10px;">${(
                  (d.data.value /
                    window.d3.sum(data.map((item) => item.value))) *
                  100
                ).toFixed(0)}%</div>

              </div>`
          );
        })
        .on("mousemove", (event) => {
          tooltip
            .style("left", `${event.pageX + 15}px`) // Adjusted to follow the cursor
            .style("top", `${event.pageY - 28}px`); // Adjusted for height
        })
        .on("mouseout", () => {
          tooltip.style("opacity", 0); // Hide the tooltip on mouse out
        });

      // Append the percentage text as before
      arcs
        .append("text")
        .attr("transform", (d) => `translate(${arc.centroid(d)})`) // Position the text at the center of the slice
        .attr("text-anchor", "middle") // Center the text horizontally
        .attr("dy", "0.35em") // Adjust the vertical alignment
        .style("fill", "#fff") // Set text color
        .style("font-size", "10px") // Adjust font size
        .style("font-weight", "bold") // Make text bold
        .style("text-shadow", "1px 1px 2px #000") // Add shadow effect
        .text((d) => {
          const totalValue = window.d3.sum(data.map((item) => item.value));
          const percentage = ((d.data.value / totalValue) * 100).toFixed(0);
          return percentage > 5 ? `${percentage}%` : ""; // Display percentage only if greater than 5%
        });

      // Clean up the tooltip when the component unmounts
      return () => {
        tooltip.remove();
      };
    }
  }, [
    isVisible,
    isD3Loaded,
    rankStatistics,
    queries,
    setFilteredData,
    setFiltered,
    selectedData,
    filteredBy,
  ]);

  return (
    <div
      className={`relative flex flex-col py-4
         w-full h-full border-2
         border-gray-200 bg-white
          dark:bg-slate rounded-lg transition-opacity duration-500`}
    >
      <div
        id="tooltip"
        className="absolute z-[1000] top-16 left-2 shadow
            opacity-0 bg-[#d8d6d6] rounded-lg text-white p-2 text-sm"
      ></div>

      {isVisible ? (
        <>
          <div
            className={`flex items-center justify-between gap-1 mb-2 ${
              filtered && filteredBy === "pie" ? "px-2" : "px-4"
            } `}
          >
            <h2
              className="text-lg font-semibold text-gray-700
             dark:text-gray-300"
            >
              Activity Ranks
            </h2>
            {/* <button className="text-[12px] text-red-600 font-bold">Reset</button> */}
            {filtered && filteredBy === "pie" && (
              <button
                onClick={() => {
                  setFilteredData(tableData);
                  setFiltered(false);
                  setSelectedData(null);
                }}
                className="text-[12px] font-bold bg-mainColor text-white p-1 rounded"
              >
                Reset
              </button>
            )}
          </div>
          <div className="flex flex-col items-center justify-evenly h-full">
            {isD3Loaded && <svg ref={svgRef}></svg>}
            <div className="w-full flex items-center justify-center gap-2">
              <p className="text-green-500 text-sm flex items-center justify-center gap-1">
                <FaArrowTrendUp />
                <span>{rankStatistics.Improved}</span>
              </p>
              <p className="text-red-500 text-sm flex items-center justify-center gap-1">
                <FaArrowTrendDown />
                <span>{rankStatistics.Declined}</span>
              </p>
              <p className="text-gray-500 dark:text-gray-400 text-sm flex items-center justify-center gap-1">
                <FaArrowRightLong />
                <span>{rankStatistics.Maintained}</span>
              </p>
            </div>
          </div>
        </>
      ) : (
        <p className="text-gray-500 text-center">No data available</p>
      )}
    </div>
  );
};

export default ActivityPieComponent;
