import React from "react";
import RankRange from "../../Range/RankRange";

const RankRangeComponent = ({
  tableData,
  setFilteredData,
  setFiltered,
  scrollToSection,
  filteredBy,
  setFilteredBy,
}) => (
  <div
    className="w-full transition duration-500 transform
  border-2 border-gray-200 dark:border-gray-500 rounded-lg "
  >
    <RankRange
      filteredBy={filteredBy}
      setFilteredBy={setFilteredBy}
      queries={tableData}
      setFilteredData={setFilteredData}
      setFiltered={setFiltered}
      scrollToSection={scrollToSection}
    />
  </div>
);

export default RankRangeComponent;
