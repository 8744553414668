import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import DataTable from "react-data-table-component";
import Serpanalysis from "../Serpanalysis";
import { useSelector } from "react-redux";
import { parseISO, isSameDay, addDays, subDays } from "date-fns";
import { usePagination } from "../../hook";
import PagePaginationProjects from "../../components/MainRank/Pagination/PagePaginationProjects";
const TabsProjects = ({ rowData, projectId, projectDetails }) => {
  console.log(rowData, "rowData")
  const [activeTab, setActiveTab] = useState("tab1");
  const [tabData, setTabData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [yAxisMin, setYAxisMin] = useState(0);
  const [yAxisMax, setYAxisMax] = useState(100);
  const [timePeriod, setTimePeriod] = useState("month");
  const userId = useSelector((state) => state?.authSlice?.user?.id);
  const [visibilityData, setVisibilityData] = useState([]);
  const [applyBlur, setApplyBlur] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const state = useSelector((state) => state);
  useEffect(() => {
    if (rowData.first_five_links) {
      const preparedData = prepareVisibilityData(rowData.first_five_links);
      setVisibilityData(preparedData);
    }
  }, [rowData]);

  useEffect(() => {
    if (rowData) {
      const fetchTabData = async () => {
        const encodedQuery = encodeURIComponent(rowData.query);
        const queryId = encodeURIComponent(rowData.query_id);
        const encodedTargetUrl = encodeURIComponent(rowData.target_url);
        const url = `${process.env.REACT_APP_API_URL}/api/display-project-ranks/${userId}/${queryId}/${projectId}/`;

        try {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            setTabData(data);
            processChartData(data, "month");
            processHistoryData(data);
          } else {
            console.error("Error fetching data:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchTabData();
    }
  }, [rowData]);

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
    processChartData(tabData, e.target.value);
  };

  const processChartData = (data, selectedTimePeriod) => {
    let filteredData = filterDataByTimePeriod(data, selectedTimePeriod);
    const chartSeries = filteredData.map((item) => {
      const date = new Date(item.date);
      const rank = item.rank === 101 ? 101 : parseInt(item.rank, 10);
      return {
        x: date.getTime(),
        y: rank,
        label: rank === null ? "-" : rank.toString(),
      };
    });

    const allRanksAreInvalid = chartSeries.every((item) => item.y === null);
    setApplyBlur(allRanksAreInvalid);

    const ranks = chartSeries.map((item) => (item.y === null ? 101 : item.y));
    const minRank = Math.min(...ranks);
    const maxRank = Math.max(...ranks);
    const buffer = 1;

    setSeriesData(chartSeries);
    setYAxisMin(Math.max(0, minRank - buffer));
    setYAxisMax(maxRank + buffer);
  };

  const filterDataByTimePeriod = (data, timePeriod) => {
    const currentDate = new Date();
    return data.filter((item) => {
      const itemDate = parseISO(item.date);
      switch (timePeriod) {
        case "day":
          return isSameDay(currentDate, itemDate);
        case "week":
          return itemDate >= subDays(currentDate, 7);
        case "month":
          return itemDate >= subDays(currentDate, 30);
        default:
          return true;
      }
    });
  };

  const processHistoryData = (data) => {
    const currentDate = new Date();
    const lastDayData = data.find((item) =>
      isSameDay(addDays(currentDate, -1), parseISO(item.date))
    );
    const lastWeekData = data
      .filter((item) => parseISO(item.date) >= addDays(currentDate, -8))
      .slice(-1)[0];
    const lastMonthData = data
      .filter((item) => parseISO(item.date) >= addDays(currentDate, -31))
      .slice(-1)[0];

    setHistoryData([
      { period: "Last Day", data: lastDayData ? lastDayData.rank : "N/A" },
      { period: "Last 7 Days", data: lastWeekData ? lastWeekData.rank : "N/A" },
      {
        period: "Last 30 Days",
        data: lastMonthData ? lastMonthData.rank : "N/A",
      },
    ]);
  };

  const extractDomainName = (url) => {
    try {
      const decodedUrl = decodeURIComponent(url);
      if (decodedUrl.length > 50) {
        return decodedUrl.substring(0, 47) + "...";
      }
      return decodedUrl;
    } catch (error) {
      return url.length > 50 ? url.substring(0, 47) + "..." : url;
    }
  };

  const prepareVisibilityData = (firstFiveLinks) => {
    if (!firstFiveLinks || firstFiveLinks.length === 0) {
      return [];
    }

    let linksArray = [];
    try {
      linksArray = JSON.parse(firstFiveLinks.replace(/'/g, '"'));
    } catch (error) {
      console.error("Error parsing first_five_links", error);
    }

    return linksArray.map((link, index) => ({
      id: index + 1,
      url: link,
      domain: extractDomainName(link),
    }));
  };

  const getDisplayRank = (rank) => {
    return [100, 101, 102].includes(rank) ? "N/A" : rank;
  };

  const chartOptions = {
    chart: {
      id: "chart-id",
      type: "line",
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Date",
      },
      reversed: true,
    },
    yaxis: {
      title: {
        text: "Rank",
      },
      labels: {
        formatter: (value) => value.toFixed(0),
      },
      reversed: true,
      min: yAxisMin,
      max: yAxisMax,
    },
    colors: ["#2BA950"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
          .y === 101
          ? "-"
          : val.toString();
      },
      offsetX: 0,
      offsetY: -5,
      style: {
        fontSize: "14px",
        colors: ["#fff"],
        fontFamily: "Helvetica, Arial, sans-serif",
      },
      background: {
        enabled: true,
        foreColor: "#000",
        borderRadius: 2,
        padding: 3,
        borderColor: "#2BA950",
        borderRadius: 2,
      },
    },
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => new Date(row.date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Rank",
      selector: (row) => getDisplayRank(row.rank),
      sortable: true,
    },
  ];
  const columnsSERP = [
    {
      name: "Numbers",
      selector: (row) => row.id,
      width: "100px",
    },
    {
      name: "Domain Name",
      selector: (row) => row.domain,
      cell: (row) => (
        <div onClick={() => navigator.clipboard.writeText(row.url)} className="w-full ">

        <div
           // Copy full URL to clipboard
          className={`${row.domain.includes(projectDetails.url) ? "bg-yellow-200" : ""
            } text-mainColor-600 underline cursor-pointer w-fit m-auto`}
          title={row.url} // Shows the full URL on hover
        >
          {/* {/[ء-ي]/.test(row.url) ? row.url : (row.domain.includes("http") ? row.domain : `http://${row.domain}`)} */}
          <a
            href={row.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {extractDomainName(row.url)} {/* Display truncated domain name */}
          </a>
        </div>
        </div>
      ),
    },
  ];

  let currentProjects;
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  currentProjects = visibilityData.slice(
    indexOfFirstProject,
    indexOfLastProject
  );
  const totalPages = Math.ceil(visibilityData.length / projectsPerPage);
  console.log(currentProjects, visibilityData, 'currentProjects');

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Tabs
      value={activeTab}
      className="max-w-[100rem] mx-auto bg-white dark:bg-darkbg text-gray-950 dark:text-gray-300 "
    >
      <TabsHeader
        className="p-0 font-semibold bg-transparent border-b rounded-none border-mainColor-gray-50 "
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-mainColor shadow-none rounded-none",
        }}
      >
        <Tab key="tab1" value="tab1" onClick={() => setActiveTab("tab1")}>
          Rank Progress
        </Tab>
        <Tab key="tab2" value="tab2" onClick={() => setActiveTab("tab2")}>
          SERP Details
        </Tab>
        <Tab key="tab3" value="tab3" onClick={() => setActiveTab("tab3")}>
          Rank History
        </Tab>
      </TabsHeader>
      <TabsBody>
        <TabPanel key="tab1" value="tab1">
          <div className="flex justify-between ">
            <h2 className="text-left mr-[50px] mb-[-20px] font-semibold text-xl  text-gray-950 dark:text-gray-300">
              Latest rank :{" "}
              <span className="text-mainColor">
                {getDisplayRank(rowData?.rank)}
              </span>
            </h2>
            <h2 className="text-right mr-[50px] mb-[-20px] text-mainColor font-bold">
              {rowData?.query}
            </h2>
            <div className="mb-4">
              <select
                onChange={handleTimePeriodChange}
                value={timePeriod}
                className="px-6 border rounded "
              >
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </select>
            </div>
          </div>

          <div className="flex space-x-[20px] border rounded-md bg-baseColor dark:bg-darkbg text-gray-950 dark:text-gray-300 p-[10px] ">
            <Chart
              options={chartOptions}
              series={[{ data: seriesData }]}
              type="line"
              height={300}
              className={`w-[50%] flex items-center `}
            />
            <div className="w-[50%] mt-[9px] border-2  border-gray-200 dark:border-gray-500  rounded-md bg-white ">
              <DataTable
                className="dark:bg-darkbg text-gray-950 dark:text-gray-300 "
                columns={columns}
                data={filterDataByTimePeriod(tabData, timePeriod)}
                pagination
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel key="tab2" value="tab2">
          <div className="grid grid-cols-2 items-">
            <Serpanalysis rowData={rowData} />
            <div className="relative overflow-x-auto sm:rounded-lg px-4  rounded">
              {visibilityData.length === 0 ? (
                <>
                  <div>No data available</div>
                </>
              ) : (
                <div className="border-2  border-gray-200 dark:border-gray-500  rounded-md bg-white ">
                  <DataTable
                    className="dark:bg-darkbg text-gray-950 dark:text-gray-300 "
                    columns={columnsSERP}
                    data={visibilityData}
                    pagination
                    paginationPerPage={6}
                    paginationRowsPerPageOptions={[6, 10, 15, 20, 25]}
                  />
                  {/* <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 table-domain">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Numbers
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Domain Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentProjects.map((link, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <td className="px-6 py-3">{link.id}</td>
                          <td className="px-6 py-3">
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`${
                                link.domain.includes(projectDetails.url)
                                  ? "bg-yellow-200"
                                  : ""
                              } text-mainColor-600 underline`}
                            >
                              {link.domain}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
              )}
              {/* <PagePaginationProjects
                links={true}
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
              /> */}
            </div>
          </div>
        </TabPanel>
        <TabPanel key="tab3" value="tab3">
          <h1 className="mb-4 text-xl font-semibold text-gray-950 dark:text-gray-300">
            Rank History
          </h1>
          <table className="min-w-full bg-white border rounded-md">
            <thead className="bg-gray-50 dark:bg-darkbg">
              <tr>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase">
                  Period
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase">
                  Rank
                </th>
              </tr>
            </thead>
            <tbody>
              {historyData.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-6 py-4 whitespace-nowrap">{item.period}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {getDisplayRank(item.data)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TabPanel>
      </TabsBody>
    </Tabs>
  );
};

export default TabsProjects;
