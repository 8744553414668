
import DataTable from "react-data-table-component";
import {
  FaEllipsisV,
  FaTrash,
} from "react-icons/fa";
import { jsonrepair } from "jsonrepair"; // Import jsonrepair
import { useNavigate } from "react-router-dom"; // Import useHistory
import {  useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect, useRef } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { toast } from "react-toastify";
import LinkModal from "../../../../../components/Modal/LinkModal";
import SkeletonDataTable from "../../../../../components/Skeleton/SkeletonDataTable";
import Confirmation from "../../../../../components/Modal/Confirmation";
import SkeletonTabs from "../../../../../components/Skeleton/SkeletonTabs";

const MainContent = ({
  isLoading,
  isKeywordDataLoading,
  isUrlDataLoading,
  filteredLinks,
  columns,
  data,
  setData,
  keywordData,
  urlData,
  url_Data,
  setUrl_Data,
  columns_URL,
  RequestSuccess,
  isOpen,
  links,
  onClose,
  setShowDeleteButton,
  showDeleteButton,
  setSelectedRefreshLinks,
  selectedrefreshLinks,
  refreshLinks,
  isConfirmationrefreshOpen,
  setIsConfirmationRefreshOpen,
  isRefreshing,
  setIsRefreshing,
  setIsRefreshLoading,
  refreshloading,
  setIsRefreshingClose,
  isRefreshingClose,
  button_refresh,
  handleSubmit,
  update,
  set_button_refresh
}) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [responseLinksData, setResponseLinksData] = useState([]);
  const [rankAvgData, setRankAvgData] = useState([]); // State to handle rank_avg
  const [combinedH2H3DataArray, setCombinedH2H3DataArray] = useState([]); // State to store H2 and H3 combined data
  const [keywordSearchTerm, setKeywordSearchTerm] = useState("");
  const [urlSearchTerm, setUrlSearchTerm] = useState("");
  const [isKeywordDropdownOpen, setIsKeywordDropdownOpen] = useState(false);
  const [isUrlDropdownOpen, setIsUrlDropdownOpen] = useState(false);
  const [isKeywordConfirmationOpen, setIsKeywordConfirmationOpen] = useState(false);
  const [isUrlConfirmationOpen, setIsUrlConfirmationOpen] = useState(false);
  const [isDeletingAllKeywords, setIsDeletingAllKeywords] = useState(false);
  const [isDeletingAllUrls, setIsDeletingAllUrls] = useState(false);
  const navigate = useNavigate();
  const userId = useSelector((state) => state?.authSlice?.user.id);
  const filteredKeywordData = data.filter((item) =>
    item.query?.toLowerCase().includes(keywordSearchTerm)
  );
  const filteredUrlData = Array.isArray(url_Data)
    ? url_Data.filter((item) =>
      item.title?.toLowerCase().includes(urlSearchTerm)
    )
    : [];

  // Create refs for detecting clicks outside
  const keywordDropdownRef = useRef(null);
  const urlDropdownRef = useRef(null);
  const toggleKeywordDropdown = () => setIsKeywordDropdownOpen((prev) => !prev);
  const toggleUrlDropdown = () => setIsUrlDropdownOpen((prev) => !prev);
  const handleKeywordSearchChange = (e) => setKeywordSearchTerm(e.target.value.toLowerCase());
  const handleUrlSearchChange = (e) => setUrlSearchTerm(e.target.value.toLowerCase());

  // Close dropdowns when clicking outside

  const handleLinksSelection = (responseData) => {
    console.log("Original responseData:", responseData);
    navigate(`/detailskeyword/${responseData.query}`, {
      state: { responseData: responseData },
    });

    try {
      // Attempt to repair the main JSON structure
      const repairedData = JSON.parse(jsonrepair(JSON.stringify(responseData)));
      console.log("Repaired responseData:", repairedData);

      // Handle `rank_avg` data repair
      if (typeof repairedData.rank_avg === "string") {
        try {
          // Repair the nested JSON-like string inside `rank_avg`
          const repairedRankAvg = JSON.parse(jsonrepair(repairedData.rank_avg));
          console.log("Repaired rank_avg:", repairedRankAvg);

          // Set the `rank_avg` data to state
          setRankAvgData(repairedRankAvg);
          console.log('ggghgh')
          setShowDeleteButton(true);
        } catch (rankAvgError) {
          console.error("Error repairing nested rank_avg JSON:", rankAvgError);
        }
      } else if (Array.isArray(repairedData.rank_avg)) {
        // If `rank_avg` is already an array, set it directly
        setRankAvgData(repairedData.rank_avg);
      }

      // Handle `h2_count` data repair
      let h2Array = [];
      if (typeof repairedData.h2_count === "string") {
        try {
          // Repair the nested JSON-like string inside `h2_count`
          console.log(
            JSON.parse(jsonrepair(repairedData.h2_count)),
            Object.keys(JSON.parse(jsonrepair(repairedData.h2_count))),
            "jsonrepair"
          );

          const repairedH2Data = JSON.parse(jsonrepair(repairedData.h2_count));
          h2Array = Object.entries(repairedH2Data).flatMap(
            ([outerKey, counts]) =>
              Object.entries(counts).map(([keyword, count]) => ({
                keyword,
                count,
                type: "H2",
              }))
          );

          console.log("Repaired h2_count:", h2Array);
        } catch (h2Error) {
          console.error("Error repairing nested h2_count JSON:", h2Error);
        }
      }

      // Handle `h3_count` data repair
      let h3Array = [];
      if (typeof repairedData.h3_count === "string") {
        try {
          // Repair the nested JSON-like string inside `h3_count`
          const repairedH3Data = JSON.parse(jsonrepair(repairedData.h3_count));
          h3Array = Object.entries(repairedH3Data).flatMap(
            ([outerKey, counts]) =>
              Object.entries(counts).map(([keyword, count]) => ({
                keyword,
                count,
                type: "H3",
              }))
          );
          console.log("Repaired h3_count:", h3Array);
        } catch (h3Error) {
          console.error("Error repairing nested h3_count JSON:", h3Error);
        }
      }

      // Combine H2 and H3 data and set it to state
      const combinedData = [...h2Array, ...h3Array];
      setCombinedH2H3DataArray(combinedData);
      console.log(combinedH2H3DataArray, "combinedH2H3Columns");
      console.log("Combined H2 and H3 Data:", combinedData);

      // Handle `selected_results_sample` data repair
      if (typeof repairedData.selected_results_sample === "string") {
        try {
          // Repair the nested JSON-like string inside `selected_results_sample`
          const repairedSample = JSON.parse(
            jsonrepair(repairedData.selected_results_sample)
          );
          console.log("Repaired selected_results_sample:", repairedSample);

          // Update the repaired data with the parsed nested data
          repairedData.selected_results_sample = repairedSample.filtered_links;
          console.log(
            "Repaired selected_results_sample filtered_links:",
            repairedSample.filtered_links
          );

          // Set the state with the repaired filtered links
          setResponseLinksData(repairedSample.filtered_links);
        } catch (nestedError) {
          console.error(
            "Error repairing nested selected_results_sample JSON:",
            nestedError
          );
        }
      } else {
        // If `selected_results_sample` is not a string or JSON parsing is not needed
        setResponseLinksData(repairedData.selected_results_sample || []);
      }
    } catch (error) {
      console.error("Error repairing or parsing JSON:", error);
    }
  };

  const customTableStylesDark = {
    table: {
      style: {
        backgroundColor: "#111417",
        color: "#fff",
        borderColor: "#6b7280",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#111417",
        color: "#fff",
      },
    },
    headCells: {
      style: {
        color: "#fff",
        backgroundColor: "#111417",
      },
    },
    rows: {
      style: {
        backgroundColor: "#111417",
        color: "#fff",
        "&:nth-of-type(odd)": {
          backgroundColor: "#000",
        },
      },
    },
    pagination: {
      style: {
        backgroundColor: "#333",
        color: "#fff",
      },
    },
  };

  const customTableStylesLight = {
    table: {
      style: {
        backgroundColor: "#fff",
        color: "#000",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#fff",

        color: "#000",
      },
    },
    rows: {
      style: {
        backgroundColor: "#fff",
        color: "#000",
        "&:nth-of-type(odd)": {
          backgroundColor: "#f0f0f0",
        },
      },
    },
    pagination: {
      style: {
        backgroundColor: "#fff",
        color: "#000",
      },
    },
  };
  const isDarkMode = localStorage.getItem("darkMode") === "true";
  const customTableStyles = isDarkMode
    ? customTableStylesDark
    : customTableStylesLight;

  const handleDeleteAllKeywords = async () => {
    setIsDeletingAllKeywords(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/Delete-data-all/${userId}`,
        { method: "DELETE" }
      );

      if (response.ok) {
        toast.success("All keywords deleted successfully");
        setData([]); // Immediately clear the keyword data in the state
      } else {
        toast.error("Failed to delete all keywords");
      }
    } catch (error) {
      console.error("Error deleting all keywords:", error);
      toast.error("An error occurred while deleting all keywords.");
    } finally {
      setIsDeletingAllKeywords(false);
      setIsKeywordConfirmationOpen(false);
    }
  };


  const handleDeleteAllUrls = async () => {
    setIsDeletingAllUrls(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/Delete-url-all/${userId}`,
        { method: "DELETE" }
      );

      if (response.ok) {
        toast.success("All URLs deleted successfully");
        setUrl_Data([])
      } else {
        toast.error("Failed to delete all URLs");
      }
    } catch (error) {
      console.error("Error deleting all URLs:", error);
      toast.error("An error occurred while deleting all URLs.");
    } finally {
      setIsDeletingAllUrls(false);
      setIsUrlConfirmationOpen(false);
    }
  };

  const handleDeleteAllKeywordsClick = () => {
    setIsKeywordConfirmationOpen(true);
  };

  const handleDeleteAllUrlsClick = () => {
    setIsUrlConfirmationOpen(true);
  };

  const handleCloseKeywordConfirmation = () => {
    setIsKeywordConfirmationOpen(false);
  };

  const handleCloseUrlConfirmation = () => {
    setIsUrlConfirmationOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        keywordDropdownRef.current &&
        !keywordDropdownRef.current.contains(event.target)
      ) {
        setIsKeywordDropdownOpen(false);
      }
      if (
        urlDropdownRef.current &&
        !urlDropdownRef.current.contains(event.target)
      ) {
        setIsUrlDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>

      {isOpen && links && (
        <>
          <LinkModal
            update={update}
            isOpen={isOpen}
            onClose={onClose}
            links={links}
            filteredLinks={filteredLinks}
            query={keywordData.Query ? keywordData.Query : keywordData}
            onSelectionComplete={(selected) => handleLinksSelection(selected)}
            showDeleteButton={showDeleteButton}
            refreshLinks={() => { refreshLinks(keywordData.Query ? keywordData.Query : keywordData) }}
            button_refresh={button_refresh}
            selectedrefreshLinks={selectedrefreshLinks}
            setSelectedRefreshLinks={setSelectedRefreshLinks}
            isConfirmationrefreshOpen={isConfirmationrefreshOpen}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
            setIsConfirmationRefreshOpen={setIsConfirmationRefreshOpen}
            setIsRefreshLoading={setIsRefreshLoading}
            refreshloading={refreshloading}
            setIsRefreshingClose={setIsRefreshingClose}
            isRefreshingClose={isRefreshingClose}
            handleSubmit={handleSubmit}
            set_button_refresh={set_button_refresh}
          />
        </>
      )}


      {isLoading && !RequestSuccess ? (
        <SkeletonTabs />
      ) : (
        <Tabs
          value={activeTab}
          className="max-w-screen-3xl mx-auto py-4 md:mt-10 mt-16 "
        >
          <TabsHeader
            className="rounded-none border-b border-mainColor-gray-50 font-semibold bg-transparent p-0 dark:text-gray-300"
            indicatorProps={{
              className:
                "bg-transparent border-b-2 border-mainColor shadow-none rounded-none",
            }}
          >
            <Tab key="tab1" value="tab1" onClick={() => setActiveTab("tab1")}>
              Keyword
            </Tab>
            <Tab key="tab2" value="tab2" onClick={() => setActiveTab("tab2")}>
              URL
            </Tab>
          </TabsHeader>
          <TabsBody>
            <TabPanel key="tab1" value="tab1">
              {data.length > 0 && (
                <div className="flex justify-between items-center mb-4">
                  {/* Search Input on the left */}
                  <input
                    type="text"
                    placeholder="Search Keywords"
                    value={keywordSearchTerm}
                    onChange={handleKeywordSearchChange}
                    className="p-2 border rounded-md w-[50%] focus:outline-none"
                  />

                  {/* Icon menu on the right */}
                  <div className="relative" ref={keywordDropdownRef}>
                    <button
                      onClick={toggleKeywordDropdown}
                      className="px-2 py-1 font-semibold text-gray-500 rounded-md flex items-center"
                    >
                      <FaEllipsisV className="ml-1" />
                    </button>

                    {isKeywordDropdownOpen && (
                      <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md z-10">
                        <ul className="py-1">
                          <li
                            className="px-4 py-2 cursor-pointer flex items-center"
                            onClick={() => {
                              handleDeleteAllKeywordsClick();
                              setIsKeywordDropdownOpen(false);
                            }}
                          >
                            <FaTrash className="mr-2" /> Delete All Keywords
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* DataTable Component */}
              <div className="mt-4 overflow-x-auto border-2 border-gray-200 dark:border-gray-500 rounded-md">
                {isKeywordDataLoading && !RequestSuccess ? (
                  <SkeletonDataTable />
                ) : (
                  <DataTable
                    columns={columns}
                    data={filteredKeywordData}
                    progressPending={isKeywordDataLoading}
                    pagination
                    customStyles={customTableStyles}
                  />
                )}
              </div>
            </TabPanel>

            <TabPanel key="tab2" value="tab2">


              {url_Data.length > 0 && (
                <div className="flex justify-between items-center mb-4">

                  <input
                    type="text"
                    placeholder="Search URLs"
                    value={urlSearchTerm}
                    onChange={handleUrlSearchChange}
                    className="p-2 border rounded-md w-[50%] focus:outline-none"
                  />
                  <div className="relative" ref={urlDropdownRef}>


                    <button
                      onClick={toggleUrlDropdown}
                      className="px-2 py-1 font-semibold text-gray-500 rounded-md flex items-center"
                    >
                      <FaEllipsisV className="ml-1" />
                    </button>
                    {isUrlDropdownOpen && (
                      <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md  z-10">
                        <ul className="py-1">
                          <li
                            className="px-4 py-2 cursor-pointer flex items-center"
                            onClick={() => {
                              handleDeleteAllUrlsClick();
                              setIsUrlDropdownOpen(false);
                            }}
                          >
                            <FaTrash className="mr-2" /> Delete All URLs
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="mt-4 overflow-x-auto border-2 border-gray-200 dark:border-gray-500 rounded-md">
                {isUrlDataLoading && !RequestSuccess ? (
                  <SkeletonDataTable />
                ) : (
                  <DataTable
                    columns={columns_URL}
                    data={filteredUrlData}
                    progressPending={isUrlDataLoading}
                    pagination
                    customStyles={customTableStyles}
                  />
                )}
              </div>
            </TabPanel>
          </TabsBody>
        </Tabs>
      )}


      <Confirmation
        isOpen={isKeywordConfirmationOpen}
        onClose={() => setIsKeywordConfirmationOpen(false)}
        onConfirm={handleDeleteAllKeywords}
        message="Are you sure you want to delete all keywords?"
        isDeleting={isDeletingAllKeywords}
      />
      <Confirmation
        isOpen={isUrlConfirmationOpen}
        onClose={() => setIsUrlConfirmationOpen(false)}
        onConfirm={handleDeleteAllUrls}
        message="Are you sure you want to delete all URLs?"
        isDeleting={isDeletingAllUrls}
      />
    </>
  );
};
export default MainContent;
