import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import EGYPT from "../assets/images/EGYPT.png";
import { fetchData } from "../redux/lib/fetchData";
import SkeletonCard from "./Skeleton/SkeletonCard";
import FilterSkeleton from "./Skeleton/FilterSkeleton";
import {
  ProjectHeader,
  FilterComponent,
  RankTable,
  PaginationControls,
  OperationComplete,
  RankModal,
} from "../components";
import { useNavigate } from "react-router-dom";
import { MdOutlineFeedback } from "react-icons/md";

import {
  escapeRegExp,
  extractDomainName,
  normalizeUrl,
} from "../utils/Functions";
import TableHeaderControls from "./MainRank/HeaderControl/TableHeaderControls";
import chartOptions from "./Chart/chartOptions";
import customSelectStyles from "./CustomStyling/customSelectStyles";
import {
  useIsSmallScreen,
  useFetchData,
  useDeleteOperations,
  useEditQuery,
  usePagination,
  useBookmarks,
  useExportToExcel,
  useAddQuery,
  useFetchChartData,
  useSort,
  useFilters,
} from "../hook";
import BookmarkDetails from "./Bookmark/BookmarkDetails";
import ConfirmationModals from "./MainRank/ConfirmationModals";
import useWebSocket from "../hook/useWebSocket";
import * as XLSX from "xlsx";
import createLogQuery from "../pages/SidebarPages/Log/createLogQuiries";
import { Button } from "@material-tailwind/react";
import DateFilter from "./DateFilter";
import { setProjectCompetitors } from "../redux/lib/projectCompetitors";
import ConfirmationUpdate from "./Modal/ConfirmationUpdate";
import Confirmation_Update from "./Modal/Confirmation_Update";
import { selectAuthDetails, selectOriginalUserName } from "../redux/selectors";
// import { decrementDailyQuota } from "../redux/lib/auth";
import QuotaModal from "./Modal/QuotaModal";
const Projectranks = ({ processComplete, pin_project, setPin_project }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const bookmarks = useSelector(
    (state) => state.displayBookmarkSlice.bookmarks
  );
  const remainingQuota = useSelector((state) => state.authSlice.dailyQuota);

  const [isQuota, setisQuota] = useState(false);
  const roles = useSelector((state) => state.roleSlice.roles);
  // const NameUser = useSelector((state) => state.authSlice.user.name);
  // const userId = useSelector((state) => state.authSlice.user.id);

  // const managed = useSelector((state) => state.authSlice.managed);
  const { userId, managed, NameUser, verified } =
    useSelector(selectAuthDetails);
  const originalUser = useSelector(selectOriginalUserName);
  const dailyQuota = useSelector((state) => state.authSlice.dailyQuota);

  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  // states
  // const originalUser = useSelector((state) => state.originalUserSlice.name);

  const [selectedBookmark, setSelectedBookmark] = useState(null);
  const [favoritesData, setFavoritesData] = useState([]);
  const [selectedBookmarkName, setSelectedBookmarkName] = useState("");
  const [abortControllers, setAbortControllers] = useState([]);
  const [csrfToken, setCsrfToken] = useState("");
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [bookmarkNames, setBookmarkNames] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const projectDetails = location.state?.projectDetails;
  const [isFolderSelected, setIsFolderSelected] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [show_BookmarkSelector, setShow_BookmarkSelector] = useState(false);
  const [selectedBookmarkId, setSelectedBookmarkId] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  //update
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationData, setConfirmationData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [isConfirmation_Open, setIsConfirmation_Open] = useState(false);
  const [confirmation_Data, setConfirmation_Data] = useState({});
  const [is_Updating, setIs_Updating] = useState(false);

  const [completedUpdates, setCompletedUpdates] = useState(0);
  const [updateErrors, setUpdateErrors] = useState(0);
  const [totalUpdates, setTotalUpdates] = useState(0);
  const [completedAdds, setCompletedAdds] = useState(0);
  const [addErrors, setAddErrors] = useState(0);
  const [totalAdds, setTotalAdds] = useState(0);

  const [showAddProgressBar, setShowAddProgressBar] = useState(false);
  const [isCreatingBookmark, setIsCreatingBookmark] = useState(false);
  const [isModal_Open, setIsModal_Open] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    value: "EG",
    label: "Egypt",
    icon: <img src={EGYPT} alt="Egypt" />,
  });
  const [isDelete, setIsDelete] = useState(false);
  const [totalDeletes, setTotalDeletes] = useState(0);
  const [completeDeletes, setCompleteDeletes] = useState(0);

  const socketRef = useRef(null);
  const [updatingRows, setUpdatingRows] = useState(new Set());
  const [rowsInBulkUpdate, setRowsInBulkUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const [customDateChart, setCustomDateChart] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rankLinks, setRankLinks] = useState([]);
  const [filteredByRank, setFilteredByRank] = useState([]);
  // get largest projects
  const [largestProjects, setLargestProjects] = useState({
    name: "Project A",
    description: "Description of Project A",
  });
  // hooks
  // const { sendMessage } = useWebSocket(
  //   `wss://${process.env.REACT_APP_API_URL_WS}/ws`
  // );
  const {
    editingQueryId,
    tempQueryName,
    isEditInProgress,
    editProgress,
    handleEditClick,
    handleQueryNameChange,
    submitNewQueryName,
    setEditingQueryId,
    editError,
    setEditProgress,
  } = useEditQuery(userId, projectId);
  const { exportToExcel } = useExportToExcel(setIsLoading);
  const { loading, tableData, detailData, is_Loading, notFound } = useFetchData(
    userId,
    projectId,
    selectedBookmark?.value,
    processComplete
  );
  const [tableFilteredDate, setTableFilteredDate] = useState([]);
  const {
    filteredData,
    setFilteredData,

    setSelectedLocationFilter,
    searchQuery,
    handleSearchInputChange,
    selectedLocationFilter,
    handleLocationChange,
    selectedTargetUrl,
    handleTargetUrlFilterChange,
    rankRange,
    handleRangeChange,
    hasFiltersApplied,
    handleResetFilters,
  } = useFilters(
    tableData,
    detailData,
    selectedBookmark,
    setSelectedBookmark,
    loading,
    setSelectedBookmarkId,
    tableFilteredDate,
    setTableFilteredDate
  );

  const {
    currentPage,
    currentRows,
    totalPages,
    paginate,
    setRowsPerPage,
    setCurrentPage,
    setCurrentRows,
    rowsPerPage,
    resetToFirstPage
    
  } = usePagination(tableFilteredDate, 10);
  const { isDataLoading, onBulkSubmit } = useAddQuery(
    userId,
    projectId,
    managed,
    roles,
    projectDetails,
    setFilteredData,
    isFolderSelected,
    selectedBookmarkId,
    selectedLocation,
    completedAdds,
    setCompletedAdds,
    addErrors,
    setAddErrors,
    showAddProgressBar,
    setShowAddProgressBar,
    totalAdds,
    setTotalAdds,
    pin_project,
    setPin_project
  );

  const {
    isDialogOpen,
    isBulkDeleteDialogOpen,
    handleDeleteButtonClick,
    confirmDeletion,
    cancelDeletion,
    handleBulkDelete,
    handleDeleteConfirmed,
    handleCancelDeletion,
    checkedRows,
    setCheckedRows,
    isDeleting,
  } = useDeleteOperations(
    userId,
    projectId,
    setFilteredData,
    setTotalDeletes,
    setCompleteDeletes,
    setIsDelete,
    pin_project,
    setPin_project,
    setCurrentRows
  );
  const ProjectName = projectDetails?.name;
  const seriesData = useFetchChartData(
    userId,
    projectId,
    filteredData,
    customDateChart,
    startDate,
    endDate
  );
console.log(seriesData,'seriesDataseriesData');

  useEffect(()=>{
    setTableFilteredDate(filteredData)
  },[filteredData])
  // useEffect(() => {
  //   // Store progress bar visibility state in session storage
  //   sessionStorage.setItem("showProgressBar", JSON.stringify(showProgressBar));
  // }, [showProgressBar]);

  // functions
  const minProjectRank = async () => {
    let queryIds = [];
    let allData = [];
    filteredData.map((data) => {
      queryIds.push(encodeURIComponent(data.query_id));
    });

    queryIds.map(async (queryId, index) => {
      if (index <= rowsPerPage) {
        const url = `${process.env.REACT_APP_API_URL}/api/display-project-ranks/${userId}/${queryId}/${projectId}/`;

        try {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            allData.push(data);
          } else {
            console.error("Error fetching data:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
  };
  const handleExportToExcel = () => {
    if (checkedRows.length > 0 && checkedRows.length !== tableData.length) {
      setIsLoading(true);
      const selectedData = filteredData.filter((val) =>
        checkedRows.includes(val.query_id)
      );

      const worksheet = XLSX.utils.json_to_sheet(selectedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredKeywords");

      const fileName = `FilteredKeywords_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/:/g, "-")}.xlsx`;

      XLSX.writeFile(workbook, fileName);
      setIsLoading(false);
    } else {
      exportToExcel(userId, projectId, setIsLoading);
    }
  };
  const bookmarkOptions = bookmarks.map((bookmark) => ({
    value: bookmark.b_id, // Assuming each bookmark has a unique `id`
    label: bookmark.name, // And a `name` you want to display
  }));
  const handleBookmarkChange = async (selectedOption) => {
    if (!selectedOption) {
      setFilteredData(tableData); // Reset to all data if no bookmark is selected
      setIsFolderSelected(false);
      setSelectedBookmark(null); // Reset selected bookmark
      setCurrentPage(1); // Reset pagination to the first page
      handleResetFilters(); // Reset filters to show all data

      return;
    }

    setSelectedBookmark(selectedOption);
    setSelectedBookmarkId(selectedOption.value); // Update the selected bookmark ID
    setSelectedBookmarkName(selectedOption.label); // Update the selected bookmark name based on the label

    // Perform the API call to fetch data based on the selected bookmark
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/DisplayFavos/${userId}/${selectedOption.value}/`;
    try {
      const response = await axios.get(apiUrl);
      const favoritesData = response.data;
      const rankData = favoritesData.map((favourite) => favourite.rank);
      setFilteredData(rankData);
      setIsFolderSelected(true);
      setFavoritesData(rankData.length); // Update favoritesData state
    } catch (error) {
      toast.error(
        "Failed to fetch data for the selected bookmark. Please try again."
      );
    }
    setSelectedRows([]);
    setCheckedRows([]);
  };
  const toggleBookmarkSelector = () => {
    setShow_BookmarkSelector((prevShow) => !prevShow);
  };
  const open_Modal = () => setIsModal_Open(true);
  const close_Modal = () => setIsModal_Open(false);

  const uniqueTargetUrls = [
    "All Sources",
    ...new Set(
      tableData.map((item) =>
        item.target_url
          .replace(/^(https?:\/\/)?(www\.)?/, "")
          .replace(/\/$/, "")
      )
    ),
  ];

  const handleSingleUpdateButtonClick = async (
    event,
    userId,
    query_id,
    project_id
  ) => {
    event.stopPropagation();
    if (!query_id) {
      toast.error("Query ID is missing!");
      return;
    }
    console.log("Single update button clicked");

    // Prevent multiple updates by checking if the update process is already ongoing
    if (is_Updating) {
      toast.info("Please wait until the current update is finished.");
      return;
    }

    setConfirmationData({ userId, query_id, project_id, numberOfKeywords: 1 });
    setIsConfirmation_Open(true);
  };

  // const handleUpdateConfirm = async () => {
  //   const { userId, query_id, project_id } = confirmationData;
  //   setIs_Updating(true);
  //   setIsConfirmation_Open(false);

  //   setTotalUpdates((prev) => prev + 1);
  //   setShowProgressBar(true);

  //   let requestSuccessful = false;
  //   const retryLimit = 10;
  //   let retryCount = 0;

  //   const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  //   const makeRequest = async () => {
  //     if (requestSuccessful) return;

  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/api/update-rank/${userId}/${query_id}/${project_id}/`,
  //         {},
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
  //         setFilteredData((currentData) => {
  //           const updatedItemIndex = currentData.findIndex(
  //             (item) => item.query_id === query_id
  //           );
  //           const updatedQuery = currentData.filter(
  //             (item) => item.query_id === query_id
  //           );
  //           let newRank = updatedQuery[0].query;
  //           sessionStorage.setItem("rank", JSON.stringify(newRank));
  //           const updatedItem = {
  //             ...currentData[updatedItemIndex],
  //             ...response.data,
  //           };
  //           const newData = [
  //             updatedItem,
  //             ...currentData.slice(0, updatedItemIndex),
  //             ...currentData.slice(updatedItemIndex + 1),
  //           ];
  //           return newData;
  //         });
          
  //         setCompletedUpdates((prev) => prev + 1);
  //         toast.success(`${response.data.query} update successful`);

  //         const name = managed ? originalUser : NameUser;
  //         const domainName = extractDomainName(response.data.target_url);
  //         const logMessage = `Query: ${response.data.query} Updated with Target_url : ${domainName} in project ${ProjectName} and it's Rank ${response.data.query} - ${ProjectName} by ${name}`;
  //         createLogQuery(logMessage, userId);

  //         requestSuccessful = true;
  //       } else {
  //         throw new Error("Update failed");
  //       }
  //     } catch (error) {
  //       console.error("Error updating rank:", error);
  //       if (error.message === "canceled" && retryCount < retryLimit) {
  //         retryCount += 1;
  //         await wait(2000 * retryCount);
  //         await makeRequest();
  //       } else {
  //         toast.error(
  //           `An error occurred while updating the rank. Error status: ${
  //             error.response ? error.message : "unknown"
  //           }`
  //         );
  //         setUpdateErrors((prevErrors) => prevErrors + 1);
  //       }
  //     }
  //   };

  //   await makeRequest();
  //   setIs_Updating(false);
  //   setIsConfirmation_Open(false);
  // };
  
   // Update filteredData
          // setFilteredData((currentData) => {
          //   const updatedItemIndex = currentData.findIndex(
          //     (item) => item.query_id === query_id
          //   );
          //   const updatedItem = {
          //     ...currentData[updatedItemIndex],
          //     ...response.data,
          //   };
          //   const newData = [
          //     updatedItem,
          //     ...currentData.slice(0, updatedItemIndex),
          //     ...currentData.slice(updatedItemIndex + 1),
          //   ];
          //   return newData;
          // });
  
          // // Update currentRows similarly
          // setCurrentRows((currentRowsData) => {
          //   const updatedItemIndex = currentRowsData.findIndex(
          //     (item) => item.query_id === query_id
          //   );
          //   if (updatedItemIndex !== -1) {
          //     const updatedItem = {
          //       ...currentRowsData[updatedItemIndex],
          //       ...response.data,
          //     };
          //     const newData = [
          //       updatedItem,
          //       ...currentRowsData.slice(0, updatedItemIndex),
          //       ...currentRowsData.slice(updatedItemIndex + 1),
          //     ];
          //     return newData;
          //   }
          //   return currentRowsData;
          // });
  // const handleUpdateConfirm = async () => {
  //   const { userId, query_id, project_id } = confirmationData;
  //   setIs_Updating(true);
  //   setIsConfirmation_Open(false);
  
  //   setTotalUpdates((prev) => prev + 1);
  //   setShowProgressBar(true);
  
  //   let requestSuccessful = false;
  //   const retryLimit = 10;
  //   let retryCount = 0;
  
  //   const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  //   const makeRequest = async () => {
  //     if (requestSuccessful) return;
  
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/api/update-rank/${userId}/${query_id}/${project_id}/`,
  //         {},
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
         
  //         setFilteredData((currentData) => {
  //           const updatedItemIndex = currentData.findIndex(
  //             (item) => item.query_id === query_id
  //           );
  
  //           // If the item isn't found, return currentData without changes
  //           if (updatedItemIndex === -1) return currentData;
  
  //           const updatedQuery = currentData[updatedItemIndex];
  //           const updatedItem = { ...updatedQuery, ...response.data };
  
  //           // Remove the updated item from its current position and place it at the top
  //           const newData = [
  //             updatedItem, // Move the updated item to the top
  //             ...currentData.slice(0, updatedItemIndex),
  //             ...currentData.slice(updatedItemIndex + 1),
  //           ];
  
  //           // Update currentRows to reflect the first page of the updated list
  //           setCurrentRows(newData.slice(0, rowsPerPage));
  //           console.log(currentRows,"setCurrentRows")
  //           setCurrentPage(1); // Set to the first page to ensure visibility
  
  //           return newData; // Update the filtered data state
  //         });
          

  //         setCompletedUpdates((prev) => prev + 1);
  //         toast.success(`${response.data.query} update successful`);
  
  //         const name = managed ? originalUser : NameUser;
  //         const domainName = extractDomainName(response.data.target_url);
  //         const logMessage = `Query: ${response.data.query} Updated with Target_url : ${domainName} in project ${ProjectName} and it's Rank ${response.data.query} - ${ProjectName} by ${name}`;
  //         createLogQuery(logMessage, userId);
  
  //         requestSuccessful = true;
  //       } else {
  //         throw new Error("Update failed");
  //       }
  //     } catch (error) {
  //       console.error("Error updating rank:", error);
  //       if (error.message === "canceled" && retryCount < retryLimit) {
  //         retryCount += 1;
  //         await wait(2000 * retryCount);
  //         await makeRequest();
  //       } else {
  //         toast.error(
  //           `An error occurred while updating the rank. Error status: ${
  //             error.response ? error.message : "unknown"
  //           }`
  //         );
  //         setUpdateErrors((prevErrors) => prevErrors + 1);
  //       }
  //     }
  //   };
  
  //   await makeRequest();
  //   setIs_Updating(false);
  //   setIsConfirmation_Open(false);
  // };



  const handleUpdateConfirm = async () => {
    const { userId, query_id, project_id } = confirmationData;
    setIs_Updating(true);
    setIsConfirmation_Open(false);
  
    setTotalUpdates((prev) => prev + 1);
    // sessionStorage.clear();
    setShowProgressBar(true);
  
    let requestSuccessful = false;
    const retryLimit = 10;
    let retryCount = 0;
  
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    const makeRequest = async () => {
      if (requestSuccessful) return;
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/update-rank/${userId}/${query_id}/${project_id}/`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.status === 200) {
          const updatedData = response.data;
  
          // Update the filteredData state
          setFilteredData((currentData) => {
            const updatedItemIndex = currentData.findIndex(
              (item) => item.query_id === query_id
            );
  
            if (updatedItemIndex === -1) return currentData;
  
            const updatedItem = { ...currentData[updatedItemIndex], ...updatedData };
  
            // Move the updated item to the top
            const newData = [
              updatedItem,
              ...currentData.slice(0, updatedItemIndex),
              ...currentData.slice(updatedItemIndex + 1),
            ];
  
            // Reset pagination to the first page and update the displayed rows
            // Update currentRows using the new filtered data
            // setCurrentRows(newData.slice(0, rowsPerPage));
            console.log(currentRows,"kkkiki")

            return newData; // Update the filteredData state
          });
          resetToFirstPage();

          setCompletedUpdates((prev) => prev + 1);
          toast.success(`${response.data.query} update successful`);
  
          const name = managed ? originalUser : NameUser;
          const domainName = extractDomainName(updatedData.target_url);
          const logMessage = `Query: ${updatedData.query} Updated with Target_url: ${domainName} in project ${ProjectName} and its Rank ${updatedData.rank} - ${ProjectName} by ${name}`;
          createLogQuery(logMessage, userId);
  
          requestSuccessful = true;
        } else {
          throw new Error("Update failed");
        }
      } catch (error) {
        console.error("Error updating rank:", error);
        if (error.message === "canceled" && retryCount < retryLimit) {
          retryCount += 1;
          await wait(2000 * retryCount);
          await makeRequest();
        } else {
          toast.error(
            `An error occurred while updating the rank. Error status: ${
              error.response ? error.message : "unknown"
            }`
          );
          setUpdateErrors((prevErrors) => prevErrors + 1);
        }
      }
    };
  
    await makeRequest();
    setIs_Updating(false);
    setIsConfirmation_Open(false);
  };
  


  
  const handleBulkUpdateButtonClick = () => {
    setConfirmationData({
      userId,
      projectId,
      numberOfKeywords: checkedRows.length,
    });
    setIsConfirmationOpen(true);
    sessionStorage.setItem("test", "test");
    sessionStorage.clear();

  };
  const handleUpdateButtonClick = async (
    event,
    userId,
    query_id,
    project_id,
    controller,
    isSmallScreen
  ) => {
    event.stopPropagation();
    let requestSuccessful = false;
    const retryLimit = 10;
    let retryCount = 0;
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    const makeRequest = async () => {
      if (requestSuccessful || controller.signal.aborted) return;
      try {
        setUpdatingRows((prev) => ({ ...prev, [query_id]: true }));
  
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/update-rank/${userId}/${query_id}/${project_id}/`,
          {},
          {
            signal: controller.signal,
          }
        );
  
        if (response.status === 200) {
          console.log("Response from API:", response.data);
  
          setFilteredData((currentData) => {
            const updatedItemIndex = currentData.findIndex(
              (item) => item.query_id === query_id
            );
            if (updatedItemIndex === -1) return currentData;
  
            // Move the updated item to the top
            const updatedItem = { ...currentData[updatedItemIndex], ...response.data };
            const newData = [
              updatedItem, 
              ...currentData.slice(0, updatedItemIndex), 
              ...currentData.slice(updatedItemIndex + 1)
            ];
  

  
            // Return the new filtered data
            return newData;
          });
          resetToFirstPage();

          const name = managed ? originalUser : NameUser;
          const domainName = extractDomainName(response.data.target_url);
          const logMessage = `Query: ${response.data.query} Updated with Target_url : ${domainName} in project ${ProjectName} and it's Rank ${response.data.query} - ${ProjectName} by ${name}`;
          console.log("Log Message:", logMessage);
          createLogQuery(logMessage, userId);
  
          setCompletedUpdates((prev) => prev + 1);
          requestSuccessful = true;
        }
      } catch (error) {
        console.error("Error during API request:", error);
        if (error.name === "AbortError") {
          console.log("Request was aborted.");
        } else {
          if (retryCount < retryLimit) {
            retryCount += 1;
            if (error.response.status === 404) {
              setTotalUpdates((prev) => prev - 1);
            } else {
              await wait(2000 * retryCount);
              await makeRequest();
            }
          } else {
            if (!isSmallScreen)
              toast.error(
                `An error occurred while updating the rank. Error status: ${
                  error.response ? error.message : "unknown"
                }`
              );
            setUpdateErrors((prevErrors) => prevErrors + 1);
          }
        }
      }
    };
  
    await makeRequest();
  };
  

  // const handleBulkUpdate = async () => {
  //   console.log("updated")
  //   setIsConfirmationOpen(false); // Close the confirmation modal when proceeding
  //   setRowsInBulkUpdate(true);
  //   let idCounter = 0;
  //   const controllers = checkedRows.map(() => {
  //     idCounter += 1;
  //     const controller = new AbortController();
  //     controller.signal.id = idCounter++;
  //     return controller;
  //   });
  //   setAbortControllers(controllers);
  //   // sendMessage(userId, "Updated Queries");
  //   setIsUpdateInProgress(true); // Set the update in progress state to true

  //   const total = checkedRows.length;
  //   if (!userId || !projectId || total === 0) {
  //     console.error(
  //       "Missing userId, projectId, or no rows selected for bulk update."
  //     );
  //     setIsUpdateInProgress(false); // Set the update in progress state to false
  //     return;
  //   }

  //   setTotalUpdates((prev) => prev + total);
  //   setShowProgressBar(true);

  //   const chunkArray = (array, chunkSize) => {
  //     const chunks = [];
  //     for (let i = 0; i < array.length; i += chunkSize) {
  //       chunks.push(array.slice(i, i + chunkSize));
  //     }
  //     return chunks;
  //   };
  //   const queryChunks = chunkArray(checkedRows, 10);
  //   const updatePromises = checkedRows.map((queryId, index) =>
  //     handleUpdateButtonClick(
  //       { stopPropagation: () => {} },
  //       userId,
  //       queryId,
  //       projectId,
  //       controllers[index],
  //       isSmallScreen
  //     )
  //   );

  //   try {
  //     // for (const chunk of queryChunks) {
  //     //   const updatePromises = chunk.map((query_id, index) => {
  //     //     console.log(query_id, "updatePromises");

  //     //     handleUpdateButtonClick(
  //     //       { stopPropagation: () => {} },
  //     //       userId,
  //     //       query_id,
  //     //       projectId,
  //     //       controllers[index],
  //     //       isSmallScreen
  //     //     );
  //     //   });
  //     await Promise.all(updatePromises);
  //     // }
  //   } catch (error) {
  //     if (error.name !== "AbortError") {
  //       console.error("Error in bulk update:", error);
  //     }
  //   }

  //   setCheckedRows([]);
  //   setIsUpdateInProgress(false); // Set the update in progress state to false
  //   setAbortControllers([]);
  //   // Show a summary notification only on large screens
  //   let rank = JSON.parse(sessionStorage.getItem("rank")) || [];

  //   if (!isSmallScreen) {
  //     //setCompletedUpdates(0),
  //     const successMessage = `Successfully updated ${rank.length + 1} queries.`;
  //     setTimeout(() => sessionStorage.clear(), 3500);
  //     const errorMessage =
  //       updateErrors > 0 ? `Failed to update ${updateErrors} queries.` : "";
  //     // toast.success(successMessage);
  //     // setTotalUpdates(0)
  //     if (errorMessage) toast.error(errorMessage);
  //   }
  //   setRowsInBulkUpdate(false);
  //   // setUpdatingRows((prev) => ({ ...prev, [query_id]: true }));
  //   // console.log(updatingRows,'updatingRows');
  //   setUpdatingRows((prev) => {
  //     const newState = {};
  //     for (const key in prev) {
  //       if (Object.prototype.hasOwnProperty.call(prev, key)) {
  //         newState[key] = false;
  //       }
  //     }
  //     return newState;
  //   });
  // };
// without try request
  // const handleBulkUpdate = async () => {
  //   console.log("updated");
  //   setIsConfirmationOpen(false); // Close the confirmation modal when proceeding
  //   setRowsInBulkUpdate(true);
  
  //   let idCounter = 0;
  //   const controllers = checkedRows.map(() => {
  //     idCounter += 1;
  //     const controller = new AbortController();
  //     controller.signal.id = idCounter++;
  //     return controller;
  //   });
  //   setAbortControllers(controllers);
  //   setIsUpdateInProgress(true); // Set the update in progress state to true
  
  //   const total = checkedRows.length;
  //   if (!userId || !projectId || total === 0) {
  //     console.error(
  //       "Missing userId, projectId, or no rows selected for bulk update."
  //     );
  //     setIsUpdateInProgress(false);
  //     return;
  //   }
  
  //   setTotalUpdates((prev) => prev + total);
  //   setShowProgressBar(true);
  
  //   const chunkArray = (array, chunkSize) => {
  //     const chunks = [];
  //     for (let i = 0; i < array.length; i += chunkSize) {
  //       chunks.push(array.slice(i, i + chunkSize));
  //     }
  //     return chunks;
  //   };
  
  //   const queryChunks = chunkArray(checkedRows, 5);
  
  //   try {
  //     for (const chunk of queryChunks) {
  //       const updatePromises = chunk.map((queryId, index) =>
  //         handleUpdateButtonClick(
  //           { stopPropagation: () => {} },
  //           userId,
  //           queryId,
  //           projectId,
  //           controllers[index],
  //           isSmallScreen
  //         )
  //       );
  
  //       // Await the resolution of all update requests for the current chunk
  //       await Promise.all(updatePromises);
  //     }
  
  //     // Once all chunks are processed, show success toast
  //     toast.success(`Successfully updated all ${total} queries `);
      
     
      
  //     // Handle post-update tasks such as quota reduction
  //     setisQuota(true);
  
  //   } catch (error) {
  //     if (error.name !== "AbortError") {
  //       console.error("Error in bulk update:", error);
  //     }
  //   }
  
  //   setCheckedRows([]);
  //   setIsUpdateInProgress(false); // Set the update in progress state to false
  //   setAbortControllers([]);
  
  //   let rank = JSON.parse(sessionStorage.getItem("rank")) || [];
  
  //   if (!isSmallScreen) {
  //     const successMessage = `Successfully updated ${rank.length + 1} queries.`;
  //     setTimeout(() => sessionStorage.clear(), 3500);
  //     const errorMessage =
  //       updateErrors > 0 ? `Failed to update ${updateErrors} queries.` : "";
  //     if (errorMessage) toast.error(errorMessage);
  //   }
  
  //   setRowsInBulkUpdate(false);
  //   setUpdatingRows((prev) => {
  //     const newState = {};
  //     for (const key in prev) {
  //       if (Object.prototype.hasOwnProperty.call(prev, key)) {
  //         newState[key] = false;
  //       }
  //     }
  //     return newState;
  //   });
  // };
  
//with try reuest

const handleBulkUpdate = async () => {
  console.log("Bulk update started");
  setIsConfirmationOpen(false); // Close the confirmation modal when proceeding
  setRowsInBulkUpdate(true);

  let idCounter = 0;
  const controllers = checkedRows.map(() => {
    idCounter += 1;
    const controller = new AbortController();
    controller.signal.id = idCounter++;
    return controller;
  });
  setAbortControllers(controllers);
  setIsUpdateInProgress(true); // Set the update in progress state to true

  const total = checkedRows.length;
  if (!userId || !projectId || total === 0) {
    console.error(
      "Missing userId, projectId, or no rows selected for bulk update."
    );
    setIsUpdateInProgress(false);
    return;
  }

  setTotalUpdates((prev) => prev + total);
  setShowProgressBar(true);

  const retryLimit = 5; // Maximum number of retries
  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };
  


  const queryChunks = chunkArray(checkedRows, 5); // Split into chunks of 5

  try {
    // Process each chunk sequentially
    for (const chunk of queryChunks) {
      console.log(`Processing chunk of ${chunk.length} queries...`);

      const updatePromises = chunk.map(async (queryId, index) => {
        let requestSuccessful = false;
        let retryCount = 0;

        const makeRequest = async () => {
          if (requestSuccessful || controllers[index].signal.aborted) return;

          try {
            await handleUpdateButtonClick(
              { stopPropagation: () => {} },
              userId,
              queryId,
              projectId,
              controllers[index],
              isSmallScreen
            );
            requestSuccessful = true; // Mark the request as successful if no errors

          } catch (error) {
            console.error("Error updating query:", error);

            // Retry if request fails, but limit to `retryLimit`
            if (retryCount < retryLimit && error.name === "AbortError") {
              retryCount += 1;
              await wait(2000 * retryCount); // Exponential backoff
              await makeRequest(); // Retry the request
            } else {
              // If retries exceed the limit, log an error and stop retrying
              console.error(`Failed to update query ${queryId} after ${retryLimit} retries.`);
              setUpdateErrors((prevErrors) => prevErrors + 1);
            }
          }
        };

        await makeRequest(); // Call the request with retry logic
      });

      await Promise.all(updatePromises); // Wait for the current chunk to complete before processing the next
    }

    toast.success(`Successfully updated all ${total} queries`);
    setisQuota(true);

  } catch (error) {
    if (error.name !== "AbortError") {
      console.error("Error in bulk update:", error);
    }
  }

  setCheckedRows([]);
  setIsUpdateInProgress(false); // Set the update in progress state to false
  setAbortControllers([]);

  let rank = JSON.parse(sessionStorage.getItem("rank")) || [];

  if (!isSmallScreen) {
    const successMessage = `Successfully updated ${rank.length + 1} queries.`;
    setTimeout(() => sessionStorage.clear(), 3500);
    const errorMessage =
      updateErrors > 0 ? `Failed to update ${updateErrors} queries.` : "";
    if (errorMessage) toast.error(errorMessage);
  }

  setRowsInBulkUpdate(false);
  setUpdatingRows((prev) => {
    const newState = {};
    for (const key in prev) {
      if (Object.prototype.hasOwnProperty.call(prev, key)) {
        newState[key] = false;
      }
    }
    return newState;
  });
};


  // const handleCancelAll = () => {
  //   console.log("dcddvdv");
  //   abortControllers.forEach(controller => {
  //     controller.abort();
  //   });
  //   setAbortControllers([]);
  // };

  const onBulkSubmitHandler = (data) => {

    onBulkSubmit(data, isSmallScreen);
  };
  const handleDeleteButtonBookmark = () => {
    handleDeleteBookmark(userId, selectedBookmarkId, projectId);
    handleResetFilters();

    dispatch(fetchData({ userId, projectId }));
  };

  const handleAddToBookmark = async (bookmarkId) => {
    let successCount = 0; 

    for (const queryId of checkedRows) {
      const queryDetails = filteredData.find((q) => q.query_id === queryId);
      if (!queryDetails) {
        console.warn(`Details not found for query ID: ${queryId}`);
        continue;
      }

      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/favourites/${userId}/${queryDetails.query_id}/${bookmarkId}/${projectId}/`;
        const response = await axios.get(apiUrl);

        if (response.status === 200 || response.status === 201) {
          successCount++; // Increment the counter for each successful addition
        } else {
          // Handle the case where the API call was not successful
          console.warn(
            `Failed to add '${queryDetails.query}' to bookmark ${bookmarkId}`
          );
        }
      } catch (error) {
        console.error(
          `Error adding '${queryDetails.query}' to bookmark ${bookmarkId}:`,
          error
        );
        // Optionally: Show an error message or handle the error as needed
      }
    }

    // Check if there were any successful additions and show a summary toast
    if (successCount > 0) {
      toast.success(
        `${successCount} ${
          successCount === 1 ? "query was" : "queries were"
        } successfully added to the bookmark`
      );
    } else {
      // If no queries were successfully added, you might want to inform the user as well
      toast.info("No queries were added to the bookmark");
    }

    setCheckedRows([]); // Reset the selection after the operation
  };
  const {
    filteredBookmarks,
    newBookmarkName,
    setNewBookmarkName,
    handleCreateBookmark,
    handleDeleteBookmark,
    handleRenameBookmark,
    setEditingBookmarkId,
    editingBookmarkId,
  } = useBookmarks(projectId, userId, handleAddToBookmark, filteredData);

  const onConfirm = () => {
    setOpenDateFilter(false);
    setCustomDateChart(true);
  };

  useEffect(() => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    setCsrfToken(token);
  }, []);

  useEffect(() => {
    // minProjectRank();

    // Whenever the filtered data or rows per page change, update the total pages
    const totalRows = filteredData.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    // If current page is greater than new total pages, reset current page to last available page
    if (currentPage > totalPages) {
      paginate(totalPages || 1);
    }
    setFavoritesData(filteredData.length);
  }, [rowsPerPage]);

  function extractDomain(url) {
    let domain;
    // Remove protocol (http, https, etc.) and get domain
    if (url.indexOf("://") > -1) {
      domain = url.split("/")[2];
    } else {
      domain = url.split("/")[0];
    }
    // Remove port number if present
    domain = domain.split(":")[0];
    // Remove "www."
    domain = domain.replace("www.", "");
    return domain;
  }
  useEffect(() => {
    if (tableData.length > 0) {
      let combinedArray = tableData.reduce((acc, item) => {
        // Parse the string into an array if conditions are met
        let links = [];
        try {
          if (
            item?.first_five_links != null &&
            item?.first_five_links.length > 0 &&
            !item.first_five_links.startsWith("g") &&
            !item.first_five_links.startsWith("n") &&
            !item.first_five_links.startsWith("a")
          ) {
            links = JSON.parse(item.first_five_links.replace(/'/g, '"'));
          }
        } catch (e) {
          links = [""];
        }
        return acc.concat(links);
      }, []);

      let domainCounts = combinedArray?.reduce((acc, link) => {
        let domain = extractDomain(link);
        acc[domain] = (acc[domain] || 0) + 1;
        return acc;
      }, {});
      const excludeDomains = [
        "youtube",
        "instagram",
        "wikipedia",
        "facebook",
        "medium",
        "olx",
        "linkedin",
        "dubizzle",
        "twitter",
        "snapchat",
        "tiktok",
        "pinterest",
        "reddit",
        "tumblr",
        "vimeo",
        "booking",
        "airbnb",
        "tripadvisor",
        "yelp",
      ];
      domainCounts = Object.entries(domainCounts)
        .filter(
          ([domain]) =>
            !excludeDomains.some((exclude) => domain.includes(exclude))
        )
        .reduce((acc, [domain, value]) => {
          acc[domain] = value;
          return acc;
        }, {});
      let top15Domains = Object.entries(domainCounts)
        .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
        // .slice(0, 15) // Get the top 10
        .map((entry) => ({ domain: entry[0], count: entry[1] }));
      setRankLinks(top15Domains);

      dispatch(
        setProjectCompetitors({
          projectId: projectId,
          projectName: ProjectName,
          links: top15Domains,
          projectUrl: projectDetails?.url,
        })
      );
    }
  }, [tableData]);
  useEffect(() => {
    setCheckedRows([]);
  }, [projectId]);

  const handleSelectAllClick = (newCheckedRows) => {
    const selectAllData =
      filteredByRank.length > 0 && filteredByRank.length !== tableData.length
        ? filteredByRank
        : tableData;
    const allRowIds = selectAllData.map((row) => row.query_id);

    setCheckedRows(allRowIds);
    setSelectedRows(currentRows);
  };
  useEffect(()=>{
setFilteredByRank([])
  },[location.pathname])
  return (
    <>
      {notFound && (
        <div className="w-full h-screen flex items-center justify-center">
          <div className="text-center">
            <h1 className="text-4xl font-bold ">404 - Project Not Found</h1>
            <p className="mt-4">
              The project you are looking for does not exist .
            </p>
            <Link
              to="/projects"
              className="mt-6 inline-block bg-mainColor text-white px-4 py-2 rounded transition 
  duration-300 ease-in-out transform hover:bg-white hover:text-mainColor  
  hover:border-2 hover:border-mainColor hover:scale-105"
            >
              Go Back to Projects
            </Link>
          </div>
        </div>
      )}

      {/* <QuotaModal
        isOpen={isQuota}
        onClose={() => setisQuota(false)}
        remainingQuota={remainingQuota}
      /> */}
      {/* // Render the Bulk Update Confirmation Modal */}

      <ConfirmationUpdate
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleBulkUpdate}
        isUpdating={isUpdating}
        numberOfKeywords={confirmationData.numberOfKeywords}
      />
      {/* // Render the Single Update Confirmation Modal */}

      <Confirmation_Update
        isOpen={isConfirmation_Open}
        onClose={() => setIsConfirmation_Open(false)}
        onConfirm={handleUpdateConfirm}
        isUpdating={is_Updating}
        numberOfKeywords={confirmation_Data.numberOfKeywords}
      />

      <ConfirmationModals
        isBulkDeleteDialogOpen={isBulkDeleteDialogOpen}
        handleDeleteConfirmed={handleDeleteConfirmed}
        handleCancelDeletion={handleCancelDeletion}
        isDialogOpen={isDialogOpen}
        cancelDeletion={cancelDeletion}
        confirmDeletion={confirmDeletion}
        isDeleting={isDeleting}
      />
      <DateFilter
        isOpen={openDateFilter}
        onClose={() => {
          setOpenDateFilter(false);
        }}
        onConfirm={onConfirm}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        setCustomDateChart={setCustomDateChart}
      />

      <RankModal
        tableData={tableData}
        filteredData={filteredData}
        isModalOpen={isModal_Open}
        closeModal={close_Modal}
        onSubmit={onBulkSubmitHandler}
        isDataLoading={isDataLoading}
        projectDetails={projectDetails}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        handleLocationChange={handleLocationChange}
        roles={roles}
      />

      {!notFound && (
        <div className="w-full  p-4 mt-[4rem] ">
          {is_Loading ? (
            <SkeletonCard count={4} />
          ) : (
            <ProjectHeader
              tableData={tableData}
              projectDetails={projectDetails}
              seriesData={seriesData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              startDate={startDate}
              endDate={endDate}
              openDateFilter={() => {
                setOpenDateFilter(true);
              }}
              filteredByRank={filteredByRank}
              setFilteredByRank={setFilteredByRank}
              setCustomDateChart={setCustomDateChart}
              customDateChart={customDateChart}
            />
          )}

          {/*Table And Actions */}
          <div
            id="table"
            className={`w-full p-0 mt-4 border-t-2 border-gray-200 dark:border-gray-500 spac lg-plus:p-4`}
          >
            {/* <div>
            <button className=" border px-3 mx-3" onClick={update}>
              update
            </button>
            <button className=" border px-3 mx-3" onClick={stopOperation}>
              stop operation
            </button>
          </div> */}
            <div className="w-full">
              {is_Loading ? (
                <FilterSkeleton />
              ) : (
                <FilterComponent
                  filteredData={filteredData}
                  tableData={tableData}
                  filteredBookmarks={filteredBookmarks}
                  handleAddToBookmark={handleAddToBookmark}
                  bookmarkNames={bookmarkNames}
                  setBookmarkNames={setBookmarkNames}
                  handleRenameBookmark={handleRenameBookmark}
                  handleDeleteBookmark={handleDeleteBookmark}
                  userId={userId}
                  projectId={projectId}
                  isCreatingBookmark={isCreatingBookmark}
                  setIsCreatingBookmark={setIsCreatingBookmark}
                  newBookmarkName={newBookmarkName}
                  setNewBookmarkName={setNewBookmarkName}
                  handleCreateBookmark={handleCreateBookmark}
                  editingBookmarkId={editingBookmarkId}
                  setEditingBookmarkId={setEditingBookmarkId}
                  uniqueTargetUrls={uniqueTargetUrls}
                  selectedTargetUrl={selectedTargetUrl}
                  handleTargetUrlFilterChange={handleTargetUrlFilterChange}
                  selectedLocationFilter={selectedLocationFilter}
                  setSelectedLocationFilter={setSelectedLocationFilter}
                  rankRange={rankRange}
                  handleRangeChange={handleRangeChange}
                  bookmarkOptions={bookmarkOptions}
                  handleBookmarkChange={handleBookmarkChange}
                  selectedBookmark={selectedBookmark}
                  customSelectStyles={customSelectStyles}
                  isFiltersVisible={isFiltersVisible}
                  toggleFilters={() => setIsFiltersVisible(!isFiltersVisible)}
                />
              )}
            </div>

            {/* {!showProgressBar &&
              !showAddProgressBar &&
              !isDelete &&
              !isEditInProgress && (
                <button
                  className={`fixed 
                     bottom-[30px] right-4
                      bg-mainColor hover:bg-white
       text-white hover:text-mainColor
      font-bold w-12 h-12 flex items-center justify-center pt-[2px] rounded-full shadow-lg focus:outline-none focus:shadow-outline transform 
     transition-transform duration-300 ease-in-out hover:scale-110 z-[10000]`}
                  onClick={() => navigate("/feedback")}
                  
                >
                  <MdOutlineFeedback size={22} />
                </button>
              )}

            {showProgressBar && (
              <button
                className={`fixed ${
                  showProgressBar ? "bottom-[110px]  " : "bottom-[30px] "
                }  right-4 bg-mainColor hover:bg-white
       text-white hover:text-mainColor
      font-bold w-12 h-12 flex items-center justify-center pt-[2px] rounded-full shadow-lg focus:outline-none focus:shadow-outline transform 
     transition-transform duration-300 ease-in-out hover:scale-110 z-[10000]`}
                onClick={() => navigate("/feedback")}
              >
                <MdOutlineFeedback size={22} />
              </button>
            )}

            {showAddProgressBar && (
              <button
                className={`fixed ${
                  showAddProgressBar ? "bottom-[110px]  " : "bottom-[30px] "
                }  right-4 bg-mainColor hover:bg-white
        text-white hover:text-mainColor
       font-bold w-12 h-12 flex items-center justify-center pt-[2px] rounded-full shadow-lg focus:outline-none focus:shadow-outline transform 
      transition-transform duration-300 ease-in-out hover:scale-110 z-[10000]`}
                onClick={() => navigate("/feedback")}
              >
                <MdOutlineFeedback size={22} />
              </button>
            )}

            {isDelete && (
              <button
                className={`fixed ${
                  isDelete ? "bottom-[110px]  " : "bottom-[30px] "
                }  right-4 bg-mainColor hover:bg-white
text-white hover:text-mainColor
font-bold w-12 h-12 flex items-center justify-center pt-[2px] rounded-full shadow-lg focus:outline-none focus:shadow-outline transform 
transition-transform duration-300 ease-in-out hover:scale-110 z-[10000]`}
                onClick={() => navigate("/feedback")}
              >
                <MdOutlineFeedback size={22} />
              </button>
            )}
            {isEditInProgress && (
              <button
                className={`fixed ${
                  isEditInProgress ? "bottom-[110px]  " : "bottom-[30px] "
                }  right-4 bg-mainColor hover:bg-white
text-white hover:text-mainColor
font-bold w-12 h-12 flex items-center justify-center pt-[2px] rounded-full shadow-lg focus:outline-none focus:shadow-outline transform 
transition-transform duration-300 ease-in-out hover:scale-110 z-[10000]`}
                onClick={() => navigate("/feedback")}
              >
                <MdOutlineFeedback size={22} />
              </button>
            )} */}
            <OperationComplete
              setTotalDeletes={setTotalDeletes}
              setCompleteDeletes={setCompleteDeletes}
              totalDeletes={totalDeletes}
              completeDeletes={completeDeletes}
              isDelete={isDelete}
              isDeleting={isDeleting}
              showProgressBar={showProgressBar}
              completedUpdates={completedUpdates}
              updateErrors={updateErrors}
              totalUpdates={totalUpdates}
              fadeOut={fadeOut}
              progressBarVisible={true} // Assuming this should always be true
              showAddProgressBar={showAddProgressBar}
              completedAdds={completedAdds}
              addErrors={addErrors}
              totalAdds={totalAdds}
              setShowProgressBar={setShowProgressBar}
              setFadeOut={setFadeOut}
              setCompletedUpdates={setCompletedUpdates}
              setUpdateErrors={setUpdateErrors}
              setTotalUpdates={setTotalUpdates}
              setShowAddProgressBar={setShowAddProgressBar}
              setCompletedAdds={setCompletedAdds}
              setAddErrors={setAddErrors}
              setTotalAdds={setTotalAdds}
              isEditInProgress={isEditInProgress}
              editProgress={editProgress}
              editError={editError}
              setEditProgress={setEditProgress} // Add this line
            />

            <TableHeaderControls
              filteredByRank={filteredByRank}
              setFilteredByRank={setFilteredByRank}
              handleSelectAllClick={handleSelectAllClick}
              showAddProgressBar={showAddProgressBar}
              completedAdds={completedAdds}
              checkedRows={checkedRows}
              selectedBookmark={selectedBookmark}
              favoritesData={favoritesData}
              selectedBookmarkName={selectedBookmarkName}
              areFiltersApplied={hasFiltersApplied}
              filteredData={filteredData}
              isDataLoading={isDataLoading}
              loading={loading}
              tableData={tableData}
              managed={managed}
              roles={roles}
              handleBulkUpdate={handleBulkUpdateButtonClick}
              isUpdateInProgress={isUpdateInProgress}
              handleBulkDelete={handleBulkDelete}
              toggleBookmarkSelector={toggleBookmarkSelector}
              filteredBookmarks={filteredBookmarks}
              handleAddToBookmark={handleAddToBookmark}
              bookmarkNames={bookmarkNames}
              setBookmarkNames={setBookmarkNames}
              handleRenameBookmark={handleRenameBookmark}
              handleDeleteBookmark={handleDeleteBookmark}
              userId={userId}
              projectId={projectId}
              isCreatingBookmark={isCreatingBookmark}
              setIsCreatingBookmark={setIsCreatingBookmark}
              newBookmarkName={newBookmarkName}
              setNewBookmarkName={setNewBookmarkName}
              handleCreateBookmark={handleCreateBookmark}
              searchQuery={searchQuery}
              handleSearchInputChange={handleSearchInputChange}
              exportToExcel={handleExportToExcel}
              hasFiltersApplied={hasFiltersApplied}
              handleResetFilters={handleResetFilters}
              handleDeleteButtonBookmark={handleDeleteButtonBookmark}
              // stopOperation={stopOperation}
              show_BookmarkSelector={show_BookmarkSelector}
              setShow_BookmarkSelector={setShow_BookmarkSelector}
              open_Modal={open_Modal}
              editingBookmarkId={editingBookmarkId}
              setEditingBookmarkId={setEditingBookmarkId}
              isLoading={isLoading}
              selectedBookmarkId={selectedBookmarkId}
              selectedRows={selectedRows}
            />

            <RankTable
              is_Updating={is_Updating}
              tableData={tableData}
              filteredByRank={filteredByRank}
              setTableFilteredDate={setTableFilteredDate}
              projectDetails={projectDetails}
              filteredData={filteredData}
              setCurrentRows={setCurrentRows}
              loading={loading}
              currentRows={currentRows}
              selectedRows={selectedRows}
              checkedRows={checkedRows}
              updatingRows={updatingRows}
              handleSingleUpdateButtonClick={handleSingleUpdateButtonClick}
              userId={userId}
              projectId={projectId}
              handleEditClick={handleEditClick}
              editingQueryId={editingQueryId}
              tempQueryName={tempQueryName}
              handleQueryNameChange={handleQueryNameChange}
              submitNewQueryName={submitNewQueryName}
              handleDeleteButtonClick={handleDeleteButtonClick}
              roles={roles}
              setSelectedRows={setSelectedRows}
              setCheckedRows={setCheckedRows}
              managed={managed}
              setEditingQueryId={setEditingQueryId}
              isEditInProgress={isEditInProgress}
              favoritesData={favoritesData}
              rowsInBulkUpdate={rowsInBulkUpdate}
            />
            <div>
              <PaginationControls
                showProgressBar={showProgressBar || showAddProgressBar}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                currentPage={currentPage}
                paginate={paginate}
                totalPages={totalPages}
                tableDataLength={tableData.length}
                filteredData={filteredData.length}
              />
            </div>
            {selectedBookmark && (
              <BookmarkDetails bookmarkId={selectedBookmarkId} />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default memo(Projectranks);
