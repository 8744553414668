import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { MenuList } from "@material-tailwind/react";
import React, { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { FaClosedCaptioning } from "react-icons/fa6";
import { MdClose, MdDelete } from "react-icons/md";
import Select from "react-select";

const Spinner = () => (
  <div className="w-4 h-4 mx-2 border-b-2 border-white rounded-full animate-spin"></div>
);

const AddCompetitors = ({
  links,
  isOpen,
  onClose,
  onConfirm,
  loading,
  selectedLinks,
  setSelectedLinks,
  projectUrl,
  extractDomain,
  removeSeleted,
  OpenDeleteConfirm,
  handleSelect,
  openNew,
  openAddNew,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = links
    .filter(
      (link) =>
        !link.domain.toLowerCase().includes(projectUrl.toLowerCase()) &&
        !selectedLinks.includes(link.domain)
    )
    .map((link) => ({
      label: `${link.domain}`,
      value: link.domain,
    }));
  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    handleSelect(selected.value);
  };

  const linkAppear =
    selectedLinks.length === 0
      ? links.slice(0, 10)
      : selectedLinks.length === 1
      ? links.slice(0, 11)
      : selectedLinks.length === 2
      ? links.slice(0, 12)
      : selectedLinks.length === 3
      ? links.slice(0, 13)
      : selectedLinks.length === 4
      ? links.slice(0, 14)
      : links;
      const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? "#28A745" : "#28A745",
          backgroundColor: state.isSelected
            ? "#e5f6df"
            : state.isFocused
            ? "#e5f6df"
            : "#ffffff",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "4px 12px",
          minWidth: "100px",
        }),
        container: (provided) => ({
          ...provided,
          width: "250px",
          borderColor: "#28A745",
        }),
        menu: (provided) => ({
          ...provided,
          width: "230px",
          borderColor: "#28A745",
          maxHeight: "150px",
          overflow: "hidden", // Ensure the menu itself doesn't scroll
        }),
        control: (provided, state) => ({
          ...provided,
          width: "250px",
          borderColor: state.isFocused ? "#218838" : "#28A745",
          "&:hover": {
            borderColor: "#218838",
          },
          borderRadius: "16px",
          overflow: "hidden",
        }),
        menuList: (provided) => ({
          ...provided,
          maxHeight: "150px", // Control the height of the list
          overflowY: "auto", // Ensure only one scroll bar
          overflowX: "hidden",

          padding: 0, // Optional: Adjust padding if necessary

        }),
      };
  if (!isOpen) return null;

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
      className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
      bg-greeng 
      bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
      >
      <div className="w-full max-w-2xl p-4 bg-white rounded-lg shadow-lg">
        <h3 className="text-center font-medium text-lg mb-3">
          Add Competitors
        </h3>

        {/* <div className="grid lg:grid-cols-2  items-stretch justify-between gap-4"> */}
        {/* <div className="border rounded-lg my-3 p-3">
            {selectedLinks.map((link) => {
              return (
                <div className="grid grid-cols-3 gap-3 items-center">
                  {link !== projectUrl && (
                    <>
                      <p className="col-span-2">{link}</p>{" "}
                      <MdDelete
                        className="cursor-pointer text-red-600"
                        onClick={() => {
                          removeSeleted(link);
                        }}
                      />
                    </>
                  )}
                </div>
              );
            })}
          </div> */}
        {/* Suggestion */}
        <div>
          <p>Competitor Suggestion</p>

          <div className="flex items-center flex-wrap gap-2 my-2 ">
            {selectedLinks.map((link, index) => {
              return (
                <>
                  {!link.toLowerCase().includes(projectUrl.toLowerCase()) && (
                    <button
                      onClick={() => {
                        OpenDeleteConfirm(link);
                      }}
                      className={`${"opacity-25"} rounded-2xl bg-mainColor text-white   px-3 py-1 `}
                      // disabled={selectedLinks.includes(link.domain)}
                    >
                      {link}
                    </button>
                  )}
                </>
              );
            })}
            {linkAppear.map((link) => {
              return (
                <>
                  {!link.domain
                    .toLowerCase()
                    .includes(projectUrl.toLowerCase()) &&
                    !selectedLinks.includes(link.domain) && (
                      <button
                        onClick={() => {
                          selectedLinks.includes(link.domain)
                            ? OpenDeleteConfirm(link.domain)
                            : handleSelect(link.domain);
                        }}
                        className={`${
                          selectedLinks.includes(link.domain)
                            ? "opacity-25"
                            : "opacity-90 hover:opacity-100 "
                        } rounded-2xl bg-mainColor text-white   px-3 py-1 `}
                        // disabled={selectedLinks.includes(link.domain)}
                      >
                        {link.domain}
                      </button>
                    )}
                </>
              );
            })}
            {!openAddNew && links.length > 10 && (
              <button
                onClick={openNew}
                className="border border-gray-300 hover:text-mainColor rounded-2xl flex items-center  gap-2 px-3 py-1"
              >
                <span>Add New Competitor</span>
                <BiPlus size={18} />
              </button>
            )}
            {openAddNew && links.length > 10 && (
              <>
                <Select
                  options={options}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  placeholder="Choose a competitors"
                  styles={customStyles}
                  className=" text-mainColor flex items-center gap-2 px-3 py-1 min-w-[100px]"
                />
              </>
            )}
          </div>
        </div>
        {/* </div> */}
        <p>
          Competitor Selected:{" "}
          {selectedLinks.length > 0
            ? selectedLinks.length - 1
            : selectedLinks.length}
          /3
        </p>
        <p className="text-red-600">
          {selectedLinks.length === 4 ? "You have reached the limit." : ""}
        </p>
        <div className="flex justify-end mt-4 space-x-4">
          <button
            className="px-4 py-2 font-bold
             text-black bg-gray-300 rounded
              hover:bg-gray-400"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="flex items-center px-4 py-2 font-bold text-white bg-mainColor hover:bg-emerald-700 rounded "
            onClick={onConfirm}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner /> saving...
              </>
            ) : (
              "save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCompetitors;
