import { Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { MdOutlineDateRange } from "react-icons/md";

// Import a spinner component or create a simple one
const DateOptions = [
  { label: "last 7 days", value: "7days" },
  { label: "last 28 days", value: "28days" },
  { label: "last 3 months", value: "3months" },
  { label: "last 6 months", value: "6months" },
  { label: "last 12 months", value: "12months" },
  { label: "last 16 months", value: "16months" },
  { label: "custom", value: "custom" },
];

const DateFilter = ({
  isOpen,
  onClose,
  onConfirm,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setCustomDateChart,
}) => {
  const [selectedDate, setSelectedDate] = useState(DateOptions[0].value);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (selectedDate !== "custom") {
      updateDates(selectedDate);
    }
  }, [selectedDate]);

  const updateDates = (range) => {
    let days = 7;
    switch (range) {
      case "28days":
        days = 28;
        break;
      case "3months":
        days = 90;
        break;
      case "6months":
        days = 180;
        break;
      case "12months":
        days = 365;
        break;
      case "16months":
        days = 480;
        break;
      default:
        days = 7;
    }

    const calculatedStartDate = new Date(
      new Date().setDate(new Date().getDate() - days)
    );
    setStartDate(calculatedStartDate);
    setEndDate(new Date());
    const daysArray = getDays(days);
    setDates(daysArray);
  };

  const getDays = (range) => {
    const daysArray = [];
    for (let i = 0; i < range; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      daysArray.push(formatDate(date));
    }
    return daysArray;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    if (e === "custom") {
      // Custom date handling
    } else {
      updateDates(e);
    }
  };

  if (!isOpen) return null;

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
    

    className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
    bg-greeng 
    bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
    >
      <div className="w-full max-w-sm p-4 bg-white rounded-lg shadow-lg">
        <RadioGroup value={selectedDate} onChange={handleDateChange}>
          <RadioGroup.Label className="sr-only">
            Select Date Range
          </RadioGroup.Label>
          <div className="space-y-2">
            {DateOptions.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option.value}
                className={({ active, checked }) =>
                  `${checked ? "border-mainColor text-mainColor " : "bg-white"}
                 ${
                   active
                     ? "ring-1 ring-mainColor text-mainColor border-mainColor"
                     : ""
                 }
                 relative rounded-lg border px-5 py-4 cursor-pointer flex focus:outline-none`
                }
              >
                {({ checked }) => (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium ${
                            checked ? "text-mainColor" : "text-gray-900"
                          }`}
                        >
                          {option.label}
                        </RadioGroup.Label>
                      </div>
                    </div>
                    {checked && (
                      <div className="flex-shrink-0 text-mainColor">
                        <CheckCircleIcon className="w-6 h-6" />
                      </div>
                    )}
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        {selectedDate === "custom" && (
          <div className="flex items-center gap-4 my-5">
            <ReactDatePicker
              id="start-date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start date"
              className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-mainColor border-2"
            />
            <ReactDatePicker
              id="end-date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End date"
              className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-mainColor border-2"
            />
          </div>
        )}

        <div className="flex justify-end mt-4 space-x-4">
          <button
            className="px-4 py-2 font-bold text-black bg-gray-300 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>

          <button
            className="flex items-center px-4 py-2 font-bold text-white bg-mainColor rounded hover:bg-mainColor-700"
            onClick={onConfirm}
          >
            submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
