import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import CompletedQueries from "./CompletedQueries";
import { toast } from "react-toastify";

const SharedProgressBar = ({
  completedUpdates,
  updateErrors,
  totalUpdates,
  fadeOut,
  progressBarVisible,
  setShowProgressBar,
  setTotalUpdates,
  setCompletedUpdates,
}) => {
  const [showQueries, setShowQueries] = useState(false);

  
  const isLoading = completedUpdates + updateErrors < totalUpdates;
  const hasErrors = updateErrors > 0;
  const isCompleteOrHasError = completedUpdates > 0 || hasErrors;
  
  const progressBarColor = hasErrors
    ? completedUpdates > 0
      ? "bg-orange-500"
      : "bg-red-500"
    : "bg-green-500";

  const textColor = hasErrors ? "text-red-500" : "text-green-500";

  const progressBarWidth = `${Math.min(
    (completedUpdates / totalUpdates) * 100,
    100
  )}%`;
  
  useEffect(() => {
    if (progressBarWidth === "100%") {
      setShowProgressBar(false);
      setTotalUpdates(0);
      setCompletedUpdates(0);
      setTimeout(() => sessionStorage.clear(), 500);
    }
  }, [
    progressBarWidth,
    setShowProgressBar,
    setTotalUpdates,
    setCompletedUpdates,
  ]);
  if (!progressBarVisible) return null;

  return (
    <div className="my-2">
      <div className="progress-bar-striped bg-gray-200 rounded-full h-2 overflow-hidden">
        <div
          className={`progress-bar-striped ${
            fadeOut ? "fade-out" : ""
          } h-full rounded-full ${progressBarColor} transition-all duration-500`}
          style={{ width: progressBarWidth }}
        >
          {isCompleteOrHasError && (
            <div className="flex items-center justify-end h-full pr-2">
              {hasErrors ? (
                <svg
                  className="w-4 h-[20px] text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="w-4 h-[20px] text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </div>
          )}
        </div>
      </div>
      <span className={`${textColor} text-sm mt-[10px]`}>
        Updating: {completedUpdates}/{totalUpdates}
        {hasErrors && ` with ${updateErrors} error(s)`}
        {!showQueries && (
          <ArrowUpCircleIcon
            onClick={() => {
              setShowQueries(true);
            }}
            width={22}
            className=" text-mainColor cursor-pointer inline-block mx-2"
          />
        )}
        {showQueries && (
          <ArrowDownCircleIcon
            onClick={() => {
              setShowQueries(false);
            }}
            width={22}
            className=" text-mainColor cursor-pointer inline-block mx-2"
          />
        )}
        <CompletedQueries
          type={"updated"}
          setShowQueries={setShowQueries}
          showQueries={showQueries}
        />
      </span>
      <span className={`${textColor} text-sm mt-[10px]`}>
        {/* {isCompleteOrHasError
          ? `Completed with ${completedUpdates} success${
              completedUpdates > 1 ? "es" : ""
            } ${
              hasErrors
                ? `and ${updateErrors} error${updateErrors > 1 ? "s" : ""}`
                : ""
            } `
          : ""} */}
        {hasErrors
          ? `and ${updateErrors} error${updateErrors > 1 ? "s" : ""}`
          : ""}
      </span>
      {hasErrors && (
        <div className="mt-2 text-sm text-red-500">Some updates failed.</div>
      )}
    </div>
  );
};
export default SharedProgressBar;
