import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteProjects } from "../redux/lib/deleteBulkProjects";
import { deleteProject } from "../redux/lib/deleteProject";
import { fetchData } from "../redux/lib/fetchData";

const useDeleteOperations = (
  userId,
  projectId,
  setFilteredData,
  setTotalDeletes,
  setCompleteDeletes,
  setIsDelete,
  pin_project,
  setPin_project,
  setCurrentRows
) => {
  // setCompletedUpdates((prev) => prev + 1);

  const dispatch = useDispatch();
  const [isBulkDeleteDialogOpen, setIsBulkDeleteDialogOpen] = useState(false);
  const [deletingQueryId, setDeletingQueryId] = useState(null);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // Added for delete in progress indicator

  const handleDeleteButtonClick = (query_id) => {
    setDeletingQueryId(query_id);
    setIsDialogOpen(true);
  };

  const confirmDeletion = async () => {
    setIsDialogOpen(false);
    if (!deletingQueryId) return;
    setIsBulkDeleteDialogOpen(false);
    setIsDeleting(true);

    try {
      await dispatch(deleteProject(deletingQueryId)).unwrap();

      setFilteredData((currentData) => {
        // Log the data before filtering
        console.log("Before deletion:", currentData);
      
        // Filter out the item with the deletingQueryId
        const updatedData = currentData.filter((rank) => rank.query_id !== deletingQueryId);
      
        // Log the data after filtering
        console.log("After deletion:", updatedData);
      console.log(updatedData,"up")

        return updatedData;
      });
      setCurrentRows((currentRows) => {
        const updatedRows = currentRows.filter((row) => row.query_id !== deletingQueryId);
  
        // Log the state before and after for debugging
        console.log("Before deletion (currentRows):", currentRows);
        console.log("After deletion (currentRows):", updatedRows);
  
        return updatedRows;
      });
      

      dispatch(fetchData({ userId, projectId }));

      setPin_project(!pin_project);

      toast.info("Rank deleted successfully");
      console.log("ommmnia")
    } catch (error) {
      console.error("Error deleting rank:", error);
      toast.error(`An error occurred: ${error.message || "Unknown error"}`);
      setPin_project(!pin_project);
    } finally {
      setPin_project(!pin_project);

      setDeletingQueryId(null);
      setIsDeleting(false);
    }
  };

  const cancelDeletion = () => {
    setIsDialogOpen(false);
    setDeletingQueryId(null);
  };

  const handleBulkDelete = () => {
    if (checkedRows.length === 0) {
      toast.info("No queries selected for deletion.");
    } else {
      setIsBulkDeleteDialogOpen(true);
    }
  };
  // const handle
  const handleDeleteConfirmed = async () => {
    setIsDeleting(true);
    setIsDelete(true);
    const total = checkedRows.length;
    setTotalDeletes(total);
    setIsBulkDeleteDialogOpen(false);
    try {
      await dispatch(deleteProjects(checkedRows)).unwrap();

      // Update the filtered data
      setFilteredData((currentData) => {
        const newData = currentData.filter((rank) => !checkedRows.includes(rank.query_id));
        return newData;
      });
      setCurrentRows((currentRows) => {
        const newCurrentRows = currentRows.filter((row) => !checkedRows.includes(row.query_id));
        return newCurrentRows;
      });
      setPin_project(!pin_project);
      toast.success(
        `${checkedRows.length} ${
          checkedRows.length > 1 ? "queries" : "query"
        } deleted successfully`
      );
 
      setCheckedRows([]); // Clear the checked rows after deletion
    } catch (error) {
      console.error("Error in bulk delete:", error);
      toast.error("An error occurred during the bulk delete operation.");
    } finally {
      setIsDeleting(false);
      setIsDelete(false);
      setPin_project(!pin_project);
    }
  };

  const handleCancelDeletion = () => {
    setIsBulkDeleteDialogOpen(false);
  };

  return {
    isDialogOpen,
    isBulkDeleteDialogOpen,
    handleDeleteButtonClick,
    confirmDeletion,
    cancelDeletion,
    handleBulkDelete,
    handleDeleteConfirmed,
    handleCancelDeletion,
    checkedRows,
    setCheckedRows,
    isDeleting, // Return the isDeleting state
  };
};

export default useDeleteOperations;
