import axios from "axios";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams, useLocation, useNavigate } from "react-router-dom"; // Import useHistory
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import { FaBars, FaRegFileWord, FaSave, FaSpinner, FaArrowDown } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }], // Add font sizes

    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "blockquote"],
    [{ color: [] }], // Add color and background color options

    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }], // Optional: add alignment
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "color",
  "background",
  "list",
  "bullet",
  "script",
  "bold",
  "italic",
  "underline",
  "blockquote",
  "align",
  "indent",
];

const SkeletonLoader = ({ numbers }) => {
  return (
    <div className="animate-pulse space-y-4">
      {/* Skeleton for a heading */}
      <div className="h-6 bg-gray-300 rounded w-3/4"></div>
      {numbers !== 1 && <>
        {[...Array(numbers)].map((_, index) => (
          <div className="space-y-2">
            <div className="h-4 bg-gray-300 rounded w-5/6"></div>
            <div className="h-4 bg-gray-300 rounded w-full"></div>
            <div className="h-4 bg-gray-300 rounded w-5/6"></div>
            <div className="h-4 bg-gray-300 rounded w-2/3"></div>

          </div>
        ))}
      </>}

    </div>
  );
};

const isArabicText = (text) => {
  const arabicPattern = /[\u0600-\u06FF]/g; // Arabic characters
  const englishPattern = /[a-zA-Z]/g; // English characters

  const arabicMatches = text.match(arabicPattern) || [];
  const englishMatches = text.match(englishPattern) || [];

  // Compare counts and return true if Arabic characters predominate
  return arabicMatches.length > englishMatches.length;
};

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
};

const normalizeText = (text) => {
  console.log(text, 'text');

  if (!text) return ''; // Return an empty string if text is undefined or null

  return text
    .replace(/أ|ا/g, 'ا')  // Normalize different forms of 'ا'
    .replace(/ه|ة/g, 'ة')  // Normalize different forms of 'ة'
    .replace(/ى|ي/g, 'ي')  // Normalize different forms of 'ة'

    // Add more character replacements as needed
    .normalize("NFC");     // Normalize text for compatibility
};

const isAtBottom = () => {
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const scrollPosition = window.scrollY || document.documentElement.scrollTop;
  const totalHeight = document.documentElement.scrollHeight;

  return windowHeight + scrollPosition + 5 >= totalHeight; // Adjusted for minor differences
};
const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
  });
};
const GeneratedURLPage = ({ show }) => {
  const { userid, object_id, url } = useParams();
  const location = useLocation();
  const [keywordOccurrencesInHeadings, setKeywordOccurrencesInHeadings] =
    useState({});
  const [usedKeywords, setUsedKeywords] = useState([]);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [headings, setHeadings] = useState([]);
  const [contentDirection, setContentDirection] = useState("ltr");
  const token = useSelector((state) => state.authSlice?.user.token);
  const [isSaving, setIsSaving] = useState(false);
  const quillRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false); // Set true for new posts, false for others
  const [userScrolled, setUserScrolled] = useState(false);
  const [outPutMessages, setOutputMessages] = useState([]);
  const [showScrollIcon, setShowScrollIcon] = useState(true);
  const [keywordSearch, setKeywordSearch] = useState()
  const [keywordCounts, setKeywordCounts] = useState([]);

  const contentIndexRef = useRef(0); // Keeps track of the current index for typing
  const intervalRef = useRef(null); // Store the interval ID
  const startTimeRef = useRef(null); // Use a ref to store a fixed start time


  const extractHeadingsAndCountKeywords = (content) => {
    const headingRegex = /<h2[^>]*>(.*?)<\/h2>/g;
    const keywordCounts = {};
    let match;

    // Extract <h2> headings and count occurrences of each keyword
    while ((match = headingRegex.exec(content)) !== null) {
      const headingText = match[1].trim();

      // Count occurrences of each keyword in the heading
      usedKeywords?.forEach((keyword) => {
        const escapedKeyword = escapeRegExp(keyword.keyword); // Escape special characters
        const regex = new RegExp(escapedKeyword, "gi");
        const occurrences = (headingText.match(regex) || []).length;
        if (occurrences > 0) {
          keywordCounts[keyword.keyword] = (keywordCounts[keyword.keyword] || 0) + occurrences;
        }
      });
    }

    setKeywordOccurrencesInHeadings(keywordCounts); // Update the state with keyword occurrences
  };
  const exportArticleAsWord = (content, title) => {
    const converted = htmlDocx.asBlob(content);
    saveAs(converted, `${title}.docx`);
    toast.success("Article exported as Word");
  };
  const handleChange = (newContent) => {
    const newDirection = isArabicText(newContent) ? "rtl" : "ltr";
    setContentDirection(newDirection);
    setContent(newContent);

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();

      setTimeout(() => {
        if (!userScrolled) {
          editor.root.scrollTo({
            top: editor.root.scrollHeight,
            behavior: "smooth",
          });

          // Smooth scroll to the right (horizontal)
          editor.root.scrollTo({
            left: editor.root.scrollWidth,
            behavior: "smooth",
          });
        }
      }, 400);
    }
    countKeywords(); // Call count function after setting the content

  };
  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Save the edited article
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${object_id}/`,
        {
          title: title,
          Generated_article: content,
          new_headings: headings,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Article saved successfully.");
        // fetchUpdatedArticle(); // Fetch the latest content after saving
      } else {
        toast.error("An error occurred while saving the article.");
      }
    } catch (error) {
      console.error("Error saving article:", error);
      toast.error("An error occurred while saving the article.");
    } finally {
      setIsSaving(false); // Stop the loading spinner
    }
  };
  const fetchUpdatedArticle = async () => {
    if (location?.state?.payload) {
      setIsTyping(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/keyword/Generate-article/`,
          {
            method: "POST",
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
              Accept: "text/event-stream",
            },
            body: JSON.stringify(location.state.payload),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        setOutputMessages([]);

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const messages = chunk.split("\n\n");
          for (const message of messages) {
            if (message.startsWith("data: ")) {
              try {
                const data = JSON.parse(message.slice(6));

                if (data.content && typeof data.content === "object") {
                  if (!data.content.title) {
                    const [heading, para] = Object.entries(data.content)[0] || [
                      "",
                      "",
                    ];
                    setOutputMessages((prevMessages) => [
                      ...prevMessages,
                      {
                        heading: heading || "No heading",
                        content: para || "No content",
                      },
                    ]);
                    setHeadings((prevHeadings) => [
                      ...prevHeadings,
                      heading || "No heading",
                    ]);
                  }

                  // if (data.content.title) {
                  //   setTitle(data.content.title);
                  // }
                  // if (data.content.used_keyword) {
                  //   setUsedKeywords(data.content.used_keyword);
                  // }

                  const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${object_id}/`,
                    {
                      headers: {
                        Authorization: `Token ${token}`,
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  if (response.status === 200) {
                    const {
                      title: fetchedTitle,
                      used_keyword: fetchedKeywords,
                    } = response.data;

                    setTitle(fetchedTitle);

                    // Parse and update usedKeywords
                    if (fetchedKeywords) {
                      setUsedKeywords(
                        typeof fetchedKeywords === "string"
                          ? JSON.parse(fetchedKeywords)
                          : fetchedKeywords
                      );
                    }
                  }
                  navigate(".", { replace: true, state: undefined });
                }
              } catch (e) {
                console.error("Parse error:", e);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error during generation:", error);
      }
    } else {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${object_id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const {
            title: fetchedTitle,
            Generated_article: fetchedContent,
            new_headings: fetchedHeadings,
            used_keyword: fetchedKeywords,
          } = response.data;

          setTitle(fetchedTitle);
          setHeadings(fetchedHeadings || []); // Update the headings state with the fetched data

          // Parse and update usedKeywords
          if (fetchedKeywords) {
            setUsedKeywords(
              typeof fetchedKeywords === "string"
                ? JSON.parse(fetchedKeywords)
                : fetchedKeywords
            );
          }

          if (fetchedContent.includes("\n") && !fetchedContent.includes("h2")) {
            const formattedContent = fetchedContent
              .split("\n")
              .map((section) => {
                // Separate the first sentence as the header (h2)
                let header, paragraphParts, header2;
                if (section.includes(".")) {
                  header = "";
                  paragraphParts = section.split(".");
                } else {
                  header = section;
                  paragraphParts = [];
                }

                // Rejoin the rest as the paragraph
                const paragraph = paragraphParts.join(".").trim();

                return `
                <h2>${header.trim()}</h2>
                <p>${paragraph}</p>
              `;
              })
              .join("");
            setContent(formattedContent);
          } else {
            setContent(fetchedContent);
          }
          // Set the content direction based on the fetched content language
          setContentDirection(isArabicText(fetchedContent) ? "rtl" : "ltr");
          extractHeadingsAndCountKeywords(fetchedContent || "");
        }
      } catch (error) {
        console.error("Error fetching article data:", error);
        toast.error("Failed to fetch article data.");
      }
    }
  };
  const checkScrollIconVisibility = () => {
    const contentHeight = document.documentElement.scrollHeight;
    const viewportHeight = window.innerHeight;
    if (contentHeight > viewportHeight && !isAtBottom()) {
      setShowScrollIcon(true);
    } else {
      setShowScrollIcon(false);
    }
  };
  const updateHeadings = (content) => {
    // Extract headings (h2 elements in this case) from the content
    const headingRegex = /<h2[^>]*>(.*?)<\/h2>/g;
    const extractedHeadings = [];
    let match;
    while ((match = headingRegex.exec(content)) !== null) {
      extractedHeadings.push(match[1]);
    }
  };

  const countKeywords = () => {
    if (quillRef.current && quillRef.current.getEditor) {
      const quill = quillRef.current.getEditor();
      const text = quill.getText(); // Get plain text from the editor
      const normalizedText = normalizeText(text);

      const results = [];

      usedKeywords?.forEach((keyword) => {
        const normalizedKeyword = normalizeText(keyword.keyword);
        let realCount = 0;


        const regex = new RegExp(`${normalizedKeyword}(?=[\\s.,!?\\-]*|$)`, 'giu');

        let match;
        while ((match = regex.exec(normalizedText)) !== null) {
          realCount += 1;
        }

        results.push({
          keyword: keyword.keyword,
          realcount: realCount,
          recomend: keyword.count
        });
      });

      setKeywordCounts(results);
      console.log("Updated keyword counts:", results);
    }
  };

  const highlightText = () => {
    if (quillRef.current && quillRef.current.getEditor) {
      const quill = quillRef.current.getEditor();
      const text = quill.getText(); // Get plain text from the editor
      const normalizedText = normalizeText(text); // Normalize editor content
      const searchTerm = normalizeText(keywordSearch); // Normalize search term

      quill.formatText(0, text.length, { background: '' });

      if (!searchTerm) return 0;

      let matchCount = 0;

      const regex = new RegExp(`${searchTerm}(?=[\\s.,!?\\-]*|$)`, 'giu');

      let match;

      while ((match = regex.exec(normalizedText)) !== null) {
        const index = match.index;
        const matchLength = match[0].length;

        quill.formatText(index, matchLength, { background: 'yellow' });
        matchCount += 1;
      }

      console.log(`Occurrences of '${keywordSearch}':`, matchCount);
      return matchCount;
    }
  };


  useEffect(() => {
    const handleScroll = () => {
      checkScrollIconVisibility(); // Handle scroll icon visibility
    };
    window.addEventListener("scroll", handleScroll);
    checkScrollIconVisibility();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAtBottom()]);
  useEffect(() => {
    fetchUpdatedArticle();
  }, []);
  useEffect(() => {
    updateHeadings(content);
    extractHeadingsAndCountKeywords(content);
    countKeywords(); // Initial count on component mount

  }, [content]);

  useEffect(() => {
    const handleScroll = () => {
      if (isAtBottom()) {
        setShowScrollIcon(false);
      } else {
        checkScrollIconVisibility();
      }
    };

    // Listen for scroll events
    window.addEventListener("scroll", handleScroll);

    // Call checkScrollIconVisibility when the component mounts
    checkScrollIconVisibility();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  useEffect(() => {
    if (!outPutMessages.length) return; // Early exit if there are no messages

    if (startTimeRef.current === null) {
      startTimeRef.current = Date.now(); // Set start time once
    }

    if (outPutMessages.length > 0) {
      const combinedContent = outPutMessages
        .map((item) => `<h2>${item.heading}</h2><p>${item.content}</p>`)
        .join("");
      // contentIndexRef.current = 0; // Reset content index
      // setContent(""); // Clear the content initially

      const length = combinedContent.length;
      const typingSpeed = 5; // Adjust typing speed (milliseconds per character)

      // Typing effect based on elapsed time
      const typeContent = () => {
        const elapsedTime = Date.now() - startTimeRef.current;
        const currentIndex = Math.min(Math.floor(elapsedTime / typingSpeed), length);

        // Update content only if currentIndex has progressed
        if (contentIndexRef.current !== currentIndex) {

          setContent(combinedContent.substring(0, currentIndex));
          contentIndexRef.current = currentIndex;

        }

        // Continue typing if not yet finished
        if (currentIndex < length) {
          intervalRef.current = requestAnimationFrame(typeContent);
        } else {
          // Clear interval when typing completes
          cancelAnimationFrame(intervalRef.current);
          intervalRef.current = null;
          setIsTyping(false); // Typing finished
        }
      };

      intervalRef.current = requestAnimationFrame(typeContent);

      return () => {
        cancelAnimationFrame(intervalRef.current);
        intervalRef.current = null;
      };
    }
  }, [outPutMessages]);
  useEffect(() => {
    const handleScroll = () => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        // Check if user has scrolled horizontally or vertically
        const isUserScrolledVertically =
          editor.root.scrollTop + editor.root.clientHeight <
          editor.root.scrollHeight;
        const isUserScrolledHorizontally =
          editor.root.scrollLeft + editor.root.clientWidth <
          editor.root.scrollWidth;

        if (isUserScrolledVertically || isUserScrolledHorizontally) {
          setUserScrolled(true); // User manually scrolled
        } else {
          setUserScrolled(false); // Reset if the scroll is at the bottom and far right
        }
      }
    };

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.root.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.root.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  // useEffect(() => {
  //   if (typeof usedKeywords === "string") {
  //     try {
  //       const parsedKeywords = JSON.parse(usedKeywords);
  //       setUsedKeywords(parsedKeywords);
  //     } catch (error) {
  //       console.error("Failed to parse used_keywords:", error);
  //       setUsedKeywords({});
  //     }
  //   }
  // }, [usedKeywords]);
  useEffect(() => {

  }, [content]);
  // Ensure highlight is applied initially after mounting
  useEffect(() => {
    if (quillRef.current) highlightText();
  }, [content, keywordSearch]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="container mx-auto p-4 mt-[3.5rem] flex flex-col relative">
      <div className="flex flex-col sm:flex-row items-center justify-between mb-4 w-full gap-4 py-3">
        {/* Export, Save, and Sidebar Toggle Button */}
        <div className="flex items-center justify-center gap-4 sm:order-2 sm:flex-row w-full sm:w-auto ">
          <button
            onClick={() => exportArticleAsWord(content, title)}
            className={`flex items-center gap-2 bg-gradient-to-r from-sky-500 to-sky-300 text-white px-4 py-2 rounded-full shadow-lg transform transition duration-200 ease-in-out hover:scale-105 ${isTyping ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={isTyping}
          >
            <FaRegFileWord className="text-xl" />
            Export
          </button>

          <button
            onClick={handleSave}
            className={`bg-mainColor text-white px-6 py-2 shadow-lg rounded-full transform transition-transform duration-200 ease-in-out hover:scale-105 active:scale-95 ${isSaving || isTyping ? "cursor-not-allowed opacity-50" : ""}`}
            disabled={isSaving || isTyping}
          >
            {isSaving ? (
              <div className="flex items-center gap-2">
                <FaSpinner className="animate-spin" />
                Saving...
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <FaSave />
                Save
              </div>
            )}
          </button>

          {/* Sidebar Toggle Button for Small Screens */}
          <div className="block sm:hidden">
            <button
              className="p-2  text-mainColor bg-gray-200 rounded-full"
              onClick={toggleSidebar}
            >
                       <AiOutlineMenu size={24} />

            </button>
          </div>
        </div>

        {/* Input Field */}
        <div className="w-full sm:flex-1 sm:order-1">
          {!title && !content ? (
            <div className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-400">
              <SkeletonLoader numbers={1} />
            </div>
          ) : (
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-400"
              placeholder="Enter article title"
              style={{ direction: contentDirection }}
            />
          )}
        </div>
      </div>



      <div
        className={`flex w-full relative 
    ${isTyping && usedKeywords?.length > 0 ? "items-stretch" : "items-start"} 
    flex-col sm:flex-row
  `}
      >
        {/* ReactQuill Editor Section */}
        <div className="w-full sm:w-3/4 sm:pr-4 mb-4 sm:mb-0">
          <div
            className={`bg-white p-4 rounded-lg border border-gray-300 quill-editor-${contentDirection}`}
            style={{ direction: contentDirection }}
          >
            {!content ? (
              <SkeletonLoader numbers={5} />
            ) : (
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={content}
                onChange={handleChange}
                modules={modules}
                formats={formats}
                className="min-h-[150px] border-none focus:ring-0"
                style={{ direction: contentDirection }}
              />
            )}
          </div>
        </div>

        {/* Sidebar Section */}
        <div
          className={`bg-gray-100 p-4 border rounded-lg ${isSidebarOpen ? "block" : "hidden"
            } sm:block w-full sm:w-[24%] border-gray-300 max-h-[90vh] overflow-auto sticky top-0 bottom-0 sm:right-[20px]`}
        >
          <ul className="space-y-2">
            {isTyping || Object.keys(usedKeywords).length === 0 ? (
              <SkeletonLoader numbers={3} />
            ) : (
              <>
                <h2>Keywords</h2>
                {keywordCounts.map(({ keyword, realcount, recomend }, index) => (
                  <li
                    key={index}
                    className={`flex flex-col justify-start items-start p-2 bg-white rounded border mt-4
                ${keywordSearch === keyword ? "border-2 border-blue-300" : "border-gray-200 shadow-sm"}
                hover:shadow-md transition duration-200`}
                    onClick={() => setKeywordSearch(prev => (prev === keyword ? null : keyword))}
                  >
                    <div className="flex flex-wrap gap-2 items-center cursor-pointer">
                      <div className={`text-sm text-gray-800 ${keywordSearch === keyword ? "font-bold" : ''}`}>{keyword}</div>
                      <div className="bg-blue-100 px-2 py-0.5 rounded-full text-[10px] flex">
                        <span>Count: </span>
                        <span>{realcount}</span>
                      </div>
                      <div className="bg-blue-100 px-2 py-0.5 rounded-full text-[10px] flex">
                        <span>Recommend: </span>
                        <span>{(recomend - recomend * 0.3).toFixed()}-{(recomend + recomend * 0.3).toFixed()}</span>
                      </div>
                      {keywordOccurrencesInHeadings[keyword] && (
                        <div className="bg-green-100 px-2 py-0.5 rounded-full text-[10px] flex">
                          <span> Headings:</span>
                          <span>{keywordOccurrencesInHeadings[keyword]}</span>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>

        {/* Scroll to Bottom Button */}
        {showScrollIcon && !location?.state?.payload && (
          <div
            className={`fixed bottom-8 ${show ? "left-[-200px]" : "left-8"} 
        bg-mainColor text-white flex items-center justify-center m-auto
        font-bold rounded-full shadow-lg cursor-pointer
        focus:outline-none focus:shadow-outline transform 
        transition-transform duration-300 ease-in-out 
        z-[10000] w-12 h-12
      `}
            onClick={scrollToBottom} // Trigger scroll to bottom on click
          >
            <FaArrowDown size={22} />
          </div>
        )}
      </div>

    </div>
  );
};
export default GeneratedURLPage;
