import React from "react";
import SkeletonTable from "../../Skeleton/SkeletonTable";
import Table from "./Table";

const RankTable = ({
  projectDetails,
  loading,
  currentRows,
  setCurrentRows,
  expandedRowId,
  checkedRows,
  setSelectedRows,
  selectedRows,
  setCheckedRows,
  updatingRows,
  handleSingleUpdateButtonClick,
  userId,
  projectId,
  handleEditClick,
  editingQueryId,
  tempQueryName,
  handleQueryNameChange,
  submitNewQueryName,
  handleDeleteButtonClick,
  roles,
  managed,
  setEditingQueryId,
  isEditInProgress,
  rowsInBulkUpdate,
  filteredData,
  favoritesData,
  tableData,
  is_Updating,
  filteredByRank,
  setTableFilteredDate,
  tableFilteredDate
}) => {
  return (
    <div className="border-2 border-gray-200 dark:border-gray-500 max-h-[850px]    overflow-auto relative">
      {loading ? (
        <SkeletonTable />
      ) : (
        <>
          <Table
          tableFilteredDate={tableFilteredDate}
            filteredByRank={filteredByRank}
            tableData={tableData}
            projectDetails={projectDetails}
            filteredData={filteredData}
            rowsInBulkUpdate={rowsInBulkUpdate}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setCheckedRows={setCheckedRows}
            currentRows={currentRows}
            setCurrentRows={setCurrentRows}
            setTableFilteredDate={setTableFilteredDate}
            expandedRowId={expandedRowId}
            checkedRows={checkedRows}
            updatingRows={updatingRows}
            handleUpdateButtonClick={handleSingleUpdateButtonClick}
            is_Updating={is_Updating}
            userId={userId}
            projectId={projectId}
            handleEditClick={handleEditClick}
            editingQueryId={editingQueryId}
            tempQueryName={tempQueryName}
            handleQueryNameChange={handleQueryNameChange}
            submitNewQueryName={submitNewQueryName}
            handleDeleteButtonClick={handleDeleteButtonClick}
            roles={roles}
            managed={managed}
            setEditingQueryId={setEditingQueryId}
            isEditInProgress={isEditInProgress}
            favoritesData={favoritesData}
          />
        </>
      )}
    </div>
  );
};

export default RankTable;
