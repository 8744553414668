import React, { useState } from 'react';
import ContentBrief from './ContentBrief';
import ScoreCard from './ScoreCard';

const MainKeywordInfo = ({ url, keyword, wordCount, optimizationScore }) => {
  // Trim the keyword to 40 characters if it's longer
  const trimmedKeyword = keyword.length > 30 ? keyword.substring(0, 10) + '...' : keyword;

  // State to handle tooltip visibility
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className='flex flex-col ml-[0.75rem] items-center w-[100%] border-2
     mt-3 md:mt-0
     border-gray-200 rounded-md md:col-span-2 md:flex-row'>
        
        <div className="w-full p-4 relative">
            <p><strong> {url ? "URL" : "Keyword"} : </strong></p>
            <p
              className="truncate cursor-pointer" // Cursor pointer to indicate hoverable text
              onMouseEnter={() => setShowTooltip(true)} // Show tooltip on hover
              onMouseLeave={() => setShowTooltip(false)} // Hide tooltip when not hovering
            >
              {trimmedKeyword}
            </p>
            {/* Tooltip */}
            {showTooltip && (
              <div className="absolute z-10 bg-black text-white text-sm p-2 rounded shadow-md
                bottom-[-2.5rem] left-1/2 transform -translate-x-1/2 whitespace-nowrap
                before:content-[''] before:absolute before:top-[-8px] before:left-1/2 before:border-6
                before:border-transparent before:border-b-black before:-translate-x-1/2">
                {keyword}
              </div>
            )}
        </div>

        <div className="w-full p-4 lg:w-4/10">
            <ContentBrief wordCount={Math.round(wordCount)} />
        </div>

        <div className="w-full p-4 ">
            <ScoreCard score={optimizationScore} />
        </div>
    </div>
  );
};

export default MainKeywordInfo;
