import { FaCheckCircle } from "react-icons/fa";

const DetailsList = ({ title, items }) => (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg border border-[#E5E7EB] md:mb-0 mb-4">
      <h3 className="font-bold text-gray-800 dark:text-gray-200 text-[23px]">{title}</h3>
      <ul className="dark:text-gray-400 list-none pl-[24px] pb-[48px]">
        {items.map((item, index) => (
          <li key={index} className="flex items-center mt-[22px]">
            <FaCheckCircle className="mr-2 text-black" />
            {item.text} <span className="text-green-500 mx-2">{item.value}</span> {item.suffix}
          </li>
        ))}
      </ul>
    </div>
  );


  export default DetailsList;