import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaCheck, FaSpinner, FaTimes } from "react-icons/fa"; // Import the icons you want to use
import { BsStars } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StepForm = ({
  isOpen,
  onClose,
  newHeadings,
  oldHeadings,
  resultSample: initialResultSample,
  minCount,
  maxCount,
  art_id,
  ut_id,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedNewHeadings, setSelectedNewHeadings] = useState([]);
  const [selectedOldHeadings, setSelectedOldHeadings] = useState([]);
  const [articleWordCount, setArticleWordCount] = useState(0); // Initialize as 0
  const [deletedKeywords, setDeletedKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState({});
  const [resultSample, setResultSample] = useState(initialResultSample);
  const [newHeadingInput, setNewHeadingInput] = useState("");
  const [newKeywordInput, setNewKeywordInput] = useState("");


  const navigate = useNavigate();
  const rowsPerPage = newHeadings.length || 5;

  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait, your article is being generated. Do not refresh or close this page."
  );
  const [isGenerating, setIsGenerating] = useState(false); // State to handle loading
  const [isAddingHeading, setIsAddingHeading] = useState(false);
  const [isAddingKeyword, setIsAddingKeyword] = useState(false);
  const [addedKeywords, setAddedKeywords] = useState([]);
  const [editedKeywords, setEditedKeywords] = useState([]);

  useEffect(() => {
    if (isGenerating) {
      const firstTimeout = setTimeout(() => {
        setLoadingMessage("Still loading... Please hold on for a moment.");
      }, 30000); // 30 seconds

      const secondTimeout = setTimeout(() => {
        setLoadingMessage(
          "Please be patient, the process is taking a bit longer than expected."
        );
      }, 60000); // 60 seconds

      return () => {
        clearTimeout(firstTimeout);
        clearTimeout(secondTimeout);
      };
    }
  }, [isGenerating]);
  useEffect(() => {
    if (isOpen) {
      setSelectedNewHeadings(newHeadings);
      setSelectedOldHeadings([]);
    }
  }, [isOpen, newHeadings, oldHeadings]);

  useEffect(() => {
    if (isOpen) {
      setSelectedNewHeadings(newHeadings);
      setSelectedOldHeadings([]);

      // Calculate the average of minCount and maxCount and set it as the initial articleWordCount
      const avgCount = Math.floor((minCount + maxCount) / 2);
      setArticleWordCount(avgCount);
    }
  }, [isOpen, newHeadings, oldHeadings, minCount, maxCount]);

  useEffect(() => {
    if (isOpen && initialResultSample) {
      setResultSample(initialResultSample);
    }
  }, [isOpen, initialResultSample]);

  useEffect(() => {
    if (resultSample) {
      // Initialize selectedKeywords with all keywords checked
      const initialSelectedKeywords = Object.keys(resultSample || {}).reduce(
        (acc, keyword) => {
          acc[keyword] = true;
          return acc;
        },
        {}
      );
      setSelectedKeywords(initialSelectedKeywords);
    }
  }, [resultSample]);


  const avgCount = Math.floor((minCount + maxCount) / 2);

  useEffect(() => {
    // Guard clause to prevent errors if initialResultSample is undefined or null
    if (!initialResultSample) return;

    const avgCount = Math.floor((minCount + maxCount) / 2);
    console.log(avgCount, "avgcount")
    console.log(articleWordCount, "articleWordCount")
    const adjustmentFactor = articleWordCount / avgCount;

    // Adjust each keyword's frequency based on the adjustment factor
    const adjustedFrequencies = Object.fromEntries(
      Object.entries(initialResultSample || {}).map(([keyword, initialFrequency]) => [
        keyword,
        Math.floor(initialFrequency * adjustmentFactor),
      ])
    );

    setResultSample(adjustedFrequencies); // Update state with adjusted frequencies
    console.log("Updated Frequencies:", adjustedFrequencies); // Log the updated frequencies
  }, [articleWordCount, avgCount, initialResultSample]);


  const handleKeywordSelection = (keyword) => {
    setSelectedKeywords((prevSelected) => {
      const isCurrentlySelected = prevSelected[keyword];
      if (isCurrentlySelected) {
        // If unchecking, add to deletedKeywords
        setDeletedKeywords((prevDeleted) => {
          if (!prevDeleted.includes(keyword)) {
            const updatedDeleted = [...prevDeleted, keyword];
            console.log("Deleted Keywords:", updatedDeleted); // Log the deleted keywords array
            return updatedDeleted;
          }
          return prevDeleted;
        });
      } else {
        // If rechecking, remove from deletedKeywords
        setDeletedKeywords((prevDeleted) =>
          prevDeleted.filter((deletedKeyword) => deletedKeyword !== keyword)
        );
      }

      return {
        ...prevSelected,
        [keyword]: !isCurrentlySelected,
      };
    });
  };

  const handleHeadingSelection = (heading, type) => {
    if (type === "new") {
      if (selectedNewHeadings.includes(heading)) {
        setSelectedNewHeadings(
          selectedNewHeadings.filter((h) => h !== heading)
        );
      } else {
        setSelectedNewHeadings([...selectedNewHeadings, heading]);
      }
    } else if (type === "old") {
      if (selectedOldHeadings.includes(heading)) {
        setSelectedOldHeadings(
          selectedOldHeadings.filter((h) => h !== heading)
        );
      } else {
        setSelectedOldHeadings([...selectedOldHeadings, heading]);
      }
    }
  };

  const [data, setData] = useState([
    ...newHeadings.map((heading) => ({ heading, type: "New" })),
    ...oldHeadings.map((heading) => ({ heading, type: "Old" })),
  ]);
  useEffect(() => {
    setData([
      ...newHeadings.map((heading) => ({ heading, type: "New" })),
      ...oldHeadings.map((heading) => ({ heading, type: "Old" })),
    ]);
  }, [newHeadings, oldHeadings]);
  const columns = [
    {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          checked={
            row.type === "New"
              ? selectedNewHeadings.includes(row.heading)
              : selectedOldHeadings.includes(row.heading)
          }
          onChange={() =>
            handleHeadingSelection(row.heading, row.type.toLowerCase())
          }
          className="accent-mainColor"
        />
      ),
      width: "20%",
      maxHeight: "10%",
    },
    {
      name: "Heading",
      selector: (row) => row.heading,
      sortable: true,
      width: "60%",
      cell: (row) => (
        <div
          className="flex justify-between items-center cursor-pointer" // Add cursor pointer for click indication
          onClick={() => handleHeadingSelection(row.heading, row.type.toLowerCase())} // Handle selection on click

        >
          <div style={{ fontSize: "14px" }}>{row.heading}</div>
        </div>
      ),
    },
    {
      name: "",
      sortable: true,
      width: "20%",
      cell: (row) =>
        row.type === "New" && !row.isNewlyAdded ? (
          <div
            className="bg-white"
            style={{
              borderRadius: "50%",
              padding: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "10px",
            }}
          >
            <BsStars style={{ color: "#38bdf8", fontSize: "20px" }} />
          </div>
        ) : null,
    },
  ];

  const handleAddNewKeyword = () => {
    if (newKeywordInput.trim() !== "") {
      const trimmedKeyword = newKeywordInput.trim();
      const minFrequency = Math.floor(
        Math.min(...Object.values(resultSample)) || 1
      ); // Default to 1 if resultSample is empty

      setSelectedKeywords((prevSelected) => ({
        ...prevSelected,
        [trimmedKeyword]: "added",
      }));

      setResultSample((prevResultSample) => ({
        [trimmedKeyword]: minFrequency,
        ...prevResultSample,
      }));

      // Add to addedKeywords if not already present
      setAddedKeywords((prevAdded) => {
        if (!prevAdded.includes(trimmedKeyword)) {
          const updatedAdded = [...prevAdded, trimmedKeyword];
          console.log("Added Keywords:", updatedAdded); // Log the added keywords array
          return updatedAdded;
        }
        return prevAdded;
      });
      setNewKeywordInput("");
      setIsAddingKeyword(false);
    }
  };

  const handleAddNewHeading = () => {
    if (newHeadingInput.trim() !== "") {
      const newHeading = newHeadingInput.trim();

      // Add the new heading to the selectedNewHeadings array
      setSelectedNewHeadings((prevSelected) => [...prevSelected, newHeading]);

      // Add the new heading to the data array at the top and mark it as newly added
      setData((prevData) => [
        { heading: newHeading, type: "New", isNewlyAdded: true },
        ...prevData,
      ]);

      setNewHeadingInput("");
      setIsAddingHeading(false);
    }
  };
  
  const [outPutMessages, setOutputMessages] = useState();
  const userId = useSelector((state) => state?.authSlice?.id);

  const handleGenerateClick = async () => {
    setIsGenerating(true); // Start loading
    setLoadingMessage(
      "Please wait, your article is being generated. Do not refresh or close this page."
    );

    // Prepare the data for the request
    const selectedHeadings = [...selectedNewHeadings, ...selectedOldHeadings];
    const selectedKeywordList = Object.keys(selectedKeywords).filter(
      (keyword) => selectedKeywords[keyword]
    );

    const addedKeywordsPayload = {};
    addedKeywords.forEach((keyword) => {
      if (resultSample[keyword] !== undefined) {
        addedKeywordsPayload[keyword] = resultSample[keyword];
      }
    });

    const editedKeywordsPayload = {};
    editedKeywords.forEach((keyword) => {
      if (resultSample[keyword] !== undefined) {
        editedKeywordsPayload[keyword] = resultSample[keyword];
      }
    });

    const newHeadingsPayload = selectedNewHeadings.filter(Boolean);
  
    const payload = {
      article_id: art_id,
      Added_keyword: Object.keys(addedKeywordsPayload).length
        ? addedKeywordsPayload
        : undefined,
      edit_keyword: Object.keys(editedKeywordsPayload).length
        ? editedKeywordsPayload
        : undefined,
      deleted_keywords: deletedKeywords.length ? deletedKeywords : undefined,
      word_count: articleWordCount || 0,
      new_headings: newHeadingsPayload.length ? newHeadingsPayload : undefined,
    };
    navigate(`/generatedarticle/${userId}/${art_id}/`, {
      state: {
        payload,
      },
    });

  };

  const renderStepOne = () => (
    <div className="">
      <div className="flex justify-start ">
        <h2
          className="text-md  mb-4 flex  items-center 
     justify-center  "
        >
          <span> Select or </span>
          <span
            className="underline text-blue-400 ml-2 cursor-pointer"
            onClick={() => setIsAddingHeading(!isAddingHeading)}
          >
            Add Heading
          </span>
        </h2>
        {isAddingHeading && (
          <div className="flex items-center gap-2 mb-4">
            <input
              type="text"
              value={newHeadingInput}
              onChange={(e) => setNewHeadingInput(e.target.value)}
              placeholder="Add new heading"
              className="border px-3 py-1 rounded-lg shadow-sm focus:outline-none  ml-3 "
            />

            <button onClick={handleAddNewHeading}>
              <FaCheck className="text-green-500 cursor-pointer mr-4 text-lg" />
            </button>
            <button
              onClick={() => {
                setNewHeadingInput("");
                setIsAddingHeading(false);
              }}
            >
              <FaTimes className="text-red-500 cursor-pointer text-lg" />
            </button>
          </div>
        )}
      </div>
      <div className="table-container" style={{ maxHeight: "400px", overflowY: "auto" }}>
        <DataTable
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[...new Set([rowsPerPage, 10, 20])]}
          paginationComponentOptions={{
            rowsPerPageText: "Rows per page:",
            rangeSeparatorText: "of",
            selectAllRowsItem: true,
            selectAllRowsItemText: "All",
          }}
          customStyles={{
            pagination: {
              style: {
                backgroundColor: 'white',
                position: 'sticky',
                bottom: 0,
                zIndex: 10,
                paddingBottom: '10px',
                paddingTop: '10px',
              },
            },
          }}
          onRowClicked={(row) => handleHeadingSelection(row.heading, row.type.toLowerCase())}
          conditionalRowStyles={[
            {
              when: (row) => true, // Applies to all rows
              style: {
                cursor: 'pointer', // Sets cursor to pointer on hover
              },
            },
          ]}
        />

      </div>

      <div
        className="flex justify-end"
        style={{
          position: 'sticky',
          bottom: 0,
          zIndex: 11,
          backgroundColor: 'white',
          padding: '10px',
          marginTop: '10px',
        }}
      >
        <button
          onClick={() => setCurrentStep(2)}
          className="bg-mainColor text-white px-4 py-2 rounded-full mt-3"
        >
          Next
        </button>
      </div>
    </div>
  );

  const renderStepTwo = () => {
    const minFrequency = Math.floor(
      Math.min(...Object.values(resultSample)) || 1
    ); // Default to 1 if resultSample is empty
    const maxFrequency = Math.ceil(
      Math.max(...Object.values(resultSample)) || 10
    ); // Default to 10 if resultSample is empty
    const keywordData = Object.entries(resultSample || {}).map(
      ([keyword, frequency]) => ({
        keyword,
        frequency: Math.max(frequency, minFrequency), // Make sure frequency is at least the minFrequency
      })
    );

    const handleFrequencyChange = (keyword, newFrequency) => {
      const clampedFrequency = Math.max(
        minFrequency,
        Math.min(newFrequency, maxFrequency)
      ); // Clamp frequency within range

      // Update the frequency in the resultSample state
      const updatedResultSample = { ...resultSample };
      updatedResultSample[keyword] = clampedFrequency;
      setResultSample(updatedResultSample);

      // Mark the keyword as edited in selectedKeywords
      setSelectedKeywords((prevSelected) => ({
        ...prevSelected,
        [keyword]: "edited",
      }));

      // Add to editedKeywords if not already present
      setEditedKeywords((prevEdited) => {
        if (!prevEdited.includes(keyword)) {
          const updatedEdited = [...prevEdited, keyword];
          console.log("Edited Keywords:", updatedEdited); // Log the edited keywords array
          return updatedEdited;
        }
        return prevEdited;
      });
    };

    const keywordColumns = [
      {
        name: "",
        cell: (row) => (
          <input
            type="checkbox"
            checked={selectedKeywords[row.keyword] || false}
            onChange={() => handleKeywordSelection(row.keyword)}
            className="accent-mainColor"
          />
        ),
        width: "10%",
      },
      {
        name: "Keyword",
        selector: (row) => row.keyword,
        sortable: true,
        width: "70%",
      },

      {
        name: "Frequency",
        selector: (row) => row.frequency,
        sortable: true,
        cell: (row) => (
          <input
            type="number"
            value={Math.floor(row.frequency)}
            onChange={(e) =>
              handleFrequencyChange(row.keyword, parseInt(e.target.value, 10))
            }
            step="1"
            min={minFrequency} // Use minFrequency as the minimum value
            max={maxFrequency} // Use maxFrequency as the maximum value
            className="border px-2 py-1 rounded w-[50px] no-spinner"
          />
        ),
      },
    ];

    return (
      <div>
        {/* Article Word Count Input Section */}

        <div className="flex">
          <h2 className="text-md mb-4 flex items-center justify-center">
            <span> Select or </span>
            <span
              className="underline text-blue-400 ml-2 cursor-pointer"
              onClick={() => setIsAddingKeyword(!isAddingKeyword)}
            >
              Add Keyword
            </span>
          </h2>
          {isGenerating && (
            <div

              className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
            bg-greeng 
            bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
            >
              <div className="flex flex-col items-center gap-4">
                <FaSpinner className="animate-spin text-white text-5xl" />
                <p className="text-white text-lg">{loadingMessage}</p>
              </div>
            </div>
          )}

          {isAddingKeyword && (
            <div className="flex items-center gap-2 mb-4">
              <input
                type="text"
                value={newKeywordInput}
                onChange={(e) => setNewKeywordInput(e.target.value)}
                placeholder="Add new keyword"
                className="border px-3 py-1 rounded-lg shadow-sm focus:outline-none ml-3"
              />
              <button onClick={handleAddNewKeyword}>
                <FaCheck className="text-green-500 cursor-pointer mr-4 text-lg" />
              </button>
              <button
                onClick={() => {
                  setNewKeywordInput("");
                  setIsAddingKeyword(false);
                }}
              >
                <FaTimes className="text-red-500 cursor-pointer text-lg" />
              </button>
            </div>
          )}
        </div>
        {/* Keyword Table */}
        <div className="max-h-[350px] overflow-y-auto border border-b">
          <DataTable
            columns={keywordColumns}
            data={keywordData.slice(0, articleWordCount * 0.05)}
            pagination

            highlightOnHover
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 15, 20]}
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: "All",
            }}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="block text-gray-700 mb-3">Article Word Count</label>
          <div className="relative">
            <input
              type="range"
              min={minCount}
              max={maxCount}
              value={articleWordCount}
              onChange={(e) => setArticleWordCount(Number(e.target.value))}
              className="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer range-slider-thumb"
              style={{
                background: `linear-gradient(to right, #22c55e ${((articleWordCount - minCount) / (maxCount - minCount)) * 100
                  }%, #D1D5DB ${((articleWordCount - minCount) / (maxCount - minCount)) * 100
                  }%)`,
              }}
            />

            <div className="absolute inset-x-0 top-0 flex justify-between text-gray-600 mt-4">
              <span
                className="absolute left-1/2 transform 
    -translate-x-1/2 text-center 
    flex items-center justify-center"
                style={{ top: "40px", left: "45px" }}
                title={`Word Count: ${articleWordCount}`} // Tooltip text
              >
                Count: {articleWordCount}
              </span>
            </div>
          </div>
        </div>


        {/* Navigation Buttons */}
        <div className="flex justify-end mt-6 relative">
          <button
            onClick={() => setCurrentStep(1)}
            className="bg-gray-500 text-white px-4 py-2 rounded-full mr-2"
          >
            Back
          </button>
          <button
            onClick={handleGenerateClick}
            className="flex items-center gap-2 bg-gradient-to-r from-sky-500 to-sky-300 text-white px-4 py-2 rounded-full shadow-lg transform transition duration-200 ease-in-out hover:scale-105"
          >
            Generate
          </button>
        </div>
      </div>
    );
  };

  const renderStepper = () => (
    <div className="flex items-center justify-center  w-[80%] mx-auto mb-6">
      <div
        className={`flex items-center justify-center w-8 h-8 rounded-full border-2 transition-all duration-300 ${currentStep === 1
          ? "bg-green-500 text-white border-green-500"
          : "bg-green-500 text-white border-green-500"
          }`}
      >
        1
      </div>
      <div
        className={`flex-1 h-1 mx-2 transition-all duration-300 ${currentStep === 2 ? "bg-green-500" : "bg-gray-300"
          }`}
      ></div>
      <div
        className={`flex items-center justify-center w-8 h-8 rounded-full border-2 transition-all duration-300 ${currentStep === 2
          ? "bg-green-500 text-white border-green-500"
          : "bg-white text-gray-300 border-gray-300"
          }`}
      >
        2
      </div>
    </div>
  );

  return (
    isOpen && (
      <div
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            onClose();
          }
        }}
        className="pointer-events-auto fixed inset-0 z-[1200] bg-black bg-opacity-30 grid h-screen w-screen place-items-center backdrop-blur-sm"
      >
        <div
          className="w-full  max-w-[700px] relative  px-4 py-6  bg-white rounded-lg shadow-lg overflow-y-auto "
        // Set max height to 90vh for the modal content
        >
          {renderStepper()}
          {currentStep === 1 ? renderStepOne() : renderStepTwo()}
        </div>
      </div>
    )
  );
};

export default StepForm;
