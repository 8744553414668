import React, { useRef, useState } from "react";
import { FaTrash, FaBookmark, FaSpinner } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import SearchBar from "../Search/Searchbar";
import AddQueryButton from "../Buttons/AddQueryButton";
import ExportButton from "../Buttons/ExportButton";
import DeleteBookmarkButton from "../Buttons/DeleteBookmarkButton";
import SkeletonLoader from "../../Skeleton/SkeletonLoader";
import { useOutsideClick } from "../../../hook";
import ResetFiltersButton from "../Buttons/ResetFiltersButton";

const TableHeaderControls = ({
  showAddProgressBar,
  completedAdds,
  checkedRows,
  favoritesData,
  selectedBookmarkName,
  hasFiltersApplied,
  filteredData,
  loading,
  tableData,
  managed,
  roles,
  handleBulkUpdate,
  isUpdateInProgress,
  handleBulkDelete,
  toggleBookmarkSelector,
  filteredBookmarks,
  handleAddToBookmark,
  bookmarkNames,
  setBookmarkNames,
  handleRenameBookmark,
  handleDeleteBookmark,
  userId,
  projectId,
  isCreatingBookmark,
  setIsCreatingBookmark,
  newBookmarkName,
  setNewBookmarkName,
  handleCreateBookmark,
  searchQuery,
  handleSearchInputChange,
  exportToExcel,
  handleResetFilters,
  stopOperation,
  show_BookmarkSelector,
  setShow_BookmarkSelector,
  open_Modal,
  editingBookmarkId,
  setEditingBookmarkId,
  isLoading,
  selectedBookmarkId,
  handleSelectAllClick,
  handleDeleteButtonBookmark,
  isDataLoading,
  selectedRows,
  filteredByRank,
  setFilteredByRank,
}) => {
  console.log(filteredByRank, "filteredByRank");

  const bookmarkSelectorRef = useRef(null);
  useOutsideClick(bookmarkSelectorRef, () => setShow_BookmarkSelector(false));

  const [isBookmarkCreating, setIsBookmarkCreating] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsBookmarkCreating(true);
      handleCreateBookmark().finally(() => {
        setIsBookmarkCreating(false);
      });
    }
  };

  const handleCreateBookmarkClick = () => {
    setIsBookmarkCreating(true);
    handleCreateBookmark().finally(() => {
      setIsBookmarkCreating(false);
    });
  };

  const filteredBookmarkOptions = filteredBookmarks.filter(
    (bookmark) => bookmark.b_id !== selectedBookmarkId
  );

  return (
    <div className="mb-4 border-2 border-gray-200 dark:border-gray-500 bg-white dark:bg-darkbg head-table">
      <div
        className="flex flex-col flex-wrap items-center
       justify-center gap-1 p-4 mx-0 overflow-auto md:flex-row lg:p-3
       md:justify-between md:overflow-visible md:mx-auto"
      >
        <div className="flex items-center justify-center mb-4 space-x-3 md:mb-0 md:justify-between text-gray-950 dark:text-gray-300 ">
          {checkedRows.length > 0 ? (
            <div className="rounded   flex items-center gap-2">
              <span className=" ">{checkedRows.length} selected</span>
              {checkedRows.length !== tableData.length &&
                !isUpdateInProgress && (
                  <button
                    type="button"
                    onClick={handleSelectAllClick}
                    className={` opacity-60 hover:opacity-100 cursor-pointer
                   rounded-r  text-mainColor underline font-bold  `}
                  >
                    Select All{" "}
                    {filteredByRank.length > 0 &&
                    filteredByRank.length !== tableData.length
                      ? filteredByRank.length
                      : tableData.length}
                    ?
                  </button>
                )}
            </div>
          ) : selectedBookmarkId ? (
            <span>
              {favoritesData} keyword{favoritesData !== 1 ? "s" : ""} in{" "}
              {selectedBookmarkName}
            </span>
          ) : hasFiltersApplied() ? (
            <>
              <span className="hidden md:block">
                {filteredData.length} keyword
                {filteredData.length !== 1 ? "s" : ""} matching filters
              </span>
              <span className="block md:hidden">{filteredData.length}</span>
            </>
          ) : loading ? (
            <SkeletonLoader />
          ) : showAddProgressBar && tableData.length === 0 ? (
            <span>
              {completedAdds}
              <span className="mx-1">
                keyword keyword{tableData.length !== 1 ? "s" : ""}
              </span>
            </span>
          ) : showAddProgressBar && tableData.length > 0 ? (
            <span>
              {tableData.length + completedAdds}
              <span className="mx-1">
                keyword
                {tableData.length !== 1 ? "s" : ""}
              </span>
            </span>
          ) : (
            <span>
              {filteredByRank.length > 0
                ? filteredByRank.length
                : filteredData.length}
              <span className="mx-1">
                keyword
                {filteredData.length !== 1 ? "s" : ""}
              </span>
            </span>
          )}

          {checkedRows.length > 0 && (
            <div className="flex items-center space-x-2">
              {/* update */}
              {(!managed || (managed && roles.Add_bulk_query)) && (
                <button
                  onClick={handleBulkUpdate}
                  disabled={isUpdateInProgress}
                  className={`flex items-center justify-center p-0 md:px-4 md:py-2 border-r-2 border-gray-200 ${
                    isUpdateInProgress ? "cursor-not-allowed text-gray-500" : ""
                  }`}
                >
                  <FiRefreshCw
                    className={`mr-2 ${isUpdateInProgress ? "spinner" : ""}`}
                  />
                  <span className="hidden sm:inline">Refresh</span>
                </button>
              )}
              {/* delete */}
              {(!managed || (managed && roles.Delete_bulk_query)) && (
                <button
                  onClick={handleBulkDelete}
                  className="flex items-center justify-center p-0 mr-2 border-r-2 border-gray-200 md:px-4 md:py-2"
                >
                  <FaTrash className="mr-2" />
                  <span className="hidden sm:inline">Delete</span>
                </button>
              )}

              <button
                onClick={toggleBookmarkSelector}
                className="flex items-center justify-center p-0 mr-2 border-r-2 border-gray-200 md:px-4 md:py-2"
              >
                <FaBookmark className="mr-2" />
                <span className="hidden sm:inline">Add to favourite</span>
              </button>

              {show_BookmarkSelector && (
                <div className="relative" ref={bookmarkSelectorRef}>
                  <div
                    onClick={(event) => {
                      if (event.target === event.currentTarget) {
                        setShow_BookmarkSelector(false);
                      }
                    }}
                    className="pointer-events-auto fixed inset-0 z-[999] grid h-screen w-screen 
                    place-items-center bg-opacity-10 opacity-100 bg-white dark:bg-darkbg
                      backdrop-blur-sm transition-opacity duration-300"
                  >
                    <div className="w-full max-w-lg p-2 bg-white dark:bg-darkbg rounded-lg boxing">
                      <div className="overflow-x-auto h-[300px]">
                        {filteredBookmarkOptions.length > 0 ? (
                          filteredBookmarkOptions.map((bookmark) => (
                            <div
                              key={bookmark.b_id}
                              className="flex justify-between p-2 cursor-pointer hover:bg-gray-200 "
                            >
                              <div
                                className="text-sm text-left w-full"
                                onClick={() => {
                                  handleAddToBookmark(bookmark.b_id);
                                  toggleBookmarkSelector();
                                }}
                              >
                                <span className="text-sm text-left w-full">
                                  {bookmark.name}
                                </span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="p-2 text-sm text-center text-gray-500">
                            No bookmarks found.
                          </div>
                        )}
                      </div>
                      <div className="w-full max-w-lg p-4 bg-white rounded-lg">
                        {isCreatingBookmark ? (
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="Bookmark name"
                              className="w-full p-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none"
                              value={newBookmarkName}
                              onChange={(e) =>
                                setNewBookmarkName(e.target.value)
                              }
                              onKeyPress={handleKeyPress}
                            />
                            {isBookmarkCreating ? (
                              <span className="absolute right-4 top-1/2 transform -translate-y-1/2 animate-spin">
                                <FaSpinner className="mr-2 animate-spin text-mainColor" />
                              </span>
                            ) : (
                              <button
                                onClick={handleCreateBookmarkClick}
                                className="absolute top-0 right-0 h-full px-4 text-white bg-mainColor rounded-r-md"
                              >
                                Enter
                              </button>
                            )}
                          </div>
                        ) : (
                          <div
                            className="p-2 text-sm font-bold text-center text-black transition-colors duration-200 ease-in-out bg-white cursor-pointer"
                            onClick={() => setIsCreatingBookmark(true)}
                          >
                            + create favourite
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className=" flex items-center md:justify-end justify-center space-x-2 overflow-auto md:overflow-visible ml-auto">
          <SearchBar
            searchQuery={searchQuery}
            handleSearchInputChange={handleSearchInputChange}
          />

          <AddQueryButton
            onClick={open_Modal}
            roles={roles}
            isDataLoading={isDataLoading}
          />
          {selectedRows.length > 0 && (
            <ExportButton onClick={exportToExcel} isLoading={isLoading} />
          )}

          {(hasFiltersApplied() || filteredByRank.length > 0) && (
            <ResetFiltersButton
              onClick={() => {
                handleResetFilters();
                setFilteredByRank([]);
              }}
            />
          )}
          {selectedBookmarkId && (
            <DeleteBookmarkButton onClick={handleDeleteButtonBookmark} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TableHeaderControls;
