import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { process_Keyword } from "../redux/lib/keywordSlice";
import { fetchUrlData } from "../redux/lib/urlSearch";
import { useLocation, useNavigate } from "react-router-dom";
// import { decrementDailyQuota } from "../redux/lib/auth";

const useKeywordAndUrlProcessing = (
  dispatch,
  userId,
  existingKeywords = []
) => {
  const [isKeywordLoading, setIsKeywordLoading] = useState(false);
  const [isUrlLoading, setIsUrlLoading] = useState(false);
  const [keywordData, setKeywordData] = useState(null);
  const [urlData, setUrlData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [domainLink, setDomainLink] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [links, setLinks] = useState([]);
  const [query, setQuery] = useState("");
  const [modalkeywordexist, setModalkeywordExist] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState();
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [selectedrefreshLinks, setSelectedRefreshLinks] = useState(false);
  const [isConfirmationrefreshOpen, setIsConfirmationRefreshOpen] = useState(false); // For refresh confirmation
  const [isRefreshing, setIsRefreshing] = useState(false); // To handle refresh state
  const [refreshloading, setIsRefreshLoading] = useState(false); // To handle refresh state
  const [isRefreshingClose, setIsRefreshingClose] = useState(false); // To handle refresh state
  const [button_refresh, set_button_refresh] = useState(true)

  const extractDomain = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.hostname;
    } catch (error) {
      return "Unknown Domain";
    }
  };
  console.log(isRefreshing, refreshloading, 'refreshloadingrefreshloading');
  useEffect(() => {

    if (isRefreshing) {
      console.log("Refreshing links for:", query);
      handleKeywordSubmit(query);

    }
  }, [isRefreshing, query]); // Depend on isRefreshing and query

  const handleKeywordSubmit = async (query) => {
    let keywordExists = existingKeywords.some((keyword) => keyword === query);
    set_button_refresh(false);

    if (isRefreshing) {
      keywordExists = false

    }

    if (keywordExists) {
      setLoadingMessage("");
      set_button_refresh(true)
      setShowDeleteButton(false);
      // dispatch(
      //   process_Keyword({ query, userId })
      // );
      setSelectedQuery(query);
      setModalkeywordExist(true); // Display a modal or alert
      return;
    }
    setLoadingMessage(`Processing ${query}`);

    setIsKeywordLoading(true);
    try {
      setShowDeleteButton(true);

      const response = await dispatch(
        process_Keyword({ query, userId })
      ).unwrap();
      setQuery(query); // Set the query state
      setKeywordData(response);
      setUrlData(null);
      // Extract domain if there are any links associated with the keyword data

      if (
        response.Query &&
        response.Weights &&
        response.Weights.filtered_links.length > 0
      ) {
        setLinks(response.Weights.filtered_links); // Pass filtered links here
        setQuery(response.Query);
        setModalVisible(true);
      } else if (response.Weights.filtered_links.length === 0) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/keyword/Delete-data/${userId}/${response.Query}/`,
          {
            method: "DELETE",
          }
        );
        toast.error("no valid urls")
      }
     
    } catch (error) {
      console.log(error, 'errorerrorerrorerror');

      // toast.error("Please add a valid keyword");
    } finally {
      setIsKeywordLoading(false);
      setLoadingMessage("");
      setIsRefreshing(false); // Reset isRefreshing after handling
      setIsRefreshLoading(false);
    }
  };

  const handleURLSubmit = async (url) => {
    const domainName = extractDomain(url);
    setLoadingMessage(`Processing ${domainName}`);


    setIsUrlLoading(true);
    try {
      const response = await dispatch(fetchUrlData({ url, userId })).unwrap();
      console.log(response, "urlData");

      setUrlData(response);

      setKeywordData(null);
      if (
        location.pathname.includes("urlData") ||
        location.pathname.includes("detailskeyword") ||
        location.pathname.includes("keywordresearch")
      ) {
        navigate(`/urlData/${response.object_id}`, {
          state: { data: response, type: "url" },
        });
      }

      toast.success("URL processed successfully");
      // dispatch(decrementDailyQuota(1));
    } catch (error) {
      toast.error("Please add a valid URL");
    } finally {
      setIsUrlLoading(false);
      setLoadingMessage("");

    }
  };
  const refreshLinks = (queryName) => {
    setIsRefreshLoading(true);
    setQuery(queryName);
    setIsRefreshing(true);
    setIsRefreshingClose(true);

  };


  const handleSubmit = (input) => {
    setLoadingMessage("Submitting input...");

    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // Protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // Domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IPv4
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+\\u00A0-\\uFFFF]*)*" + // Port and path, allowing Unicode characters and percent encoding
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // Query string
      "(\\#[-a-z\\d_]*)?$", // Fragment
      "i"
    );
    console.log(urlPattern, urlPattern.test(input), "urlData");

    const isURL = urlPattern.test(input);

    if (isURL) {
      handleURLSubmit(input);
      navigate(`${location.pathname}#details`); // Navigate after successful keyword processing
    } else {
      console.log(input, "inputinputinputinputinput");

      handleKeywordSubmit(input);
    }
  };
    const closeModal = () => {
    setModalVisible(false);
    set_button_refresh(true)

  };

  return {
    handleSubmit,
    isKeywordLoading,
    isUrlLoading,
    keywordData,
    urlData,
    closeModal,
    links,
    modalVisible,
    setModalVisible,
    handleKeywordSubmit,
    setModalkeywordExist,
    modalkeywordexist,
    selectedQuery,
    setLinks,
    setQuery,
    query,
    setKeywordData,
    setShowDeleteButton,
    showDeleteButton,
    loadingMessage,
    setLoadingMessage,
    refreshLinks,
    selectedrefreshLinks,
    setSelectedRefreshLinks,
    isConfirmationrefreshOpen,
    setIsConfirmationRefreshOpen,
    isRefreshing,
    setIsRefreshing,
    setIsRefreshLoading,
    refreshloading,
    setIsRefreshingClose,
    isRefreshingClose,
    button_refresh,
    set_button_refresh
  };
};

export default useKeywordAndUrlProcessing;
