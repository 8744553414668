import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import HeadingTitle from '../HeadingTitle';
import { useSelector } from 'react-redux';
import DeleteConfirmationModal from '../Modal/DeleteConfirmationModal';
import { RiTableFill } from "react-icons/ri";

import { toast } from 'react-toastify';
import AlertModal from '../Modal/AlertModal';
import { FaHashtag, FaThLarge, FaEdit, FaTrashAlt } from 'react-icons/fa';

const GeneratedArticlePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    Query,
    aggregatedResults,
    aggregatedResultsColumns,
    // combinedH2H3Columns,
    combinedH2H3DataArray

  } = location.state || {};
  console.log(combinedH2H3DataArray, "combinedH2H3Columns")
  const [sortedData, setSortedData] = useState(aggregatedResults || []);
  const [sortDirection, setSortDirection] = useState(null);
  //keyword_data
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  //sub_Heading
  const [selectedSubHeadingRows, setSelectedSubHeadingRows] = useState([]);
  const [isAllSubHeadingSelected, setIsAllSubHeadingSelected] = useState(false);
  const [history, setHistory] = useState([]);
  const [rangeValue, setRangeValue] = useState(550); // Default value in the middle of the range
  const token = useSelector((state) => state.authSlice.token)
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/keyword/generated-articles/`, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const historyData = response.data;
        console.log("Fetched history data:", historyData); // Debug log
        setHistory(historyData);
      } catch (error) {
        console.error('Error fetching history data:', error);
      }
    };

    fetchHistory();
  }, [token]);



  const handleRangeChange = (e) => {
    setRangeValue(parseInt(e.target.value));
  };

  const handleSort = (column) => {
    let sorted;
    let newSortDirection;

    if (sortDirection === 'asc') {
      sorted = [...sortedData].sort((a, b) => b.count - a.count);
      newSortDirection = 'desc';
    } else if (sortDirection === 'desc') {
      sorted = [...sortedData].sort((a, b) => a.count - b.count);
      newSortDirection = null;
    } else {
      sorted = [...sortedData];
      newSortDirection = 'asc';
    }
    setSortedData(sorted);
    setSortDirection(newSortDirection);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };
  const handleSubHeadingRowSelected = (state) => {
    const validSelections = state.selectedRows.filter(row => row.keyword && row.keyword !== "INVALID_SUBHEADING");
    setSelectedSubHeadingRows(validSelections.map(row => row.keyword));
    console.log("Selected Sub Headings:", validSelections);
  };


  const handleSelectAllSubHeadings = () => {
    if (selectedSubHeadingRows.length === combinedH2H3DataArray.length) {
      setSelectedSubHeadingRows([]);
    } else {
      setSelectedSubHeadingRows(combinedH2H3DataArray);
    }
  };
  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedRows([]);
      setIsAllSelected(false);
    } else {
      setSelectedRows(sortedData);
      setIsAllSelected(true);
    }
  };


  const handleTexteditor = (row) => {
    navigate(`/text/${row.title}/${row.id}/`, { state: { title: row.title, content: row.Generated_article } });
  };
  const handleViewToggle = () => {
    setViewMode(viewMode === 'grid' ? 'list' : 'grid');
  };




  const handleDelete = async () => {
    if (selectedArticle) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/keyword/generated-articles/${selectedArticle.id}/`, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });

        setHistory(history.filter((item) => item.id !== selectedArticle.id));
        setModalOpen(false);
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };
  const historyColumns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Edit',
      button: true,
      cell: (row) => (
        <FaEdit
          className="text-mainColor cursor-pointer"
          onClick={() => handleTexteditor(row)}
        />
      ),
    },
    {
      name: 'Delete',
      button: true,
      cell: (row) => (

        <FaTrashAlt
          className="text-red-500 cursor-pointer"
          onClick={() => openDeleteModal(row)}
        />

      ),
    },
  ];

  const handleGenerateArticle = async () => {
   
    // Ensure all headings are valid and filter out any invalid ones
    const headings = selectedSubHeadingRows.filter(subHeading => subHeading && subHeading !== "INVALID_SUBHEADING");
  
    if (headings.length === 0) {
      alert("Invalid selection. Please select valid subheadings.");
      return;
    }
  
    const title = Query || "Default Title";
  
    const data = {
      title: title,
      headings: headings, // Send the array of valid strings
      word_count: rangeValue,
    };
  
    console.log('Submitting the following data:', data);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/keyword/generated-articles/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const generatedArticle = response.data;
      console.log('Submission successful, response:', generatedArticle);
  
      navigate(`/text/${generatedArticle.title}/${generatedArticle.id}/`,
         { state: { title: generatedArticle.title, content: generatedArticle.Generated_article } });
      toast.success(`${title} generated successfully`);
  
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setAlertMessage("Failed to submit the article. You have exceeded your limit. You must delete a generated article to be able to create a new one.");
        setAlertModalOpen(true);
      } else {
        setAlertMessage("Failed to submit the article. An unexpected error occurred.");
        setAlertModalOpen(true);
      }
    }
  };
  







  const combinedH2H3Columns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,

    },
    {
      name: "Count",
      selector: (row) => row.count,
      sortable: true,
      // width:"20%"
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      // width:"20%"

    },
  ];
  const openDeleteModal = (article) => {
    setSelectedArticle(article);
    setModalOpen(true);
  };
  const handleSelectSubHeading = (keyword) => {
    if (keyword && keyword !== "INVALID_SUBHEADING") { // Ensure the keyword is valid
      if (selectedSubHeadingRows.includes(keyword)) {
        setSelectedSubHeadingRows(selectedSubHeadingRows.filter((item) => item !== keyword));
      } else {
        setSelectedSubHeadingRows([...selectedSubHeadingRows, keyword]);
      }
    }
  };
  const filteredTags = combinedH2H3DataArray.filter(item =>
    item.keyword.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const columns = aggregatedResultsColumns?.map(column => ({
    name: column.name,
    selector: column.name === 'Keyword'
      ? (row) => row.keyword
      : (row) => Math.round(row.count),
    sortable: true,
    sortFunction: handleSort,
  })) || [];
  return (
    <div className="pt-[6rem] w-full min-h-screen rounded-lg relative p-4">
      <div className="   w-full">
        <div className="flex flex-col sm:flex-row sm:justify-between my-4">
          <div className="mb-4 sm:mb-0">
            <HeadingTitle title={Query} />
          </div>
        </div>
        {selectedRows.length > 0 && (
          <div className="mt-4 text-left">
            <p>{`${selectedRows.length} selected `}
              <span
                className="text-mainColor underline cursor-pointer font-semibold"
                onClick={handleSelectAll}
              >
                {isAllSelected ? `Unselect All?` : `Select All ${sortedData.length}?`}
              </span>
            </p>
          </div>
        )}
        {selectedSubHeadingRows.length > 0 && (
          <div className="mt-4 text-left">
            <p>{`${selectedSubHeadingRows.length} selected in Sub Headings `}
              <span
                className="text-mainColor underline cursor-pointer font-semibold"
                onClick={handleSelectAllSubHeadings}
              >
                {isAllSubHeadingSelected ? `Unselect All?` : `Select All ${combinedH2H3DataArray.length}?`}
              </span>
            </p>
          </div>
        )}
        <div className='flex flex-wrap justify-between gap-5 '>
          {viewMode === 'grid' ? (
            <div className="flex-1 border-2 mt-3 border-gray-200 dark:border-gray-500 rounded-md">
              {combinedH2H3DataArray.length > 0 ? (
                <DataTable
                  title="Sub Heading"
                  className="custom-checkbox"
                  columns={combinedH2H3Columns}
                  data={combinedH2H3DataArray}
                  defaultSortField="count"
                  defaultSortAsc={true}
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20, 50]}
                  paginationComponentOptions={{ noRowsPerPage: true }}
                  selectableRows
                  onSelectedRowsChange={handleSubHeadingRowSelected}
                />
              ) : (
                <p>No Sub Headings data available</p>
              )}
            </div>
          ) : (
            <div className="flex flex-wrap gap-3 overflow-y-scroll p-10 border-2 border-gray-200 rounded-3xl custom-scrollbar max-h-60">
              {filteredTags.length > 0 ? (
                filteredTags.map((item, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer rounded-2xl text-white ${selectedSubHeadingRows.includes(item.keyword) ? 'opacity-25 cursor-not-allowed' : 'opacity-90 hover:opacity-100'}`}
                    onClick={() => handleSelectSubHeading(item.keyword)}
                  >
                    <div className="flex items-center">
                      <span className="border-r-2 p-1 text-md text-white bg-[#2ba950a6] rounded-l-2xl">{item.type}</span>
                      <span className="p-1 bg-mainColor rounded-r-2xl">{item.keyword}</span>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Sub Headings data available</p>
              )}
            </div>
          )}
        </div>
        <DeleteConfirmationModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={handleDelete}  // Confirm deletion on modal confirmation
          title={selectedArticle?.title}
        />
        <AlertModal
          isOpen={alertModalOpen}
          onClose={() => setAlertModalOpen(false)}
          message={alertMessage}
        />
      </div>
    </div>
  );
};
export default GeneratedArticlePage;