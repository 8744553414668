import { useState } from 'react';
import { toast } from 'react-toastify';

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        toast.info(`Copied "${text}" to clipboard successfully`, {
          position: 'bottom-center',
          autoClose: 5000
        });
        setIsCopied(true);
      }, (err) => {
        console.error('Error copying text to clipboard: ', err);
        toast.error('Error copying text to clipboard', {
          position: 'bottom-center',
          autoClose: 5000
        });
        setIsCopied(false);
      });
    } 
    // else {
    //   fallbackCopyTextToClipboard(text);
    // }
  };

  return { isCopied, copyToClipboard };
};

export default useCopyToClipboard;
