import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import createLogQuery from "../../pages/SidebarPages/Log/createLogQuiries";
import { extractDomainName } from "../../utils/Functions";
import { useDispatch } from "react-redux";
import fetchData from "./fetchData";
import { useLocation } from "react-router-dom";
const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};
export const deleteProjects = createAsyncThunk(
  "ranks/deleteProjects",
  async (query_ids, { getState, rejectWithValue }) => {
    let rank = JSON.parse(sessionStorage.getItem("rank")) || [];
    const state = getState();
    const userId = state.authSlice.id; // Assuming this is where the userId is stored
    const token = state.authSlice.token; // And the token
    const originalUser = state.originalUserSlice.name;
    const queryChunks = chunkArray(query_ids, 10);
    // const dispatch = useDispatch();
    // const location=useLocation()
    // console.log(location.pathname.split("/"[1]),'dcdcscs');
    
    // const deleteRequests = query_ids.map((query_id) => {
    //   const url = `${process.env.REACT_APP_API_URL}/api/delete-rank/${userId}/${query_id}/`;
    //   console.log(url, "url");
    //   return axios
    //     .delete(url, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res.data.query, "url");

    //       rank =
    //         typeof rank === "string" ? [rank] : rank.concat(res.data.query);
    //       let newRank = [...new Set(rank)];
    //       sessionStorage.setItem("rank", JSON.stringify(newRank));
    //       const domainName = extractDomainName(res.data.target_url);
    //       const logMessage = `Query: ${res.data.query} Deleted with Target_url : ${res.data.target_url} in project ${res.data.project} and it's Rank ${res.data.query} - ${res.data.project} by ${originalUser}`;
    //       createLogQuery(logMessage, userId);
    //     });
    // });

    try {
      // Process each chunk of delete requests sequentially
      for (const chunk of queryChunks) {
        const deleteRequests = chunk.map((query_id) => {
          const url = `${process.env.REACT_APP_API_URL}/api/delete-rank/${userId}/${query_id}/`;
          return axios
            .delete(url, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              rank =
                typeof rank === "string" ? [rank] : rank.concat(res.data.query);
              let newRank = [...new Set(rank)];
              sessionStorage.setItem("rank", JSON.stringify(newRank));
              const domainName = extractDomainName(res.data.target_url);
              const logMessage = `Query: ${res.data.query} Deleted with Target_url : ${res.data.target_url} in project ${res.data.project} and it's Rank ${res.data.query} - ${res.data.project} by ${originalUser}`;
               createLogQuery(logMessage, userId);
            });
        });

        // Await the completion of all delete requests in the current chunk
        await Promise.all(deleteRequests);

      }

      return query_ids; // Return the array of deleted query_ids on success
    } catch (error) {
      console.error("Error in deleteProjects thunk:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
const deleteProjectBulkSlice = createSlice({
  name: "rank",
  initialState: {
    ranks: [],
    status: "idle",
    error: null,
  },
  reducers: {
    // Define your reducers here if you have any
  },
  extraReducers: (builder) => {
    builder
      // Use deleteProjects.pending, .fulfilled, and .rejected
      .addCase(deleteProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProjects.fulfilled, (state, action) => {
        state.status = "succeeded";
        // This assumes your payload is the array of deleted query_ids
        // Adjust if your API response structure is different
        state.ranks = state.ranks.filter(
          (rank) => !action.payload.includes(rank.query_id)
        );
      })
      .addCase(deleteProjects.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message; // Use action.error.message to get the error message
      });
  },
});

export default deleteProjectBulkSlice.reducer;
