import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk for handling the API request
export const fetchUrlData = createAsyncThunk(
  "url/fetchUrlData",
  async ({ url, userId }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/process_url/${userId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url }), // Send the URL as part of the request body
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // await fetch(
      //   `${process.env.REACT_APP_API_URL}/api/Updatequote/${userId}/`,
      //   {
      //     method: "PUT",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       daily_quota_KR: 5
      //     })
      //   }
      // );
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const UrlSlice = createSlice({
  name: "Url",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchUrlData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUrlData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchUrlData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default UrlSlice.reducer;
