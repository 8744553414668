import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Profile from "../Profile";
import ConfirmationDialogg from "../Modal/ConfirmationDialogg";
import { login, setManaged } from "../../redux/lib/auth";
import createLog from "../../pages/SidebarPages/Log/createLog";
import ManagedUserInfo from "./ManagedUserInfo";
import FileUpload from "./FileUpload";
import logo from "../../assets/logo/Rankteradark.png";
import { AiOutlineMenu } from "react-icons/ai";

const Navbar = ({ show, toggle, setProcessCompleted, processComplete }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.authSlice.id);
  const managed = useSelector((state) => state.authSlice.managed);
  const managedUserName = useSelector((state) => state.authSlice.user.name);
  const originalUser = useSelector((state) => state.originalUserSlice);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    setProjectId(pathSegments.length > 2 ? pathSegments[2] : "");
  }, [location.pathname]);

  useEffect(() => {
    if (localStorage.getItem("reloadWithHash") === "true") {
      localStorage.removeItem("reloadWithHash");
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  const revertToOriginalUser = () => {
    if (originalUser) {
      dispatch(
        login({
          token: originalUser.token,
          id: originalUser.id,
          name: originalUser.name,
          email: originalUser.email,
          verified: originalUser.verified,
          Roles: [],
        })
      );
      dispatch(setManaged(false));
      toast.info("Returned to your original account");
      createLog(`Logout by ${originalUser.name}`, userId);
      navigate("/projects");
      // navigate("/Account");
    } else {
      toast.error("No original user data found. Please log in again.");
    }
  };

  return (
    <div>
      <div
        className={`fixed inset-0 top-0 flex items-center justify-between
       h-16 py-0 pr-10 md:pr-0 
     first-letter:md:pr-3 pl-5
      bg-white border-b-2 border-gray-200
       dark:border-gray-500 dark:bg-slate z-[1000]`}
      >
        <div className="md:flex items-center gap-7 hidden">
          {!show && (
            <>
              <button onClick={toggle} className="text-mainColor w-fit">
                <AiOutlineMenu size={24} />
              </button>
              <Link
                to="/projects"
                className="transition duration-150 ease-in-out"
              >
                <img src={logo} alt="logo" className="md:w-36 w-20" />
              </Link>
            </>
          )}
          <button className="relative mr-48 text-lg font-bold text-greeng">
            {location.pathname === "/" && (
              <span className="ml-2">All Data</span>
            )}
          </button>
          {managed && (
            <ManagedUserInfo
              managedUserName={managedUserName}
              revertToOriginalUser={revertToOriginalUser}
            />
          )}
        </div>
        <div className="flex-1" />
        <div className="flex items-center justify-end p-4">
          {location.pathname.includes("/projects/") && (
            <FileUpload
              projectId={projectId}
              userId={userId}
              setProcessCompleted={setProcessCompleted}
              processComplete={processComplete}
            />
          )}
        </div>
        <ConfirmationDialogg
          isOpen={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => {
            setShowConfirmDialog(false);
            window.location.reload();
          }}
          message="The data has been uploaded. Do you want to refresh the page to see the new data?"
          btnText="Refresh"
        />
        <div className="flex md:pr-10">
          <Profile />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
