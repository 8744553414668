import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { FaSpinner, FaSync } from "react-icons/fa";
import Confirmation from "./Confirmation";

const LinkModal = ({
  isOpen,
  onClose,
  links = [],
  filteredLinks,
  query,
  onSelectionComplete,
  showDeleteButton,
  refreshLinks,
  selectedrefreshLinks,
  setSelectedRefreshLinks,
  isConfirmationrefreshOpen,
  setIsConfirmationRefreshOpen,
  isRefreshing,
  setIsRefreshing,
  setIsRefreshLoading,
  refreshloading,
  setIsRefreshingClose,
  isRefreshingClose,
  modalVisible,
  handleSubmit,
  close_refresh,
  set_close_refresh,
  button_refresh, update
}) => {
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const maxSelection = 5;
  const userId = useSelector((state) => state?.authSlice?.user.id);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // State for delete confirmation modal
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submit state
  const [loadingMessage, setLoadingMessage] = useState(""); // Message for the loading overlay

  useEffect(() => {

    if (filteredLinks) {
      const combinedResults = links
        .map(item1 => {
          const matchingItems = filteredLinks.filter(item2 => item2.link === item1.link);
          if (matchingItems.length > 0) {
            return { ...item1, matches: matchingItems };
          }
          return null;
        })
        .filter(Boolean);
      console.log(combinedResults, links, filteredLinks, 'combinedResultscombinedResults');
      setSelectedLinks(selectedrefreshLinks ? [] : combinedResults.slice(0, 5));
      // setSelectedRefreshLinks(false);


    }

    if (!filteredLinks && isOpen && links.length > 0) {
      // Sort links by rank, with rank 1 being the highest
      const sortedLinks = [...links].sort((a, b) => a.rank - b.rank);

      // Select top 5 based on sorted rank
      const initialSelection = sortedLinks.slice(0, maxSelection).map((link) => ({
        link: link.link,
        rank: link.rank,
      }));

      setSelectedLinks(selectedrefreshLinks ? [] : initialSelection);
    }
  }, [isOpen, links, selectedrefreshLinks]);
  console.log("selectedLinksselectedLinksselectedLinks", filteredLinks, selectedrefreshLinks, selectedLinks);

  if (!isOpen) return null;
  const extractDomain = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.startsWith("www.") ? domain.slice(4) : domain;
    } catch (error) {
      return "Invalid URL";
    }
  };
  const sortedLinks = [...links].sort((a, b) => a.rank - b.rank);

  const data = sortedLinks.map((link) => ({
    domain: extractDomain(link.link),
    ...link,
  }));



  const handleCheckboxChange = (row) => {

    const linkWithRank = row;

    const isSelected = selectedLinks.some(
      (selected) => selected.link === linkWithRank.link
    );

    if (isSelected) {
      setSelectedLinks(
        selectedLinks.filter((selected) => selected.link !== linkWithRank.link)
      );

      setErrorMessage("");
    }
    else if (selectedLinks.length < maxSelection) {

      setSelectedLinks([...selectedLinks, linkWithRank]);
      setErrorMessage("");
    } else {
      setErrorMessage(`You can only select up to ${maxSelection} links.`);
    }
  };
  const handle_Submit = async () => {
    setIsSubmitting(true); // Start buffering state

    const ranks = selectedLinks.map((link) => link.rank);
    const endpoint = `${process.env.REACT_APP_API_URL
      }/keyword/Query-data-select/${userId}/${encodeURIComponent(query)}/`;

    try {
      const response = await fetch(endpoint, {
        method: "PUT",
        headers: {
          // Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ranks }),
      });

      if (response.ok) {
        const responseData = await response.json(); // Get the response data

        onSelectionComplete(responseData);
        // onClose();
        setSelectedRefreshLinks(false)
      } else {
        const errorData = await response.json();
        console.error("Error submitting ranks:", errorData);
        setErrorMessage("Failed to submit the selected ranks.");
      }
    } catch (error) {
      console.error("Error submitting ranks:", error);
      setErrorMessage("An error occurred while submitting the selected ranks.");
    } finally {
      setIsSubmitting(false); // End buffering state
    }
  };
  const columns = [
    {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedLinks.some((selected) => selected.link === row.link)}
          onChange={() => handleCheckboxChange(row)}
          className="accent-mainColor"
        />
      ),

    },
    {
      name: "Rank",
      selector: (row) => row.rank,
      sortable: true,


    },
    {
      name: "Domain",
      selector: (row) => row.domain,
      cell: (row) => (
        <a href={row.url} target="_blank" rel="noopener noreferrer">
          {row.domain}
        </a>
      ),
      sortable: true,
    },
  ];

  const onConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword/Delete-data/${userId}/${query}/`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setIsConfirmationOpen(false);
        onClose();
        // setSelectedRefreshLinks(true)

      } else {
        console.error(`Failed to remove item: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error removing item");
      console.error("Error removing item:", error);
    } finally {
      setIsDeleting(false);
    }
  };
  const handleRefreshClick = () => {
    setIsConfirmationRefreshOpen(true);
  };



  const handleConfirmRefresh = () => {
    // setIsRefreshing(true); 
    // setIsRefreshLoading(true)

    setLoadingMessage("Refreshing...");
    setSelectedRefreshLinks(true);
    refreshLinks()
    setIsConfirmationRefreshOpen(false);

  };


  const handleClose = () => {
    onClose();
    setSelectedRefreshLinks(false);

  };


  return (
    <div
      className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
     bg-greeng 
     bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"

    >



      <div
        className="w-full max-w-lg p-4 bg-white rounded-lg  overflow-y-scroll border-2 border-gray-300 "
        onClick={(e) => e.stopPropagation()}
      >


        <h2 className=" font-semibold mb-4  flex">
          <span className="relative group text-lg ">
            Select Domain Links

          </span>
          {refreshLinks && button_refresh && (

            <>
              <button
                onClick={handleRefreshClick}
                className="ml-auto flex items-center gap-1  text-mainColor px-2 py-1  rounded-lg
             "
              >

                <FaSync className="mr-1" /> Refresh
              </button>
            </>
          )}


        </h2>

        <DataTable columns={columns} data={data} pagination />
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
        <div className="flex justify-end space-x-3">

          {(!isRefreshingClose || update) &&
            <button
              // onClick={showDeleteButton ? onConfirmDelete : onClose}
              onClick={handleClose}
              className="mt-4 ml-4 px-4 py-2 bg-[#D1D5DB] text-black font-bold rounded-lg"
            >
              Cancel
            </button>
          }

          <button
            onClick={handle_Submit}
            disabled={isSubmitting}
            className={`mt-4 px-6 py-2 font-bold rounded-lg flex items-center justify-center
    ${isSubmitting ? 'bg-gray-400 text-gray-100 cursor-not-allowed' : 'bg-mainColor text-white hover:bg-mainColor-dark'}`}
          >
            {isSubmitting ? (
              <>
                <FaSpinner className="animate-spin mr-2 text-white" />
                <span>Submitting...</span>
              </>
            ) : (
              "Submit"
            )}
          </button>



          <Confirmation
            isOpen={isConfirmationrefreshOpen}
            onClose={() => setIsConfirmationRefreshOpen(false)}
            onConfirm={handleConfirmRefresh}
            message="Are you sure you want to refresh and clear data selected links?"
            isDeleting={isDeleting}
            keyword="refresh"
          />
        </div>
        {refreshloading && (
          <div
            className="pointer-events-auto fixed inset-0 z-[1300] flex items-center justify-center bg-black bg-opacity-70"
          >
            <div className="flex flex-col items-center gap-4">
              <FaSpinner className="animate-spin text-mainColor text-5xl" />
              <p className="text-white text-lg">{loadingMessage}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkModal;
