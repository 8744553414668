import React, { useEffect, useState } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import { RiShieldCheckLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";

const AddPaymentMethod = () => {
  const location = useLocation();
  const [planId, setPlanId] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  useEffect(() => {
    // Extract query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const planIdFromUrl = searchParams.get("planId");
    const typeFromUrl = searchParams.get("type");

    // Update the state with the parameters
    setPlanId(planIdFromUrl);
    setPaymentType(typeFromUrl);
  }, [location.search]);

  return (
    <div className="pt-[6rem] flex flex-col items-center px-4">
      <div className="flex flex-col md:flex-row justify-center items-center md:py-[30px] py-[40px] px-4 md:px-20">
        {/* Left Section: Payment Form */}
        <div className="w-full md:w-1/2 p-8 mb-8 md:mb-0 md:mr-8">
          <h2 className="text-[24px] font-bold mb-2">
            {planId === "1" ? "Basic Plan" : planId === "2" ? "Pro Plan" : "Enterprise Plan"} - {paymentType === "yearly" ? "Yearly" : "Monthly"} Billing
          </h2>
          <p className="mt-[24px] font-bold text-2xl">
            Your Account: <span className="font-light">email@email.com</span>
          </p>
          <p className="mt-[24px] font-bold text-2xl grid grid-cols-3 items-center gap-2">
            <span className="col-span-1">Billing:</span>
            <span className="col-span-1 flex items-center justify-center font-light">
              {paymentType === "yearly" ? "Yearly" : "Monthly"}
            </span>
          </p>

          <form className="space-y-4 mt-[20px]">
            <input
              type="text"
              placeholder="Card Number"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
            />
            <div className="flex space-x-4 py-[20px]">
              <input
                type="text"
                placeholder="MM/YY"
                className="w-1/2 p-3 border border-gray-300 rounded-md focus:outline-none"
              />
              <input
                type="text"
                placeholder="CVV"
                className="w-1/2 p-3 border border-gray-300 rounded-md focus:outline-none"
              />
            </div>
            <input
              type="text"
              placeholder="Card Holder Name"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
            />
            <div className="flex w-full items-end justify-end">
              <button
                type="submit"
                className="bg-mainColor text-white rounded-md font-bold text-[14px] mt-4 px-[39px] py-[7px]"
              >
                Subscribe
              </button>
            </div>
          </form>

          <div className="flex items-center justify-center text-mainColor text-sm mt-[16px] mx-auto">
            <RiShieldCheckLine className="mr-2" />
            <p className="text-[16px]">Your Data is Properly Secured</p>
          </div>
        </div>

        {/* Right Section: Trial Information */}
        <div className="w-full md:w-1/2 p-8 h-[600px] border-2 border-gray-200 rounded-[24px]">
          <h2 className="font-bold text-[24px] w-full text-gray-800 mb-4 flex items-center justify-center pt-[78px]">
            How free trial works
          </h2>
          <ul className="space-y-4">
            <li className="flex items-start">
              <FaRegCheckCircle className="text-mainColor mt-1 mr-2 text-xl" />
              <div>
                <p className="font-bold text-md">Today: Instant access</p>
                <p className="text-gray-600 text-sm mt-2">
                  By enrolling, you are signing up for a renewing monthly plan with a 7-day free trial.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <FaRegCheckCircle className="text-gray-400 mt-1 mr-2 text-xl" />
              <div>
                <p className="font-bold text-md">Day 1 - Day 7</p>
                <p className="text-gray-600 text-sm mt-2">Enjoy all features Explore Ranktera for free.</p>
              </div>
            </li>
            <li className="flex items-start">
              <FaRegCheckCircle className="text-gray-400 mt-1 mr-2 text-xl" />
              <div>
                <p className="font-bold text-md">Day 7: End of trial</p>
                <p className="text-gray-600 text-sm mt-2">You will be charged after the trial ends.</p>
              </div>
            </li>
          </ul>
          <p className="mt-4 font-light md:pt-[67px] w-full width-[80%] mx-auto pt-[10px]">
            You can cancel the 7-day free trial anytime, as well as your monthly subscription.{" "}
            <span className="underline cursor-pointer">Cancellation Policy</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddPaymentMethod;
