// redux/lib/originalUserSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  id: null,
  name: null,
  email: null,
  verified: null,
  provider: null

};

const originalUserSlice = createSlice({
  name: 'originalUser',
  initialState,
  reducers: {
    setOriginalUser: (state, action) => {
      const { token, id, name, email, verified, provider } = action.payload;
      state.token = token;
      state.id = id;
      state.name = name;
      state.email = email;
      state.verified = verified;
      state.provider = provider

    },
    clearOriginalUser: (state) => {
      state.token = null;
      state.id = null;
      state.name = null;
      state.email = null;
      state.verified = null;
      state.provider = null

    },
  },
});

export const { setOriginalUser, clearOriginalUser } = originalUserSlice.actions;
export default originalUserSlice.reducer;
