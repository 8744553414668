import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, setManaged } from "../../redux/lib/auth";
import { setRoles } from "../../redux/lib/roleSlice";
import createLog from "../../pages/SidebarPages/Log/createLog";
import { setOriginalUser } from "../../redux/lib/originalUserSlice";
import customTableStylesLight from "../customTableStyles";
import customTableStylesDark from "../customTableStylesDark";

const InvitationsTable = () => {
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.authSlice.id);
  const name = useSelector((state) => state.authSlice.user.name);

  const managed = useSelector((state) => state.authSlice.managed);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch invitations when the component mounts or userId changes
  useEffect(() => {
    const fetchInvitations = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/usemanged/${userId}/`
        );
        setInvitations(response.data);
      } catch (err) {
        console.error("Failed to fetch invitations:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchInvitations();
  }, [userId]);

  // Handle managing an invitation
  const manageInvitation = async (invitationId, creatorId) => {
    createLog(`Login by ${name} `, creatorId); // Create a log for login
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login-by-id/${invitationId}/${creatorId}/`
      );

      if (
        response.data &&
        response.data.token &&
        response.data.Roles.length > 0
      ) {
        const {
          token,
          id,
          Name,
          Email,
          "Email Verified": verified,
          Roles,
        } = response.data;

        if (managed) {
          dispatch(
            setOriginalUser({
              token: token,
              id: userId,
              name: Name,
              email: Email,
              verified: verified,
            })
          );
        }

        // Dispatch actions to update the Redux store with the new user details
        dispatch(
          login({
            token,
            id,
            name: Name,
            email: Email,
            verified,
            Roles,
          })
        );

        dispatch(setRoles(Roles[0]));
        dispatch(setManaged(true));

        // Navigate to the account page
        navigate("/account");
      }
    } catch (error) {
      console.error("Error during login management:", error);
      dispatch(setManaged(false));
    }
  };

  // Define columns for the DataTable
  const columns = [
    {
      name: "Inviter",
      selector: (row) => row.creator_info.username,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          onClick={() =>
            manageInvitation(row.invitee_info.id, row.creator_info.id)
          }
          className="px-4 py-2 text-white rounded-lg bg-mainColor hover:bg-mainColor focus:outline-none"
        >
          Manage
        </button>
      ),
   
    },
  ];
  const isDarkMode = localStorage.getItem("darkMode") === "true";
  const customTableStyles = isDarkMode
    ? customTableStylesDark
    : customTableStylesLight;

  return (
    <div>
      <h2 className="mt-4 mb-3 md:text-lg text-lg font-bold   dark:text-gray-300">
        Users I Can Manage
      </h2>
    
      <DataTable
        customStyles={customTableStyles}
        columns={columns}
        data={invitations}
        progressPending={loading}
        persistTableHead
        highlightOnHover
        pagination
      />
    </div>
  );
};

export default InvitationsTable;
