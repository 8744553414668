// selectors.js
import { createSelector } from "reselect";

const selectAuthSlice = (state) => state.authSlice;
const selectOriginalUserSlice = (state) => state.originalUserSlice;

export const selectAuthDetails = createSelector(
  [selectAuthSlice],
  (authSlice) => ({
    userId: authSlice.id,
    managed: authSlice.managed,
    token: authSlice.token,
    NameUser: authSlice.user.name,
    verified: authSlice.user.verified,
    provider:authSlice.provider,
    EmailUser:authSlice.user.email
  })
);
export const selectOriginalUserName = createSelector(
    [selectOriginalUserSlice],
    (originalUserSlice) => originalUserSlice.name
  );
  
export const selectOriginalDetails = createSelector(
  [selectOriginalUserSlice],
  (originalUserSlice) => originalUserSlice
);
