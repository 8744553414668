import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './layout/Layout';
import Login from './pages/auth/Login';
import PrivateRoutes from './utils/PrivateRoutes';
import Register from './pages/auth/Register';
import { ToastContainer } from 'react-toastify';
import RegistrationToken from './pages/Checking/RegistrationToken';
import CheckExecitance from './pages/Checking/CheckExecitance';
import VerifiedEmail from './pages/Checking/VerifiedEmail';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPassword';
import CheckEmailPage from './pages/CheckEmailPage';
import FeedbackButton from './components/FeedbackButton';

const App = () => {
  const [showTooltip, setShowTooltip] = useState(false);
const location=useLocation();
  return (
    <div className='h-screen'>

      <ToastContainer
        position="top-right"

        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path='/*' element={<Layout />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/CheckExecitance/:token' element={<CheckExecitance />} />
        <Route path='/verified/:token' element={<VerifiedEmail />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
        <Route path="/check-email" element={<CheckEmailPage />} />
        <Route path="/register/:token" element={<RegistrationToken />} />

      </Routes>

      <div
        className="relative inline-block  w-screen "
       
      >
        {!location.pathname.includes('/projects/') && (

        <div className=" absolute"  onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}>
        
            <FeedbackButton onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
          

          {showTooltip && (
            <div
              className="fixed bottom-16 z-[100000] bg-black text-white text-sm p-2 rounded shadow-md
                whitespace-nowrap right-10
                before:content-[''] before:absolute before:top-[-8px] before:left-1/2 before:border-6
                before:border-transparent before:border-b-black before:-translate-x-1/2"
            >
              Send us your feedback!
            </div>
          )}
        </div>
)}
      </div>

    </div>

  );
};

export default App;
