import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AccountDetails from "../SidebarPages/Account";
import Projectranks from "../../components/Projectranks";
import Log from "../SidebarPages/Log/Log";
import DetailsKeyword from "../../components/keyword_research/keyword/DetailsKeyword";
import MainKeywordResearch from "../MainKeyword/keyword/MainKeywordResearch";
import FeedBackForm from "../FeedBack/FeedBackForm";
import DetailsUrl from "../../components/keyword_research/Url/DetailsUrl";
import Projects from "../SidebarPages/Projects/Projects";
import ProjectCompetitors from "../ProjectCompetitors";
import SingleProject from "../SingleProject";
import RichTextEditor from "../../components/RichTextEditor";
import GeneratedArticlePage from "../../components/keyword_research/GeneratedArticlePage";
import MainKeywordResearchTest from "../MainKeyword/keyword/MainKeywordResearchTest";
import GeneratedURLPage from "../../components/keyword_research/GeneratedURLPage";
import Count from "../Count";
import Help from "../Help";

const Index = ({
  processComplete,
  activeProject,
  setActiveProject,
  setTopThreeProjects,
  topThreeProjects,
  pin_project,
  setPin_project,
  show
}) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/projects" replace />} />
      <Route
        path="/projects"
        element={
          <Projects
            activeProject={activeProject}
            setActiveProject={setActiveProject}
            topThreeProjects={topThreeProjects}
            setTopThreeProjects={setTopThreeProjects}
            pin_project={pin_project}
            setPin_project={setPin_project}
          />
        }
      />
      <Route
        path="/projects/:projectId"
        element={
          <Projectranks
            processComplete={processComplete}
            pin_project={pin_project}
            setPin_project={setPin_project}
          />
        }
      />
      <Route path="/project/:projectId" element={<SingleProject />} />

      <Route path="/Account" element={<AccountDetails />} />
      <Route path="/help" element={<Help />} />

      <Route path="/Log" element={<Log />} />
      <Route path="/main/keywordresearch" element={<MainKeywordResearch />} />
      <Route path="/main/keywordresearchTest" element={<MainKeywordResearchTest />} />

      <Route path="/detailskeyword/:queryName/" element={<DetailsKeyword />} />
      <Route path="/urlData/:id/" element={<DetailsUrl />} />

      <Route path="/:projectId/competitors" element={<ProjectCompetitors />} />
      {/* <Route path="/count" element={<Count />} /> */}

      <Route path="/Feedback" element={<FeedBackForm />} />
      {/* <Route path="/generated-article/:userid/:object_id/" element={<RichTextEditor />} /> */}
      {/* <Route path="/generated-article/:Query/" element={<GeneratedArticlePage />} /> */}
      
      <Route path="/generatedarticle/:userid/:object_id/"
       element={<GeneratedURLPage
        show={show} 
        />} />

    </Routes>
  );
};

export default Index;
