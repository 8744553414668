import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import CompletedQueries from "./CompletedQueries";

const DeleteProgressBar = ({
  updateErrors,

  fadeOut,
  progressBarVisible,
  isDeleting,
  setTotalDeletes,
  setCompleteDeletes,
  totalDeletes,
  completeDeletes,
}) => {
  const [showQueries, setShowQueries] = useState(false);

  //   if (!progressBarVisible) return null;
  const hasErrors = updateErrors > 0;
  const isCompleteOrHasError = completeDeletes > 0 || hasErrors;

  const progressBarColor = hasErrors
    ? completeDeletes > 0
      ? "bg-orange-500"
      : "bg-red-500"
    : "bg-green-500";

  const textColor = hasErrors ? "text-red-500" : "text-green-500";

  const progressBarWidth = `${Math.min(
    (completeDeletes / totalDeletes) * 100,
    100
  )}%`;
  const progressPercent =
    totalDeletes > 0 ? (completeDeletes / totalDeletes) * 100 : 0;
  const resetData = () => {
    setTotalDeletes(0);
    setCompleteDeletes(0);
    sessionStorage.clear();
  };
  useEffect(() => {
    if (progressBarWidth === "100%") {
      setTimeout(() => resetData(), 3000);
    } else {
      let rank = JSON.parse(sessionStorage.getItem("rank") || []);
      let newRank = [...new Set(rank)];
      setCompleteDeletes(newRank.length);
    }
  }, [sessionStorage.getItem("rank")]);
 

  return (
    <div className="my-2">
      <div className="progress-bar-striped bg-gray-200 rounded-full h-2 overflow-hidden">
        <div
          className={`progress-bar-striped ${
            fadeOut ? "fade-out" : ""
          } h-full rounded-full ${progressBarColor} transition-all duration-500`}
          style={{ width: progressBarWidth }}
        >
          {isCompleteOrHasError && (
            <div className="flex items-center justify-end h-full pr-2">
              {hasErrors ? (
                <svg
                  className="w-4 h-[20px] text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="w-4 h-[20px] text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </div>
          )}
        </div>
      </div>
      <span className={`${textColor} text-sm mt-[10px]`}>
        Deleting {completeDeletes}/{totalDeletes}
        {hasErrors && ` with ${updateErrors} error(s)`}
        {!showQueries && (
          <ArrowUpCircleIcon
            onClick={() => {
              setShowQueries(true);
            }}
            width={22}
            className=" text-mainColor cursor-pointer inline-block mx-2"
          />
        )}
        {showQueries && (
          <ArrowDownCircleIcon
            onClick={() => {
              setShowQueries(false);
            }}
            width={22}
            className=" text-mainColor cursor-pointer inline-block mx-2"
          />
        )}
        <CompletedQueries
          type={"deleted"}
          setShowQueries={setShowQueries}
          showQueries={showQueries}
        />
      </span>
      <span className={`${textColor} text-sm mt-[10px]`}>
        {/* {isCompleteOrHasError
            ? `Completed with ${completeDeletes} success${
                completeDeletes > 1 ? "es" : ""
              } ${
                hasErrors
                  ? `and ${updateErrors} error${updateErrors > 1 ? "s" : ""}`
                  : ""
              } `
            : ""} */}
        {hasErrors
          ? `and ${updateErrors} error${updateErrors > 1 ? "s" : ""}`
          : ""}
      </span>
      {hasErrors && (
        <div className="mt-2 text-sm text-red-500">Some queries failed.</div>
      )}
    </div>
  );
};
export default DeleteProgressBar;
