

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  id: null,
  verified: false,
  managed: false,
  // dailyQuota: 0,    // New field for daily quota
  accountType: null,  // New field for account type
  provider: null

};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      console.log(state, "state")
      console.log(action, state, "action")

      state.user = action.payload;
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.verified = action.payload.verified;
      state.managed = action.payload.Roles && action.payload.Roles.length > 0 && Object.keys(action.payload.Roles[0]).length > 0;
      // state.dailyQuota = action.payload.daily_quota;
      state.accountType = action.payload.ACCOUNT_TYPE;
      state.provider = action.payload.provider

    },
    logOut: (state, action) => {
      state.user = null;
      state.name = null;
      state.token = null;
      state.id = null;
      state.verified = null;
      state.managed = false;
      // state.dailyQuota = 0;   // Reset daily quota on logout
      state.accountType = null;  // Reset account type on logout
      state.provider = null

    },
    setManaged: (state, action) => {
      state.managed = action.payload;
    },
    // decrementDailyQuota: (state, action) => {
    //   const decrementAmount = action.payload || 1;  // Default to decrementing by 1
    //   state.dailyQuota = Math.max(state.dailyQuota - decrementAmount, 0);  // Ensure quota doesn't go below 0
    // },
  },
});

export const { login, logOut, setManaged
  ,
  // decrementDailyQuota  
} = authSlice.actions;
export default authSlice.reducer;
