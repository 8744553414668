import React from 'react';

const AlertModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div
    className="pointer-events-auto fixed inset-0 z-[1000] 
      grid h-screen w-screen place-items-center bg-black bg-opacity-30 opacity-100 backdrop-blur-sm transition-opacity duration-300"
  >      <div className="bg-white rounded-lg p-6 w-auto shadow-lg">
        <h2 className="text-xl font-bold mb-4">Alert</h2>
        <p className="mb-4">{message}</p>
        <button
          onClick={onClose}
          className="bg-mainColor text-white px-4 py-2 rounded hover:bg-darkMainColor transition duration-200"
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default AlertModal;
