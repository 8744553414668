import React, { useEffect, useState } from "react";
import { FaCheck, FaTimes, FaPen } from "react-icons/fa";

import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import DataTableComponent from "../../../../../components/keyword_research/keyword/DataTableComponent";
const extractDomain = (url) => {
  try {
    const domain = new URL(url).hostname;
    const domainName = domain.startsWith("www.") ? domain.slice(4) : domain;
    return domainName.toUpperCase();
  } catch (error) {
    return "INVALID URL";
  }
};
const removeEmojis = (text) => {
  return text?.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|\u24C2|\uD83D[\uDE00-\uDE4F])/g,
    ""
  );
};
const LinksTable = ({ linksData, setModalVisible, Query }) => {
  const [newLink, setNewLink] = useState("");
  const [filteredLinks, setFilteredLinks] = useState(linksData);
  const [showInput, setShowInput] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editDomain, setEditDomain] = useState("");
  const userId = useSelector((state) => state?.authSlice?.user.id);

  const linksColumns = [
    {
      name: "Rank",
      selector: (row) => row.rank,
      sortable: true,
      minWidth: "20%",
      maxWidth: "20%",
    },
    {
      name: "Title",
      selector: (row) => row.Title || row.title, // Handle both cases
      sortable: true,
      cell: (row) => (
        <a
          href={row.link}
          target="_blank"
          rel="noopener noreferrer"
          className="underline decoration-[#033dfc] text-[#033dfc]"
        >
          {removeEmojis(row.Title || row.title)}
        </a>
      ),
    },
    {
      name: "Domain",
      selector: (row) => extractDomain(row.link),
      sortable: true,
      cell: (row) =>
        editRowId === row.rank ? (
          <div className="flex items-center">
            <input
              type="text"
              value={editDomain}
              onChange={(e) => setEditDomain(e.target.value)}
              className="w-full px-2 py-1 bg-white border-2 border-[#e7e8ec] rounded-md text-sm focus:outline-none"
            />
            <FaCheck
              className="text-green-500 cursor-pointer ml-2"
            // onClick={handleSaveEdit}
            />
          </div>
        ) : (
          extractDomain(row.link)
        ),
    },

    // {
    //     name: 'Actions',
    //     cell: row => (
    //         <div className="w-[40px] h-[40px] flex justify-center items-center">

    //                 <FaTrash
    //                                         onClick={() => setLinkToDelete(row)}

    //                  className="text-red-500 cursor-pointer" size={20} />
    //             <div className="flex items-center">
    //                 <FaPen
    //                     className="text-gray-500 cursor-pointer ml-4"
    //                     onClick={() => handleEditClick(row)}
    //                 />
    //             </div>
    //         </div>
    //     ),
    //     sortable: true,
    //     minWidth: "20%",
    //     maxWidth: "20%",
    // },
  ];

  const processLinksData = (linksDataArray) => {
    return linksDataArray?.slice(0, 5);
  };

  const handleDeleteLink = async (link) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL
        }/keyword/Query-data-edit/${userId}/${encodeURIComponent(Query)}/`,
        {
          rank: parseInt(link.rank, 10), // Ensure rank is sent as an integer
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 204 || response.status === 200) {
        // Update the state to remove the deleted link
        setFilteredLinks(
          filteredLinks.filter((item) => item.rank !== link.rank)
        );
        // linksData.filter(item => item.rank !== link.rank);
        toast.success("Link deleted successfully");
      } else {
        toast.error("Failed to delete the link");
      }
    } catch (error) {
      console.error("Error deleting link:", error);
      toast.error("An error occurred while deleting the link");
    } finally {
      setLinkToDelete(null); // Close the confirmation modal
    }
  };
  const processedLinksDataArray = processLinksData(filteredLinks);

  useEffect(() => {
    setFilteredLinks(linksData);
  }, [linksData]);
  return (
    <div className="h-full rounded-md">
      {/* Header for Filtered Links */}
      <div className="flex items-center mb-4">
        <h2 className="text-lg font-semibold">Filtered Links</h2>

        <button
          onClick={setModalVisible}
          className="text-blue-500 underline px-4 py-2 rounded-full flex"
        >
          {filteredLinks?.length < 5 ? "Add / Edit " : "Edit "}
          Link
        </button>
        {showInput && (
          <div className="ml-4 flex items-center">
            <input
              type="text"
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
              placeholder="Add Link"
              className="w-full px-2 py-1 bg-white border-2 mr-2
                            border-[#e7e8ec] rounded-md text-sm focus:outline-none
                            transition duration-300 ease-in-out"
            />

            <FaTimes
              className="text-red-500 cursor-pointer text-lg"
              onClick={() => setShowInput(false)}
            />
          </div>
        )}
      </div>

      {/* Confirmation Modal */}
      {linkToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1004]">
          <div className="bg-white rounded-lg p-6 w-[400px] shadow-lg border border-gray-600">
            <p>
              Are you sure you want to delete the link titled "
              <strong>{linkToDelete.Title}</strong>"?
            </p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => handleDeleteLink(linkToDelete)}
              >
                Delete
              </button>
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={() => setLinkToDelete(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* DataTable */}
      <DataTableComponent
        columns={linksColumns}
        data={processedLinksDataArray}
      />
    </div>
  );
};

export default LinksTable;
