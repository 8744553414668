
import { useState, useEffect } from "react";

const usePagination = (data, initialRowsPerPage = 10) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [currentRows, setCurrentRows] = useState([]);
  console.log(currentRows,"Before")

  useEffect(() => {
    // Calculate the indexes for slicing the data
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
console.log(indexOfFirstRow,indexOfLastRow,"indexOfFirstRow")
    // Update currentRows based on the current page and rows per page
    setCurrentRows(data.slice(indexOfFirstRow, indexOfLastRow));
    console.log(currentRows,"indexOfFirstRow")
  }, [currentPage, rowsPerPage, data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to reset pagination and show the first page with updated data
  const resetToFirstPage = () => {
    setCurrentPage(1);
  };

  return {
    currentPage,
    currentRows,
    setCurrentRows,
    totalPages: Math.ceil(data.length / rowsPerPage),
    paginate,
    setCurrentPage,
    setRowsPerPage,
    rowsPerPage,
    resetToFirstPage,
  };
};

export default usePagination;
