import { FaSpinner } from "react-icons/fa";
import { RiUnpinFill } from "react-icons/ri";

const PinReplaceModal = ({
  projectToPinName,
  pinnedProjects,
  onReplace,
  setShowPinModal,
  loadingPin,
}) => (
  <div
    onClick={(event) => {
      if (event.target === event.currentTarget) {
        setShowPinModal(false);
      }
    }}
    className="pointer-events-auto fixed inset-0 z-[1300] 
    grid h-screen max-w-screen place-items-center bg-opacity-30 opacity-100 backdrop-blur-sm transition-opacity duration-300"
  >
    <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-xl border border-gray-200">
      <h2 className="text-xl font-bold mb-4 grid items-center justify-center mx-auto">
        Replace a Pinned Project
      </h2>
      <p className="text-center">
        Please select the project you want to replace with{" "}
        <strong className="text-mainColor">{projectToPinName}</strong>
      </p>
      {loadingPin ? (
        <>
          <FaSpinner
            size={24}
            className="animate-spin text-center m-auto w-6 text-mainColor my-11"
          />
        </>
      ) : (
        <ul className="border rounded-lg my-3 p-3 w-[85%] m-auto">
          {pinnedProjects.map((project) => (
            <li
              key={project.project_id}
              className="grid grid-cols-3 gap-3 items-center"
            >
              <span className="col-span-2">{project.name}</span>
              <button
                className="text-red-500 ml-auto px-2 py-1 rounded-md mb-3 transition-transform duration-300 ease-in-out hover:transform hover:scale-125"
                onClick={() => onReplace(project.project_id)}
              >
                <RiUnpinFill className="hover:text-red-500 transition-colors duration-300 ease-in-out" />
              </button>
            </li>
          ))}
        </ul>
      )}
      <button
        className="px-4 py-2 font-bold text-black bg-gray-300 rounded hover:bg-gray-400"
        onClick={() => setShowPinModal(false)}
      >
        Cancel
      </button>
    </div>
  </div>
);

export default PinReplaceModal;
