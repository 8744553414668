import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// Define the async thunk for processing the keyword
export const process_Keyword = createAsyncThunk(
    'keyword/processKeyword',
    async ({ query, userId }, { getState, rejectWithValue }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/keyword/new_scrape/${userId}/`, 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query })
          }
        );
  
        
                // Check if the error contains the specific "daily quota" message
                if (response.length > 0) {
                  // Display the daily quota message as a toast
                  response.forEach(value => {
                    toast.error(value);
                  });
                  return; // Stop further processing if quota message is received
                }
                // console.log(errorData,'errorDataerrorData');
                
        if (!response.ok) {
          const errorData = await response.json();
  
          // For other errors, provide a default message
          throw new Error(errorData.query ? errorData.query[0] : 'Network response was not ok');
        }
  
        const result = await response.json();
        if (result.length > 0) {
          // Display the daily quota message as a toast
          result.forEach(value => {
            toast.error(value);
          });
          return; // Stop further processing if quota message is received
        }
        return result;
      } catch (error) {
        console.log(error.message, 'errorerrorerror');
  
        // If error is not the specific daily quota error, show a general toast
        toast.error("An error occurred while processing the keyword.");
        return rejectWithValue(error.message);
      }
    }
  );

const keywordSlice = createSlice({
    name: 'keyword',
    initialState: {
        data: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(process_Keyword.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(process_Keyword.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                toast.success("Keyword processed successfully");
            })
            .addCase(process_Keyword.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                toast.error(action.payload);
            });
    }
});

export default keywordSlice.reducer;
